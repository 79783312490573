<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top" style="margin-top: 40px; background-color: white !important; padding-top: 0; padding-bottom: 0;">
  <div class="navbar-brand col-sm-3 col-md-2 mr-0"></div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav">
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="products">{{'nav.service_products'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="racks">{{'nav.racks'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="levels">{{'nav.service_levels'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="types">{{'nav.service_types'|translate}}</a></li>
    </ul>
  </div>
</nav>

<div class="container-fluid" style="margin-top: 82px;">
  <div class="row">
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
