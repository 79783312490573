<div class="d-flex">
  <div class="mr-auto">
    <alert></alert>
    <h1 style="white-space: nowrap;">
      <span *ngIf="auth.isSeller; else merchantHeader">
        <span>{{'common.sales_period'|translate|titlecase}}</span>
        <span class="badge badge-light p-2 ml-2">{{period.id}}</span>
        &bull;
        <span class="mr-2">{{period.store.name}}</span>
      </span>
      <ng-template #merchantHeader>
        <span>{{period.seller.name}}</span>
        <span class="badge badge-light p-2 ml-1">{{period.seller.user.email}}</span>
        <span *ngIf="period.seller.iban" class="badge badge-light p-2 ml-1">{{'common.iban_ok'|translate|uppercase}}</span>
        <span *ngIf="!period.seller.iban" class="badge badge-warning p-2 ml-1">{{'common.iban_missing'|translate|uppercase}}</span>
        <span class="badge badge-light p-2 ml-1">{{period.seller.id}}</span>
        <a class="btn btn-primary px-2 py-1 ml-1" routerLink="/seller/{{period.seller.id}}" target="_blank"><i-bs name="boxArrowUpRight"></i-bs></a>
        <br/>
        <span class="badge badge-light p-2">{{period.id}}</span>
        &bull;
        <span class="mr-2">{{period.store.name}}</span>
      </ng-template>
    </h1>
    <h2>
      <span class="badge badge-light p-2 mr-2">{{period.service_product.code}}</span>
      <span class="badge badge-light p-2 mr-2">{{period.service_product_variation.code}}</span>
      <span class="badge badge-light p-2">{{period.rack_time_slot.reck.name}}</span>
      &bull;
      {{period.rack_time_slot.from|date:'d.M.'}}&ndash;{{period.rack_time_slot.to|date:'d.M.'}}
      &bull;
      <span class="p-2" [ngClass]="statusIndicatorClass">{{statusCode|translate|uppercase}}</span>
      <span *ngIf="auth.isMerchant && period.settlement_status !== 'settlement_status_init'" class="p-2">
        <span class="badge p-2" [ngClass]="{'badge-success':period.settlement_status==='settlement_status_settled','badge-warning':period.settlement_status==='settlement_status_unsettled'}">{{'settlement_status.'+period.settlement_status|translate|uppercase}}</span>
      </span>
      <span *ngIf="auth.isMerchant && period.custom_period_status" class="p-0">
        &bull;
        <span class="badge p-2" [ngStyle]="{ 'background-color': period.custom_period_status.color }">{{period.custom_period_status.name}}</span>
      </span>
    </h2>
    <h3>
      {{'common.duration'|translate|titlecase}} <span class="badge badge-light p-2 mr-2">{{duration(period.rack_time_slot)}}{{'common.days_abbrev'|translate}}</span>
      {{'common.commission'|translate|titlecase}} <span class="badge badge-light p-2 mr-2">{{period.commission}}%</span>
      <span *ngIf="period.capacity && period.capacity > 0">{{'common.max_items'|translate}} <span class="badge badge-light p-2">{{period.capacity}}</span></span>
      <span *ngIf="period.price" class="ml-2">{{'common.price'|translate|titlecase}} <span class="badge badge-light p-2 ml-2">{{currency(period.price)}}</span></span>
      <span *ngIf="period.discount" class="ml-2">{{'common.sale__discount'|translate|titlecase}} <span class="badge badge-light p-2 ml-2">{{period.discount}}%</span></span>
    </h3>
    <h3>
      <span *ngIf="period.product_counts['total']==0">{{'common.no_products'|translate}}</span>
      <span *ngIf="period.product_counts['initial']>0">{{'product_status.initial'|translate|titlecase}} <span class="badge badge-secondary p-2 mr-2">{{period.product_counts['initial']}}</span></span>
      <span *ngIf="period.product_counts['confirmed']>0">{{'product_status.confirmed'|translate|titlecase}} <span class="badge badge-secondary p-2 mr-2">{{period.product_counts['confirmed']}}</span></span>
      <span *ngIf="period.product_counts['tagged']>0">{{'product_status.tagged'|translate|titlecase}} <span class="badge badge-secondary p-2 mr-2">{{period.product_counts['tagged']}}</span></span>
      <span *ngIf="period.product_counts['sold']>0">{{'product_status.sold'|translate|titlecase}} <span class="badge badge-secondary p-2 mr-2">{{period.product_counts['sold']  }}</span></span>
      <span *ngIf="period.product_counts['total']>0">{{'product_status.total'|translate|titlecase}} <span class="badge badge-secondary p-2 mr-2">{{period.product_counts['total']  }}</span></span>
      <span *ngIf="auth.isMerchant && period.unsettled_products_count>0">{{'common.to_settle'|translate|titlecase}} <span class="badge badge-warning p-2 mr-2">{{period.unsettled_products_count}}</span></span>
    </h3>
    <h3 *ngIf="auth.isMerchant">
      <span *ngIf="period.payment_method">{{'common.payment_method'|translate}} <span class="badge badge-light p-2 mr-2">{{'payment_method.'+period.payment_method|translate|uppercase}}</span></span>
      <span *ngIf="period.payment_status">{{'common.payment_status'|translate|titlecase}} <span class="badge p-2 badge-light">{{'payment_status.'+period.payment_status|translate|uppercase}}</span></span>
    </h3>
    <form [formGroup]='notesFormGroup'>
      <div *ngIf="showNotes" class="d-flex">
        <div class="mr-2">
          <div class="note-header" *ngIf="auth.isMerchant">{{'common.seller_note'|translate|uppercase}}</div>
          <div class="note-header" *ngIf="auth.isSeller">{{'common.seller_note_for_seller'|translate|uppercase}}</div>
          <textarea type="text" rows="2" class="note-text form-control p-1" formControlName="seller_note"></textarea>
        </div>
        <div *ngIf="auth.isMerchant">
          <div class="note-header">{{'common.internal_note'|translate|uppercase}}</div>
          <textarea type="text" rows="2" class="note-text form-control p-1" formControlName="internal_note"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div style="text-align: right;">
    <h1>
      {{'common.sales'|translate}}
      <span class="badge badge-success p-2">{{period.sales_total|currency}}</span>
    </h1>
    <h2>
      <span *ngIf="period.value_total">
        {{'common.value'|translate|titlecase}}
        <span class="badge badge-info p-2">{{period.value_total|currency}}</span>
      </span>
    </h2>
  </div>
</div>
