<div class="card mb-4" *ngIf="merchant">
  <div class="card-header">
    <div class="d-flex">
      <h2>{{'merchant_profile.reservation_info_header'|translate}}</h2>
      <div class="ml-auto">
        <button class="btn btn-primary" (click)="editReservationInfo()" *ngIf="!editingReservationInfo; else reservationEditButtons" [disabled]="true">{{'common.edit'|translate|uppercase}}</button>
        <ng-template #reservationEditButtons>
          <button class="btn btn-secondary mr-2" (click)="cancelReservationInfoEdit()">{{'common.cancel'|translate|uppercase}}</button>
          <button class="btn btn-primary" (click)="onSubmitReservationForm()">{{'common.save'|translate|uppercase}}</button>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="reservationInfoForm" (ngSubmit)="onSubmitReservationForm()">
      <!-- <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'merchant_profile.reservation_calendar_last_date'|translate}}</label>
        <div class="col-md-9">
          <div class="input-group">
            <input ngbDatepicker class="form-control"
              #lastDatePicker="ngbDatepicker" name="lastDatePicker"
              [formControlName]="'reservation_calendar_last_date'"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar p-2" type="button"
                [disabled]="!reservationInfoForm.enabled"
                (click)="lastDatePicker.toggle()"><i-bs name="calendarDate"></i-bs></button>
            </div>
          </div>
        </div>
      </div> -->

      <div *ngFor="let store of merchant.stores">
        <div class="row form-group">
          <div class="col-md-12">
            <h3>{{store.name}}</h3>
            <p>{{store.address}}</p>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-md-3 col-form-label">{{'merchant_profile.store_closed_weekdays'|translate}}</label>
          <div class="col-md-9">
            <div class="btn-group btn-group-toggle">
              <label [ngClass]="{'btn-success': f3.controls.mon.value, 'btn-primary': !f3.controls.mon.value}" ngbButtonLabel><input type="checkbox" formControlName="mon" ngbButton>{{'weekdays.mon'|translate|uppercase}}</label>
              <label [ngClass]="{'btn-success': f3.controls.tue.value, 'btn-primary': !f3.controls.tue.value}" ngbButtonLabel><input type="checkbox" formControlName="tue" ngbButton>{{'weekdays.tue'|translate|uppercase}}</label>
              <label [ngClass]="{'btn-success': f3.controls.wed.value, 'btn-primary': !f3.controls.wed.value}" ngbButtonLabel><input type="checkbox" formControlName="wed" ngbButton>{{'weekdays.wed'|translate|uppercase}}</label>
              <label [ngClass]="{'btn-success': f3.controls.thu.value, 'btn-primary': !f3.controls.thu.value}" ngbButtonLabel><input type="checkbox" formControlName="thu" ngbButton>{{'weekdays.thu'|translate|uppercase}}</label>
              <label [ngClass]="{'btn-success': f3.controls.fri.value, 'btn-primary': !f3.controls.fri.value}" ngbButtonLabel><input type="checkbox" formControlName="fri" ngbButton>{{'weekdays.fri'|translate|uppercase}}</label>
              <label [ngClass]="{'btn-success': f3.controls.sat.value, 'btn-primary': !f3.controls.sat.value}" ngbButtonLabel><input type="checkbox" formControlName="sat" ngbButton>{{'weekdays.sat'|translate|uppercase}}</label>
              <label [ngClass]="{'btn-success': f3.controls.sun.value, 'btn-primary': !f3.controls.sun.value}" ngbButtonLabel><input type="checkbox" formControlName="sun" ngbButton>{{'weekdays.sun'|translate|uppercase}}</label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-md-3 col-form-label">{{'merchant_profile.store_closed_days'|translate}}</label>
          <div class="col-md-9">
            <div class="form-control form-control-lg">
              <span *ngFor="let store_closed_day of store.store_closed_days" class="badge badge-light mr-2">
                {{store_closed_day.date}} {{store_closed_day.description}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'merchant_profile.seller_registration_enabled'|translate}}</label>
        <div class="col-md-9"><input formControlName="seller_registration_enabled" type="checkbox" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'merchant_profile.seller_reservations_enabled'|translate}}</label>
        <div class="col-md-9"><input formControlName="seller_reservations_enabled" type="checkbox" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'merchant_profile.seller_reservation_checkout_enabled'|translate}}</label>
        <div class="col-md-9"><input formControlName="seller_reservation_checkout_enabled" type="checkbox" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'merchant_profile.seller_reservation_checkout_required'|translate}}</label>
        <div class="col-md-9"><input formControlName="seller_reservation_checkout_required" type="checkbox" class="form-control form-control-lg"/></div>
      </div>
    </form>
  </div>
</div>

<div class="card mb-4" *ngIf="merchant">
  <div class="card-header">
    <div class="d-flex">
      <h2>{{'merchant_profile.basic_info_header'|translate}}</h2>
      <div class="ml-auto">
        <button class="btn btn-primary" (click)="editBasicInfo()" *ngIf="!editingBasicInfo; else basicEditButtons" [disabled]="true">{{'common.edit'|translate|uppercase}}</button>
        <ng-template #basicEditButtons>
          <button class="btn btn-secondary mr-2" (click)="cancelBasicInfoEdit()">{{'common.cancel'|translate|uppercase}}</button>
          <button class="btn btn-primary" (click)="onSubmitBasicForm()">{{'common.save'|translate|uppercase}}</button>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="basicInfoForm" (ngSubmit)="onSubmitBasicForm()">
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.name'|translate}}</label>
        <div class="col-md-9"><input formControlName="name" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.official_name'|translate}}</label>
        <div class="col-md-9"><input formControlName="official_name" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.homepage'|translate}}</label>
        <div class="col-md-9"><input formControlName="homepage" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.email'|translate}}</label>
        <div class="col-md-9"><input formControlName="email" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.phone'|translate}}</label>
        <div class="col-md-9"><input formControlName="phone" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.contact'|translate}}</label>
        <div class="col-md-9"><input formControlName="contact" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.address'|translate}}</label>
        <div class="col-md-9"><input formControlName="address" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.business_id'|translate}}</label>
        <div class="col-md-9"><input formControlName="business_id" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.privacy_policy_url'|translate}}</label>
        <div class="col-md-9"><input formControlName="privacy_policy_url" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.terms_and_conditions_url'|translate}}</label>
        <div class="col-md-9"><input formControlName="terms_and_conditions_url" type="text" class="form-control form-control-lg"/></div>
      </div>
    </form>
  </div>
</div>

<div class="card mb-4" *ngIf="merchant">
  <div class="card-header">
    <div class="d-flex">
      <h2>{{'merchant_profile.payment_info_header'|translate}}</h2>
      <div class="ml-auto">
        <button class="btn btn-primary" (click)="editPaymentInfo()" *ngIf="!editingPaymentInfo; else paymentEditButtons" [disabled]="true">{{'common.edit'|translate|uppercase}}</button>
        <ng-template #paymentEditButtons>
          <button class="btn btn-secondary mr-2" (click)="cancelPaymentInfoEdit()">{{'common.cancel'|translate|uppercase}}</button>
          <button class="btn btn-primary" (click)="onSubmitPaymentForm()">{{'common.save'|translate|uppercase}}</button>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="paymentInfoForm" (ngSubmit)="onSubmitPaymentForm()">
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.iban'|translate}}</label>
        <div class="col-md-9"><input formControlName="iban" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.bic'|translate}}</label>
        <div class="col-md-9"><input formControlName="bic" type="text" class="form-control form-control-lg"/></div>
      </div>
      <div class="row form-group">
        <label class="col-md-3 col-form-label">{{'common.checkout_merchant_id'|translate}}</label>
        <div class="col-md-9"><input formControlName="checkout_merchant_id" type="text" class="form-control form-control-lg"/></div>
      </div>
    </form>
  </div>
</div>
