<div class="container-fluid">

  <div class="card gutter-t gutter-b">
    <div class="card-header">
      <h1 class="mb-4">
        {{'common.racks'|translate}}
        <span *ngIf="selectedServiceProduct && selectedServiceProduct.recks">
          &bull;
          {{selectedServiceProduct.recks.length}}
        </span>
      </h1>
      <service-product-selector
        [showRacks]="false"
        [showVariations]="false"
        [autoselectProduct]="true"
        [requireProductSelect]="true"
        [enableSearch]="false"
        (selectProduct)="selectProduct($event)"
        (unselectProduct)="unselectProduct($event)"
        >
      </service-product-selector>
    </div>
    <div class="card-body">
      <div *ngIf="true">
        <span *ngIf="!selectedServiceProduct; else maybeRacks">{{'common.no_product_selected'|translate}}</span>
        <ng-template #maybeRacks>
          <span *ngIf="!selectedServiceProduct.recks || selectedServiceProduct.recks.length == 0; else racks">
            {{'common.no_racks'|translate}}
          </span>
        </ng-template>
        <ng-template #racks>
          <table class="table table-sm" style="width: intrinsic;">
            <thead>
              <tr>
                <th scope="col" style="width: 10em;">Name</th>
                <th scope="col" style="width: 10em;">Type</th>
                <th scope="col" style="width: 10em;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let rack of selectedServiceProduct.recks">
                <!--{{rack.id}}-->
                <td><span class="badge badge-info">{{rack.name}}</span></td>
                <td>
                  <span class="badge badge-light" *ngIf="rack.is_virtual; else regular">{{'common.rack_type_virtual'|translate|uppercase}}</span>
                  <ng-template #regular><span class="badge badge-light">{{'common.rack_type_regular'|translate|uppercase}}</span></ng-template>
                </td>
                  <td>
                  <button [disabled]="true" class="btn btn-sm btn-outline-primary px-1 py-1 mx-1"><i-bs name="pencil"></i-bs></button>
                  <button [disabled]="true" class="btn btn-sm btn-outline-danger px-1 py-1 mx-1"><i-bs name="x"></i-bs></button>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </div>
    </div>
  </div>

</div>