<div class="container-fluid">
  <div class="row">
    <main role="main" class="col">

      <div class="container-fluid">
        <div class="row my-5">
          <div class="col">
            <h1>
              {{'merchant.dashboard.title'|translate}}
            </h1>
          </div>
          <button class="btn btn-primary mr-2" (click)="getDailySalesReport('today')">{{'common.daily_report_today'|translate|uppercase}}<i-bs name="boxArrowDown" class="ml-1"></i-bs></button>
          <button class="btn btn-primary" (click)="getDailySalesReport('yesterday')">{{'common.daily_report_yesterday'|translate|uppercase}}<i-bs name="boxArrowDown" class="ml-1"></i-bs></button>
        </div>

        <div class="row my-5">
          <div class="col">
            <store-selector [initialStore]="this.storeSelection" (selectedStore)="selectedStore($event)">
            </store-selector>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-4">
              <sales-periods-summary-list [date]="day1" [store]="storeSelection" (change)="day1Changed($event)"></sales-periods-summary-list>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-4">
              <sales-periods-summary-list [date]="day2" [store]="storeSelection" (change)="day2Changed($event)"></sales-periods-summary-list>
            </div>
          </div>
        </div>

      </div>

    </main>
  </div>
</div>
