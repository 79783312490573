import { Component, OnInit } from '@angular/core';

import { ProductFeaturesService } from '@/services/product-features.service';

import { ProductBrand } from '@/domain/product-brand';

@Component({
  selector: 'app-product-brand',
  templateUrl: './brand.component.html'
})
export class ProductBrandComponent implements OnInit {

  constructor(private featureService: ProductFeaturesService) { }

  brands: ProductBrand[];

  ngOnInit(): void {
    this.getBrands();
  }

  getBrands(): void {
    this.featureService.getBrands()
      .subscribe(brands => this.brands = brands)
  }

}
