import { ActivatedRoute } from "@angular/router"
import { Component } from '@angular/core'

import { AuthenticationService } from '@/services/authentication.service'
import { SalesPeriodsService } from '@/services/sales-periods.service'

import { SalesPeriod } from "@/domain/sales-period"
import { SelectedVariationAndDate } from '@/model/calendar'

@Component({
  selector: 'merchant-calendar-view',
  templateUrl: 'merchant-calendar-view.component.html',
  styleUrls: [ 'merchant-calendar-view.component.scss' ]
})
export class MerchantCalendarViewComponent {

  openSalesPeriodModal: boolean = false
  selection: SelectedVariationAndDate = null
  salesPeriodToEdit: SalesPeriod = null

  constructor(
    public salesPeriodsService: SalesPeriodsService,
    public auth: AuthenticationService,
    public route: ActivatedRoute,
  ) { }

  selectedForEdit(period: SalesPeriod): void {
    this.selection = null
    this.salesPeriodToEdit = period
    this.openSalesPeriodModal = true
  }

  selectedForCreate(selection: SelectedVariationAndDate): void {
    this.salesPeriodToEdit = null
    this.selection = selection
    this.openSalesPeriodModal = true
  }

  salesPeriodModalClosed($event): void {
    this.openSalesPeriodModal = false
  }
}