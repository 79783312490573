<!-- https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/index.html -->

<div class="d-flex" id="wrapper" style="margin-top: 60px;">

  <product-features-sidebar></product-features-sidebar>

    <div id="page-content-wrapper">
      <div class="container-fluid">
        <h1 class="mt-4">Product Features</h1>
        <p>Manage all of your product features here. Select from the left plz.</p>
      </div>
    </div>

</div>
