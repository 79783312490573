import { AvailableStartDate } from '@/domain/available-start-date'

export class ServiceProductVariation {

  id: number
  created_at: string
  updated_at: string

  code: string
  name: string
  description: string
  description_formatted: string
  readmore_url: string

  duration: number
  capacity: number
  price: number
  price_in_store: number
  commission: number

  available_start_dates: AvailableStartDate[]

  public static from(json: any): ServiceProductVariation {
    const spv = Object.assign(new ServiceProductVariation(), json)
    if (json.available_start_dates) {
      spv.available_start_dates = json.available_start_dates.map(w => { return AvailableStartDate.from(w) })
    }
    return spv
  }
}
