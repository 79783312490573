import { Component, OnInit, ViewChildren, QueryList } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { debounceTime, switchMap } from 'rxjs/operators'
import { ibanValidator } from 'ngx-iban'

import { SortEvent, NgbdSortableHeader, State } from '@/ui/common/util/ngbd-things'

import { AlertService } from '@/services/alert.service'
import { SellersService } from '@/services/sellers.service'

import { Seller } from '@/domain/seller'

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0

@UntilDestroy()
@Component({
  selector: 'sellers',
  templateUrl: './all-sellers.component.html',
  styleUrls: ['./all-sellers.component.scss']
})
export class AllSellersComponent implements OnInit {

  @ViewChildren(NgbdSortableHeader)
  headers: QueryList<NgbdSortableHeader>

  filter = new FormControl('')

  sellers: Seller[] // the source
  sellers$: Observable<Seller[]> // used in html

  private _sellers$ = new BehaviorSubject<Seller[]>([]) // used to react
  private _search$ = new Subject<void>()

  loading: boolean = true

  private _state: State = {
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  }

  sellerForm = this.fb.group({
    name: ['', Validators.required],
    phone: [''],
    accept_marketing: [ false ],
    seller_reservations_enabled: [ true ],
    iban: ['', ibanValidator('FI')],
    user_email: ['', Validators.required],
    user_password: ['', Validators.required]
  })

  isCreating: boolean = false
  editingSellerId: number = null

  private modalRef = null

  constructor(
    private service: SellersService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private translate: TranslateService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getAllSellers()
  }

  edit(seller: Seller, modal) {
    this.isCreating = false
    this.editingSellerId = seller.id
    this.initializeSellerForm(seller)
    this.openModal(modal)
  }

  create(modal) {
    this.isCreating = true
    this.editingSellerId = null
    this.initializeSellerForm(null)
    this.openModal(modal)
  }

  save(): void {
    this.alertService.clear()
    const passwd = this.sellerForm.controls['user_password'].value
    this.service
      .saveOrCreateWithUser(this.sellerForm.value, this.editingSellerId, passwd)
      .subscribe(
        json => {
          let seller = Seller.from(json)
          if (this.editingSellerId) {
            this.replace(this.editingSellerId, seller)
          } else {
            this.add(seller)
          }
          this.didRecentlyModify(seller, 5000)
          this.modalRef.close()
          this.editingSellerId = null
          this.isCreating = false
        },
        error => {
          let msg = error.message + (error.errors ? (': ' + error.errors.join('; ')) : '')
          this.alertService.error(this.translate.instant(msg))
        })
  }

  onSort({column, direction}: SortEvent) {
    // reset other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = ''
      }
    })
    this._state.sortColumn = column
    this._state.sortDirection = direction
    this._search$.next()
  }

  clearFilter() {
    this.filter.setValue('')
    this._search$.next()
  }

  // private

  private _initSort(): void {
    if (this.headers) {
      let header = this.headers.find(h => h.sortable == 'id')
      if (header) {
        header.sort.emit({ column: 'id', direction: 'asc' })
        header.direction = 'asc'
        this.onSort({ column: 'id', direction: 'asc' })
      }
    }
  }

  private add(seller: Seller) {
    this.sellers.unshift(seller)
    this._search$.next()
  }

  private replace(sellerId: number, seller: Seller) {
    let toRemove = this.sellers.find(s => { return s.id == sellerId })
    this.sellers.splice(this.sellers.indexOf(toRemove), 1)
    this.add(seller)
  }

  private openModal(modal) {
    let options = {
      centered: true,
      ariaLabelledBy: 'modal-basic-title'
    }
    this.modalRef = this.modalService.open(modal, options)
    this.modalRef.result.then(
      closingReason => { this.editingSellerId = null },
      dismissalReason => { this.editingSellerId = null } )
  }

  private initializeSellerForm(seller: Seller) {
    if (seller) {
      this.sellerForm.setValue({
        name: seller.name,
        phone: seller.phone,
        accept_marketing: seller.accept_marketing,
        seller_reservations_enabled: seller.seller_reservations_enabled,
        iban: seller.iban,
        user_email: seller.user.email,
        user_password: '********'
      })
    } else {
      this.sellerForm.setValue({
        name: '',
        phone: '',
        accept_marketing: false,
        seller_reservations_enabled: true,
        iban: '',
        user_email: '',
        user_password: this.generatePassword()
      })
    }
    this.sellerForm.controls['user_password'].disable()
  }

  private didRecentlyModify(seller: Seller, timeout: number) {
    seller.recentlyModified = true
    setTimeout(() => { seller.recentlyModified = false }, timeout)
  }

  private generatePassword(): string {
    return Math.random().toString(36).substr(2, 12)
  }

  private getAllSellers(): void {
    this.loading = true
    this.service.sellers.subscribe(jsonArray => {
      this.sellers = Seller.fromMany(jsonArray)
      this.loading = false
      this._initSort()
      this.initializeFiltering()
    })
  }

  private initializeFiltering() {
    this._search$.pipe(
      debounceTime(200),
      switchMap(() => this._search())
    ).pipe(untilDestroyed(this)).subscribe(result => {
      this._sellers$.next(result)
    })
    this.sellers$ = this._sellers$.asObservable()
    this._search$.next();
    this.filter.valueChanges.pipe(untilDestroyed(this)).subscribe(text => {
      this._state.searchTerm = text
      this._search$.next()
    })
  }

  private _search(): Observable<Seller[]> {
    const { sortColumn, sortDirection, searchTerm } = this._state
    let sellers = this.sort2(this.sellers, sortColumn, sortDirection)
    sellers = sellers.filter(period => { return this.matches(period, searchTerm) })
    return of(sellers)
  }

  private sort2(sellers: Seller[], column: string, direction: string): Seller[] {
    if (direction === '') {
      return sellers
    }
    else {
      let sorted = [...sellers].sort((a, b) => {
        const aa = this.fieldForSortColumn(column, a)
        const bb = this.fieldForSortColumn(column, b)
        const res = compare(aa, bb)
        return direction === 'asc' ? res : -res
      })
      return sorted
    }
  }

  private fieldForSortColumn(column: string, seller: Seller): string | number {
    switch (column) {
      case 'id': return seller.id
      case 'name': return seller.name
      case 'email': return seller.user.email
      case 'period_count': return seller.sales_periods_count
     default: return seller.id
    }
  }

  private matches(seller: Seller, term: string): boolean {
    const t = term.toLocaleLowerCase()
    return seller.id.toString().includes(t)
      || seller.name.toLocaleLowerCase().includes(t)
      || seller.user.email.toLocaleLowerCase().includes(t)
      || (seller.phone && seller.phone.includes(t))
      || (seller.iban && seller.iban.toLocaleLowerCase().includes(t))
  }
}
