import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'service-products-view',
  templateUrl: 'service-products-view.component.html',
  styleUrls: [ 'service-products-view.component.scss' ]
})
export class ServiceProductsViewComponent implements OnInit {

  constructor(
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._navigateToDefaultChildView()
    this._router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event['url'] === '/merchant/service') {
        this._navigateToDefaultChildView()
      }
    })
  }

  private _navigateToDefaultChildView(): void {
    this._router.navigate([ '/merchant/service/products' ])
  }
}
