import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { AuthenticationService } from '@/services/authentication.service'
import { Observable } from 'rxjs'

const API_PATH = '/api/v1/'

@Injectable()
export class ApiCallInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isJsonCall(request.url)) {
      request = this.addJsonHeaders(request)
    }
    return next.handle(request)
  }

  private addJsonHeaders(request: HttpRequest<any>): HttpRequest<any> {
    if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.seller) {
      const seller_id = this.authenticationService.currentUserValue.seller.id
      request = request.clone({
        setHeaders: {
          'Deux-Seller-ID': ""+seller_id
        }
      })
    }

    return request.clone({
      setHeaders: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    })
  }

  private isJsonCall(url: string): boolean {
    return url.indexOf(API_PATH) > -1 || url.indexOf('/status') > -1 || url.indexOf('/app.json') > -1
  }
}