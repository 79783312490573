import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { first, skip } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'
import { MerchantsService } from '@/services/merchants.service'

import { Merchant } from '@/domain/merchant'

@UntilDestroy()
@Component({ templateUrl: 'signup.component.html' })
export class SignupComponent implements OnInit {

  signupForm: FormGroup
  loading: boolean = true
  submitted: boolean = false
  returnUrl: string
  error = ''

  signupCompleted: boolean = false

  private _allMerchants: Merchant[] = null
  signupMerchants: Merchant[] = null

  constructor(
    private _merchantsService: MerchantsService,
    private _fb: FormBuilder,
    private _router: Router,
    private _auth: AuthenticationService,
    private _translate: TranslateService,
    private _alertService: AlertService
  ) {
    if (this._auth.currentUserValue) {
      this._navigateHome()
    }
  }

  ngOnInit() {
    this._reset()
    // console.debug('_allMerchants', this._allMerchants)
    // console.debug('signupMerchants', this.signupMerchants)

    this._auth.preloadedMerchant.pipe(
      // skip(1), 
      untilDestroyed(this)).subscribe(merchant => {
        if (merchant) {
          // console.debug('got preloadedMerchant', merchant)
          this._allMerchants = [ merchant ]
          this.signupMerchants = [ merchant ]
          this.signupForm.get('merchant_id').setValue(merchant.id)
          this.loading = false
        } else {
          this._merchantsService.signupMerchants.pipe(
            // skip(1), 
            untilDestroyed(this)).subscribe(merchants => {
              // console.debug('got merchants', merchants)
              this._allMerchants = merchants
              this.signupMerchants = merchants
              this.loading = false
            })
        }
      })

    this.signupForm.get('merchant_id').valueChanges.subscribe(v => { 
      this.signupForm.get('tcAccepted').setValue(false)
    })
  }

  get f() { return this.signupForm.controls }

  private _navigateHome(): void {
    this._router.navigate(['/'])
  }

  private _reset(): void {
    this.loading = true
    this.submitted = false
    this.signupCompleted = false
    this.signupForm = this._fb.group({
      merchant_id: ['', Validators.required],
      name:        ['', Validators.required],
      phone:       [''],
      username:    ['', Validators.required],
      password:    ['', Validators.required],
      password_confirmation: ['', Validators.required],
      tcAccepted:            [false, Validators.required],
      accept_marketing:      [false]
    })
  }

  confirmationAlertClosed(): void {
    console.debug('confirmationAlertClosed')
    this._reset()
    this._navigateHome()
  }

  merchantName(id: number): string {
    return this._allMerchants.find(m => { return m.id === id }).name
  }

  hasTerms(id: number): boolean {
    return !!this.termsUrl(id)
  }

  hasPolicy(id: number): boolean {
    return !!this.policyUrl(id)
  }

  termsUrl(id: number): string {
    return this._allMerchants.find(m => { return m.id === id })?.terms_and_conditions_url
  }

  policyUrl(id: number): string {
    return this._allMerchants.find(m => { return m.id === id })?.privacy_policy_url
  }

  onSubmit() {
    this.submitted = true

    // reset alerts on submit
    this._alertService.clear()

    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return
    }

    this.loading = true;
    this._auth.sellerSignup(this.signupForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false
          this._alertService.success(this._translate.instant('account.created'))
          this.signupCompleted = true
          // this.router.navigate(['/login'])
        },
        error => {
          this.loading = false
          this._alertService.error(error.message)
        });
  }
}