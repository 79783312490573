import { Component, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { formatISO9075 } from 'date-fns'
import { saveAs } from 'file-saver'
import { Subscription } from 'rxjs'

import { AlertService } from '@/services/alert.service'
import { SettlementsService } from '@/services/settlements.service'

import { PaymentInitiationMessage } from '@/domain/payment-initiation-message'

@Component({
  selector: 'sepa-xml-view',
  templateUrl: './sepa-xml-view.component.html',
})
export class SepaXmlViewComponent implements OnInit, OnDestroy {

  public loading: boolean = false
  public messages: PaymentInitiationMessage[] = null

  private _subscription: Subscription

  constructor(
    private _service: SettlementsService,
    private _alertService: AlertService,
    private _translate: TranslateService
  ) { }

  ngOnInit(): void {
    this._subscription = this._service.paymentInitiationMessages.subscribe(m =>
      this.messages = m.sort((a,b) => { return b.id - a.id })
    )
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe()
    }
  }

  public create(): void {
    this.loading = true
    this._service.createPaymentInitiationMessage().subscribe(
      msg => {
        this.loading = false
        if (!msg) {
          const warning = this._translate.instant('merchant.settlements.no_settlements_for_xml')
          this._alertService.warning(warning)
        }
      },
      err => {
        this.loading = false
        this._alertService.error(err.message)
      }
    )
  }

  public downloadXml(message: PaymentInitiationMessage): void {
    let blob = new Blob([ message.xml ], { type: 'application/xml' });
    let date = formatISO9075(new Date(message.created_at), { format: 'basic' }).replace(' ', '')
    saveAs(blob, 'sepa-' + date + '.xml')
  }
}
