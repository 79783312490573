import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { shareReplay, map, catchError } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'

import * as api from '@/config/api-config.json'

import { ApplicationUpdateService } from '@/services/application-update.service'

import { Merchant } from '@/domain/merchant'
import { Seller } from '@/domain/seller'
import { User } from '@/domain/user'

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private _currentUserSubject: BehaviorSubject<User>
  public currentUser: Observable<User>

  private _preloadedMerchant: Merchant
  private _preloadedMerchantSubject: BehaviorSubject<Merchant>
  public preloadedMerchant: Observable<Merchant>

  constructor(
    private _http: HttpClient,
    private _translation: TranslateService,
    private _applicationUpdate: ApplicationUpdateService
  ) {
    this._currentUserSubject = new BehaviorSubject<User>(null)
    this.currentUser = this._currentUserSubject.pipe(shareReplay(1))

    this._preloadedMerchant = null
    this._preloadedMerchantSubject = new BehaviorSubject<Merchant>(null)
    this.preloadedMerchant = this._preloadedMerchantSubject.asObservable()

    let user = JSON.parse(localStorage.getItem('currentUser'))
    if (user) {
      if (user.merchant) {
        user.merchant = Merchant.from(user.merchant)
      }
      this._nextPreloadedMerchant(null)
      this._nextLoggedInUser(user)
      if (user.merchant) {
        this._maybeRedirectToMerchantHostname(user.merchant)
      }
    }
  }

  // Doing this in the constructor caused a circular DI dependency, gofigure
  maybePreloadMerchant(): void {
    if (!this.isLoggedIn) {
      this._preloadMerchantByHostname(window.location.hostname)
    }
  }

  /** Use this because if this service uses MerchantsService, it creates a circular dependency */
  public updateMerchants(merchants: Merchant[]): void {
    if (this.merchantId) {
      const merchant = merchants.find(m => { return m.id === this.merchantId })
      const user = this._currentUserSubject.value
      if (!Merchant.equal(merchant, user.merchant)) {
        user.merchant = merchant
        this._nextLoggedInUser(user)
      }
    }
  }

  get currentUserValue(): User|null {
    return this._currentUserSubject.value
  }

  get merchantId(): number|null {
    if (!this.currentUserValue) {
      return null
    }
    return this.isSeller
      ? this.currentUserValue.seller.merchant_id
      : (this.currentUserValue.merchant
          ? this.currentUserValue.merchant.id
          : null)
  }

  get sellerId(): number|null {
    return this.isSeller ? this.currentUserValue.seller.id : null
  }

  get isLoggedIn(): boolean {
    return this._currentUserSubject.value !== null
  }

  get isAdmin() {
    return this.currentUserValue && this.currentUserValue.role === 'admin'
  }

  get isSeller() {
    return this.currentUserValue && this.currentUserValue.role === 'seller'
  }

  get isSellerReservationEnabled() {
    return this.currentUserValue.merchant
      && this.currentUserValue.merchant.seller_reservations_enabled == true
      && this.isSeller
      && this.currentUserValue.seller.seller_reservations_enabled == true
  }

  get isMerchant() {
    return this.currentUserValue && this.currentUserValue.role === 'merchant'
  }

  whoami() {
    return this._http.get(api.whoami)
  }

  confirm(token: string) {
    return this._http
      .get<User>(api.auth.confirm + '?token=' + token)
      .pipe<User>(catchError(this._handleError))
  }

  reconfirm(token: string) {
    return this._http
      .get<User>(api.auth.reconfirm + '?token=' + token)
      .pipe<User>(catchError(this._handleError))
  }

  sellerSignup(values) {
    let body = {
      "seller": {
        "name": values.name,
        "phone": values.phone,
        "user_email": values.username,
        "accept_marketing": values.accept_marketing,
        "user_password": values.password,
        "user_password_confirmation": values.password_confirmation,
        "user_merchant_id": values.merchant_id
      }
    }
    return this._http
      .post<Seller>(api.sellers.signup, body)
      .pipe<Seller>(catchError(this._handleError))
  }

  logout() {
    // TODO move all local storage handling into a service
    localStorage.removeItem('currentUser')
    localStorage.removeItem('pos.storeId')
    this._currentUserSubject.next(null)
    this._applicationUpdate.update()
  }

  login(username: string, password: string) {
    let body = {
      "user": {
        "email": username,
        "password": password
      }
    }
    return this._http.post<HttpResponse<User>>(api.auth.login, body, { observe: 'response' })
      .pipe(map(response => {
        let token = response.headers.get('Authorization')
        if (!token) {
          throw new Error('Could not log in')
        }
        token = token.replace("Bearer ", "")

        if (response.body['role'] == 'seller' && !response.body['seller_account_found']) {
          throw new Error('Seller account not found')
        }

        let user = new User()
        user.id = response.body['id']
        user.username = response.body['email']
        user.created_at = response.body['created_at']
        user.updated_at = response.body['updated_at']
        user.role = response.body['role']
        user.token = token
        user.seller = response.body['seller']
        user.merchant = Merchant.from(response.body['merchant'])

        if (user.seller) {
          user.merchant = user.seller.merchant
        }

        if (user.seller && user.seller.language && ['en','fi'].includes(user.seller.language)) {
          this._translation.use(user.seller.language)
        }

        if (this._shouldRedirect(user.merchant)) {
          this._maybeRedirectToMerchantHostname(user.merchant)
          return null
        } else {
          this._nextPreloadedMerchant(null)
          this._nextLoggedInUser(user)
          return user
        }
      }))
  }

  requestPassword(email: string) {
    return this._http
      .post(api.auth.request_password, { 'email': email })
      .pipe(catchError(this._handleError))
  }

  resetPassword(token: string, password: string, passwordConfirmation: string) {
    let body = {
      'password': password,
      'password_confirmation': passwordConfirmation,
      'reset_password_token': token
    }
    return this._http
      .post(api.auth.reset_password, body)
      .pipe(catchError(this._handleError))
  }

  private _preloadMerchantByHostname(hostname: string) {
    this._http.get<Merchant>(`${api.merchants.find}?hostname=${hostname}`).subscribe(
      merchantResponse => {
        let merchant = Merchant.from(merchantResponse)
        this._nextPreloadedMerchant(merchant)
      }
    )
  }

  private _nextPreloadedMerchant(merchant: Merchant) {
    this._preloadedMerchant = merchant
    this._preloadedMerchantSubject.next(this._preloadedMerchant)
    this._maybeRedirectToMerchantHostname(merchant)
  }

  private _shouldRedirect(merchant?: Merchant): boolean {
    return merchant && merchant.hostname && window.location.hostname != merchant.hostname
  }

  private _maybeRedirectToMerchantHostname(merchant?: Merchant): void {
    if (this._shouldRedirect(merchant)) {
      const goto = 'https://' + merchant.hostname
      if (this.isLoggedIn) {
        this.logout()
      }
      window.location.replace(goto)
    }
  }

  private _nextLoggedInUser(user: User) {
    localStorage.setItem('currentUser', JSON.stringify(user))
    this._currentUserSubject.next(user)
  }

  private _handleError(error: HttpErrorResponse) {
    return throwError(error)
  }
}