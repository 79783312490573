import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { formatISO, startOfTomorrow } from 'date-fns'
import { FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core'
import { LocalDate } from '@js-joda/core'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'
import { RacksService } from '@/services/racks.service'
import { SalesPeriodsService } from '@/services/sales-periods.service'
import { SellersService } from '@/services/sellers.service'
import { ServiceProductsService } from '@/services/service-products.service'
import { StoresService } from '@/services/stores.service'

import { Rack } from '@/domain/rack'
import { SalesPeriod } from '@/domain/sales-period'
import { ServiceProduct } from '@/domain/service-product'
import { ServiceProductVariation } from '@/domain/service-product-variation'
import { Store } from '@/domain/store'

import { SelectedVariationAndDate } from '@/model/calendar'
import { CustomPeriodStatus } from '@/domain/custom-period-status';

@UntilDestroy()
@Component({
  selector: 'sales-period-edit-modal',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'sales-period-edit-modal.component.html',
  styleUrls: [ 'sales-period-edit-modal.component.scss' ],
})
export class SalesPeriodEditModalComponent implements OnInit {

  /** Sales period to edit */
  @Input()
  set period(period: SalesPeriod) {
    this._updateFormFromPeriod(period)
  }

  /** Emitted on update of sales period */
  @Output()
  periodChange = new EventEmitter<SalesPeriod>()

  /** Emitted on creation of sales period */
  @Output()
  created = new EventEmitter<SalesPeriod>()

  /** Set to true to pop up the modal */
  @Input()
  set open(open: any) {
    this._open = open
    this.onChange()
  }

  /** Selections to preselect for sales period creation */
  @Input()
  set selection(selection: SelectedVariationAndDate) {
    this.editingPeriod = null
    this._updateFormFromSelection(selection)
    // this._setStoreFromSelection(selection)
  }

  /** Whether to provide a link button to the period. Default false */
  @Input()
  set link(whether: boolean) {
    this.linkToPeriod = whether
  }

  @Output()
  closed = new EventEmitter<boolean>()

  @ViewChild('salesPeriodModalTemplate')
  private modalTemplate: TemplateRef<any>

  @ContentChild('datepicker')
  datepicker: NgbDatepicker

  linkToPeriod: boolean = false
  editingPeriod: SalesPeriod = null
  availabilityOfSelectedRack: LocalDate[] = []

  datePickerOpen = false

  stores: Store[] = null
  serviceProducts: ServiceProduct[] = null

  racks: Rack[] = null
  racksByStoreId = {}

  serviceProductsForSelectedStore: ServiceProduct[] = null
  racksForSelectedStore: Rack[] = null

  private _open: boolean = false
  private _modal: NgbModalRef = null
  // private _selectedRackId: number = null

  salesPeriodForm = this.fb.group({
    store_id: [''],
    service_product_id: ['', Validators.required],
    service_product_variation_id: ['', Validators.required],
    rack_id: ['', Validators.required],
    rack_time_slot_id: [''],
    from: ['', Validators.required],
    to: ['', Validators.required],
    price: ['', Validators.required],
    commission: ['', Validators.required],
    max_items: [''],
    discount: [''],
    seller_id: ['', Validators.required],
    seller_note: [''],
    internal_note: [''],
    payment_status: [''],
    custom_period_status_id: ['']
  })

  constructor(
    public auth: AuthenticationService,
    public sellersService: SellersService,
    private salesPeriodsService: SalesPeriodsService,
    private serviceProductService: ServiceProductsService,
    private storesService: StoresService,
    private racksService: RacksService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private calendar: NgbCalendar,
    private formatter: NgbDateParserFormatter,
    private fb: FormBuilder
  ) {
    this.racksService.racks.pipe(untilDestroyed(this)).subscribe(racks => {
      if (racks.length == 0) { return }
      this.racks = racks
      this.racksByStoreId = this.racks.reduce((rv, val) => {
        (rv[val.store_id] = rv[val.store_id] || []).push(val)
        return rv
      }, {})
    })
  }

  ngOnInit(): void {
    this.listenToFormChanges()
    this._preload()
  }

  onChange(): void {
    if (this._open == true) {
      if (this._modal) {
        // already open
      } else {
        this.initializeSalesPeriodForm()
        this._modal = this.modalService.open(this.modalTemplate, { size: 'lg', centered: true, ariaLabelledBy: 'modal-basic-title' })
        this._modal.result.then(
          closingReason => { this._close() },
          dismissalReason => { this._close() })
      }
    } else {
      // close modal
      if (!this._modal) {
        // already closed
      } else {
        this._modal.close()
      }
    }
  }

  onDatePickerNavigate(event): void {
    var selectedRackId = +this.salesPeriodForm.get('rack_id').value
    if (!selectedRackId) {
      this.availabilityOfSelectedRack = [] // availability not known
    } else {
      this.racksService.getAvailability(selectedRackId, event.next.year, event.next.month).subscribe(
        availability => {
          this.availabilityOfSelectedRack = availability.available
        }
      )
    }
  }

  customPeriodStatusesEnabled(): boolean {
    let m = this.auth.currentUserValue.merchant
    return m && m.custom_period_statuses && m.custom_period_statuses.length > 0
  }

  getProductRecksForStore(storeId: number, serviceProductId: number): Rack[] {
    const store = this._storeForId(storeId)
    const product = this._productForId(serviceProductId)
    const racks = product ? product.recks
      .filter(r => { return r.store_id == store.id })
      .sort((a,b) => { return a.name ? a.name.localeCompare(b.name) : 0 }) : []
    return racks
  }

  getServiceProductVariations(serviceProductId: number): ServiceProductVariation[] {
    const sp = this._productForId(serviceProductId)
    return sp ? sp.service_product_variations.sort((a,b) => { return a.name ? a.name.localeCompare(b.name) : 0 }) : null
  }

  private _productForId(id: number): ServiceProduct {
    return this.serviceProducts ? this.serviceProducts.find(sp => { return sp.id == id }) : null
  }

  private _storeForId(id: number): Store {
    return this.stores ? this.stores.find(store => { return store.id == id }) : null
  }

  private _updateServiceProductsForSelectedStore(storeId: number) {
    this.serviceProductsForSelectedStore = this.storesService.filterProductsForStore(this.serviceProducts, storeId)

    let f = this.salesPeriodForm
    let productId = f.get('service_product_id').value
    if (!this._isSelectableProductId(productId)) {
      f.get('service_product_id').setValue('')
    }
  }

  private _updateRacksForSelectedStore(storeId: number) {
    this.racksForSelectedStore = this.storesService.filterRacksForStore(this.serviceProducts, storeId)

    let f = this.salesPeriodForm
    let rackId = +f.get('rack_id').value
    if (!this._isSelectableRackId(rackId)) {
      f.get('rack_id').setValue('')
    }
  }

  private _isSelectableRackId(rackId: number) {
    return this.racksForSelectedStore.filter(r => { return r.id == rackId }).length > 0
  }

  private _isSelectableProductId(productId: number): boolean {
    let x = this.serviceProductsForSelectedStore.filter(p => { return p.id == productId }).length > 0
    return x
  }

  onSubmitSalesPeriod() {
    let f = this.salesPeriodForm
    f.get('from').enable()
    f.get('to').enable()
    const create = !this.editingPeriod
    this.salesPeriodsService
      .saveOrCreate(this.salesPeriodForm.value, create ? null : this.editingPeriod.id)
      .subscribe(
        period => {
          this._close()
          if (create) {
            this.created.emit(period)
            // nb do not set to this.period
          } else {
            this.editingPeriod = period
            this.periodChange.emit(this.editingPeriod)
          }
        },
        error => {
          let msg = error.message + (error.errors ? (': ' + error.errors.join('; ')) : '')
          this.alertService.error(this.translate.instant(msg))
        }
      )
  }

  periodLinkHref(period: SalesPeriod): string {
    return `/period/${this.editingPeriod.id}`
  }

  private _close(): void {
    if (this._modal) {
      this._modal.close()
    }
    this._modal = null
    this.closed.emit(true) // true = did close
  }

  isPaid(): boolean {
    return this.editingPeriod && this.editingPeriod.payment_status === 'payment_status_paid'
  }

  cancelSalePeriodEdit(): void {
    this._close()
  }

  toggle(datepicker): void {
    datepicker.toggle()
    this.datePickerOpen = !this.datePickerOpen
  }

  onDatePickerClosed(event): void {
    this.datePickerOpen = false
    if (!this.selectionToDate) {
      if (!this.selectionFromDate) {
        this.selectionFromDate = this.asNgbDate(startOfTomorrow())
      }
      this.selectionToDate = this.selectionFromDate
    }
    let f = this.salesPeriodForm
    f.get('from').setValue(formatDate(this.ngbToDate(this.selectionFromDate), 'yyyy-MM-dd', 'en'))
    f.get('to').setValue(formatDate(this.ngbToDate(this.selectionToDate), 'yyyy-MM-dd', 'en'))
  }

  // start: datepicker code from online (modified)
  hoveredDate: NgbDate | null = null
  selectionFromDate: NgbDate | null
  selectionToDate: NgbDate | null
  onDateSelection(date: NgbDate) {
    if (!this.selectionFromDate && !this.selectionToDate) {
      this.selectionFromDate = date
    } else if (this.selectionFromDate && !this.selectionToDate && date && date.after(this.selectionFromDate)) {
      this.selectionToDate = date
    } else {
      this.selectionToDate = null
      this.selectionFromDate = date
    }
  }
  isHovered(date: NgbDate): boolean {
    return this.selectionFromDate && !this.selectionToDate && this.hoveredDate && date.after(this.selectionFromDate) && date.before(this.hoveredDate)
  }
  isInside(date: NgbDate): boolean {
    return this.selectionToDate && date.after(this.selectionFromDate) && date.before(this.selectionToDate)
  }
  isRange(date: NgbDate): boolean {
    return date.equals(this.selectionFromDate) || (this.selectionToDate && date.equals(this.selectionToDate)) || this.isInside(date) || this.isHovered(date)
  }
  isUnavailable(date: NgbDate): boolean {
    const avail = this.isAvailable(date)
    return !avail
  }
  isAvailable(date: NgbDate): boolean {
    if (!this.availabilityOfSelectedRack || this.availabilityOfSelectedRack.length == 0) {
      return true
    }
    return !!this.availabilityOfSelectedRack.find(a => {
      return date.year == a.year() && date.month == a.monthValue() && date.day == a.dayOfMonth()
    })
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input)
    const result = parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue
    return result
  }
  // datepicker stuff end

  ngbToDate(ngbDate: NgbDate): Date {
    if (!ngbDate) {
      return null
    }
    // HACK set the hours 12 midday to avoid UTC-vs-EET fights
    // TODO fix this hack so that this app works globally
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day, 12, 0, 0, 0)
  }

  get selectedSellerId(): number {
    let id = this.salesPeriodForm.get('seller_id').value
    return id ? +id : undefined
  }

  private asNgbDate(d: Date): NgbDate {
    return d == null ? null : new NgbDate(d.getFullYear(), d.getMonth() + 1, d.getDate())
  }

  customPeriodStatuses(): CustomPeriodStatus[] {
    return this.auth.currentUserValue.merchant.custom_period_statuses.sort((a,b) => { return a.order - b.order })
  }

  classForPaymentStatusRadioElement(status: string): string {
    return this.salesPeriodForm.get('payment_status').value == status ? 'badge-success' : 'badge-primary'
  }

  classForPaymentStatus(paymentStatus: string): string {
    switch (paymentStatus) {
      case 'payment_status_paid': return 'btn-success'
      case 'payment_status_pending': return 'btn-warning'
      case 'payment_status_error': return 'btn-warning'
      default: return 'btn-light'
    }
  }

  styleForCustomStatus(status: CustomPeriodStatus): any {
    if (this.isCustomStatusSelected(status)) {
      return { 'background-color': status.color, 'color': 'black' }
    } else {
      return {}
    }
  }

  isCustomStatusSelected(status: CustomPeriodStatus): boolean {
    var sel = this.salesPeriodForm.get('custom_period_status_id').value
    return sel == status.id
  }

  customStatusSelected(status: CustomPeriodStatus): void {
    if (this.editingPeriod.custom_period_status_id == status.id) {
      this.salesPeriodForm.get('custom_period_status_id').setValue('')
    }
  }

  private listenToFormChanges() {
    let f = this.salesPeriodForm
    f.get('store_id').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      let storeId = +value
      this._updateServiceProductsForSelectedStore(storeId)
      this._updateRacksForSelectedStore(storeId)
    })
    f.get('service_product_id').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      f.get('service_product_variation_id').setValue(null)
      f.get('rack_id').setValue(null)
    })
  }

  private _preload(): void {
    this.stores = this.auth.currentUserValue.merchant.stores
    if (this.serviceProducts === null) {
      this.serviceProductService.serviceProducts
        .subscribe(s => {
          this.serviceProducts = s
          this.serviceProducts.sort((a, b) => { return a.code.localeCompare(b.code) })
        })
    }
  }

  private _updateFormFromSelection(selection: SelectedVariationAndDate): void {
    if (!selection) {
      return
    }

    this.initializeSalesPeriodForm()

    let store_id = selection.rack.store_id
    let product_id = selection.product.id
    let variation_id = selection.variation.id
    let rack_id = selection.rack.id
    let from = selection.from
    let to = selection.to

    let price = selection.variation.price
    let commission = selection.variation.commission || 0

    this.salesPeriodForm.get('store_id').setValue(store_id)
    this.salesPeriodForm.get('service_product_id').setValue(product_id)
    this.salesPeriodForm.get('service_product_variation_id').setValue(variation_id)
    this.salesPeriodForm.get('rack_id').setValue(rack_id)
    this.salesPeriodForm.get('price').setValue(price)
    this.salesPeriodForm.get('commission').setValue(commission)

    let fromDate = from.asDate()
    let toDate = to.asDate()

    this.salesPeriodForm.get('from').setValue(formatISO(fromDate, { representation: 'date' }))
    this.salesPeriodForm.get('to').setValue(formatISO(toDate, { representation: 'date' }))
    this.selectionFromDate = this.asNgbDate(fromDate)
    this.selectionToDate = this.asNgbDate(toDate)
  }

  private _updateFormFromPeriod(period: SalesPeriod) {
    this.editingPeriod = period
    if (!this.editingPeriod) {
      return
    }
    this.initializeSalesPeriodForm()
  }

  private initializeSalesPeriodForm(): void {
    const period = this.editingPeriod
    if (!period) { // creating
      this.salesPeriodForm.setValue({
        store_id: this.stores && this.stores.length > 0 ? this.stores[0].id : '',
        service_product_id: '',
        service_product_variation_id: '',
        rack_id: '',
        rack_time_slot_id: null,
        from: '',
        to: '',
        price: '',
        commission: '',
        max_items: '',
        discount: '',
        seller_id: null,
        seller_note: '',
        internal_note: '',
        payment_status: null,
        custom_period_status_id: null
      })
    } else {
      this.salesPeriodForm.setValue({
        store_id: period.store.id,
        service_product_id: period.service_product.id,
        service_product_variation_id: period.service_product_variation_id,
        rack_id: period.rack_time_slot.reck_id,
        rack_time_slot_id: period.rack_time_slot_id,
        from: period.rack_time_slot.from,
        to: period.rack_time_slot.to,
        price: period.price,
        commission: period.commission,
        max_items: period.capacity || 0,
        discount: period.discount,
        seller_id: period.seller_id,
        seller_note: period.seller_note,
        internal_note: period.internal_note || '',
        payment_status: period.payment_status,
        custom_period_status_id: period.custom_period_status_id || ''
      })
      this.selectionFromDate = this.asNgbDate(new Date(period.rack_time_slot.from))
      this.selectionToDate = this.asNgbDate(new Date(period.rack_time_slot.to))

      if (this.editingPeriod.payment_status === 'payment_status_paid') {
        this.salesPeriodForm.controls['price'].disable()
      }
    }
    // cannot manually edit these; only via datepicker
    this.salesPeriodForm.controls['from'].disable()
    this.salesPeriodForm.controls['to'].disable()
  }
}
