import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { AuthenticationService } from '@/services/authentication.service'
import { ServiceProduct } from '@/domain/service-product'

import { Rack } from '@/domain/rack'
import { SalesPeriod } from '@/domain/sales-period'
import { ServiceProductVariation } from '@/domain/service-product-variation'
import { Store } from '@/domain/store'

import { SelectedVariationAndDate } from '@/model/calendar'

@Component({
  selector: 'sales-period-list',
  templateUrl: './sales-period-list.component.html'
})
export class SalesPeriodListComponent implements OnInit, OnChanges {

  @Input()
  salesPeriods: SalesPeriod[] = null

  @Input()
  title: string = null

  @Input()
  approvalActions: boolean = false

  /** List of columns to hide */
  @Input()
  hide: string = null

  /** Whether to include a 'create new sales period' button */
  @Input()
  create: boolean = false

  private _salesPeriods: SalesPeriod[] = null
  private _hasManyStores: boolean = false

  searchField: string = ''
  showCurrentFilter: boolean = false
  filteredPeriods: SalesPeriod[] = null

  filterStore: Store = null
  filterProduct: ServiceProduct = null
  filterVariation: ServiceProductVariation = null
  filterRack: Rack = null

  openSalesPeriodModal: boolean = false
  selection: SelectedVariationAndDate = null

  constructor(
    public auth: AuthenticationService,
    private _router: Router
  ) {
    this.auth.currentUser.subscribe(user => {
      // on logout, user is undefined
      this._hasManyStores = user && user.merchant && user.merchant.stores && user.merchant.stores.length > 1
    })
  }

  get multistore(): boolean {
    return this._hasManyStores
  }

  get maybeStoreName(): string {
    if (this.multistore && this.auth.isMerchant && this.filterStore) {
      return this.filterStore.name
    }
    return null
  }

  ngOnInit(): void { }

  ngOnChanges(): void {
    this._refresh()
  }

  openSalePeriodModal(): void {
    const product = this.filterProduct
    const variation = this.filterVariation
    if (this.filterProduct || this.filterVariation) {
      this.selection = new SelectedVariationAndDate(product, variation, this.filterRack, null, null)
    } else {
      this.selection = null
    }
    this.openSalesPeriodModal = true
  }

  salesPeriodModalClosed($event): void {
    this.selection = null
    this.openSalesPeriodModal = false
  }

  filterChanged($event: any): void {
    this._refresh()
  }

  salesPeriodCreated(period: SalesPeriod): void {
    this._router.navigate([ '/period', period.id ])
  }

  // private

  private _refresh(): void {
    this._salesPeriods = this.salesPeriods
    if (this._salesPeriods) {

      const isSeller = this.auth.isSeller
      const sellerId = this.auth.currentUserValue.seller ? this.auth.currentUserValue.seller.id : null

      this.filteredPeriods = this._salesPeriods.filter(period => {
        return (!isSeller || period.seller_id == sellerId)
            && (!this.filterStore || period.store.id === this.filterStore.id)
            && (!this.filterProduct || period.service_product.id === this.filterProduct.id)
            && (!this.filterVariation || period.service_product_variation.id === this.filterVariation.id)
            && (!this.filterRack || period.rack_time_slot.reck.id === this.filterRack.id)
      })

      // this.showCurrentFilter = this.filteredPeriods.length > 0
      this.showCurrentFilter = true
    }
  }
}
