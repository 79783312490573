<div class="col-md-4 offset-md-4 mt-5" style="margin-top: 60px;">

  <div class="card" style="margin-top: 100px;">
    <h4 class="card-header">
      {{'account.signup'|translate}}
      <span *ngIf="signupMerchants">
        &bull;
        <span *ngIf="!f.merchant_id.value">{{'account.select_merchant'|translate}}</span>
        <span *ngIf="f.merchant_id.value">{{merchantName(f.merchant_id.value)}}</span>
      </span>
    </h4>
    <div class="card-body">

      <alert></alert>

      <div *ngIf="!loading && !signupMerchants; else signupInit">
        <div class="alert alert-info">
          {{'account.cannot_signup_now'|translate}}
        </div>
      </div>

      <ng-template #signupInit>
        <div *ngIf="signupCompleted; else signup">
          <ngb-alert [type]="'info'" (closed)="confirmationAlertClosed()">
            {{'account.confirmation_needed'|translate}}
          </ngb-alert>
        </div>
      </ng-template>

      <ng-template #signup>
        <div *ngIf="signupMerchants">
          <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">

            <div class="form-group">
              <label for="merchant_id">{{'account.merchant'|translate}}</label>
              <br/>
              <div class="btn-group-toggle" ngbRadioGroup name="merchantId" formControlName="merchant_id" style="text-align: center;"><!-- NB not btn-gruop -->
                <label *ngFor="let merchant of signupMerchants" ngbButtonLabel class="p-2 m-1" style="font-size: 125%;"
                  [ngClass]="{'btn-success': merchant.id===f.merchant_id.value, 'btn-primary': merchant.id!==f.merchant_id.value}">
                  <input ngbButton type="radio" [value]="merchant.id">{{merchant.name}}
                </label>
              </div>
              <div *ngIf="submitted && f.merchant_id.errors" class="invalid-feedback">
                <div *ngIf="f.merchant_id.errors.required">{{'account.merchant_required'|translate}}</div>
              </div>
            </div>

            <div class="form-group">
              <label for="name">{{'account.name'|translate}}</label>
              <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">{{'account.name_required'|translate}}</div>
              </div>
            </div>

            <div class="form-group">
              <label for="phone">{{'account.phone'|translate}}</label>
              <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
              <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                <div *ngIf="f.phone.errors.required">{{'account.phone_required'|translate}}</div>
              </div>
            </div>

            <div class="form-group">
              <label for="username">{{'account.username_use_email'|translate}}</label>
              <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">{{'account.username_required'|translate}}</div>
              </div>
            </div>

            <div class="form-group">
              <label for="password">{{'account.password_choose'|translate}}</label>
              <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">{{'account.password_required'|translate}}</div>
              </div>
            </div>

            <div class="form-group">
              <label for="password_confirmation">{{'account.password_choose2'|translate}}</label>
              <input type="password" formControlName="password_confirmation" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" />
              <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
                <div *ngIf="f.password_confirmation.errors.required">{{'account.passwords_dont_match'|translate}}</div>
              </div>
            </div>

            <div class="form-group">
              <!-- <div *ngIf="hasTerms(f.merchant_id.value) || hasPolicy(f.merchant_id.value)">
                {{merchantName(f.merchant_id.value)}}<br/>
              </div> -->
              <div *ngIf="hasTerms(f.merchant_id.value)" class="mb-3">
                <div>
                  <input #tcCheckBox type="checkbox" formControlName="tcAccepted"/>
                  <label for="tcCheckBox">
                    <span class="ml-2" [innerHTML]="'account.accept_terms_and_conditions'|translate:{url:termsUrl(f.merchant_id.value)}"></span>
                  </label>
                </div>
              </div>
              <div *ngIf="f.merchant_id.value" style="display: table-row;">
                <input style="display: table-cell;" #marketingCheckBox type="checkbox" formControlName="accept_marketing"/>
                <label style="display: table-cell;" for="marketingCheckBox">
                  <span class="ml-2" [innerHTML]="'account.accept_marketing'|translate:{merchant:merchantName(f.merchant_id.value)}"></span>
                </label>
              </div>
              <div *ngIf="hasPolicy(f.merchant_id.value)">
                <a href="{{policyUrl(f.merchant_id.value)}}">{{'account.privacy_policy'|translate}}</a>
              </div>
            </div>

            <div class="d-flex">
              <div class="ml-auto">
                <button [disabled]="loading || (hasTerms(f.merchant_id.value) && !f.tcAccepted.value)" class="btn btn-primary">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{'account.create_account'|translate|uppercase}}
                </button>
              </div>
            </div>

            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
          </form>
        </div>
      </ng-template>
    </div>
  </div>
</div>
