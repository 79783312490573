import { Component, OnInit } from '@angular/core';

import { ProductFeaturesService } from '@/services/product-features.service';

import { ProductStatus } from '@/domain/product-status';

@Component({
  selector: 'app-product-status',
  templateUrl: './product-status.component.html'
})
export class ProductStatusComponent implements OnInit {

  constructor(private featureService: ProductFeaturesService) { }

  statuses: ProductStatus[];

  ngOnInit(): void {
    this.getStatuses();
  }

  getStatuses(): void {
    this.featureService.getStatuses()
      .subscribe(statuses => this.statuses = statuses)
  }

}
