import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { AuthenticationService } from '@/services/authentication.service'

@Injectable({ providedIn: 'root' })
export class SellerReservationEnabledGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _auth: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._auth.currentUserValue
    if (!currentUser || !this._auth.isSellerReservationEnabled) {
      this._router.navigate(['/'])
      return false
    }
    return true
  }
}