<table class="table table-sm table-hover" *ngIf="products && products.length>0">
  <thead>
    <tr>
      <th>{{'common.number'|translate|titlecase}}</th>
      <th>{{'common.type'|translate|titlecase}}</th>
      <th>{{'common.brand'|translate|titlecase}}</th>
      <th>{{'common.size'|translate|titlecase}}</th>
      <th>{{'common.product_note'|translate|titlecase}}</th>
      <th *ngIf="!isSelfServiceSeller && !auth.isMerchant">{{'common.price'|translate|titlecase}}</th>
      <th *ngIf="auth.isMerchant || isSelfServiceSeller">{{'common.price'|translate|titlecase}}</th>
      <th *ngIf="auth.isMerchant">{{'common.sold'|translate|titlecase}}</th>
      <th><!-- status --></th>
      <th><!-- actions --></th>
    </tr>
  </thead>
  <tbody *ngIf="(features.typesById$|async) && (features.brandsById$|async) && (features.sizesById$|async)">
    <tr *ngFor="let product of products" [ngClass]="{'table-success': product.recentlyCreated||product.recentlyUpdated}">
      <th scope="row" style="white-space: nowrap;">
        <span class="badge badge-light">
          <span *ngIf="!auth.isSeller">{{zeroPad6(period.seller_id)}}-</span>{{zeroPad6(product.number)}}
        </span>
        <span *ngIf="product.is_manual" class="badge badge-light ml-1">M</span>
      </th>
      <td><p><span *ngIf="!product.is_manual && product.product_type_id; else noInfo" >{{(features.typesById$ |async)[product.product_type_id] ?(features.typesById$ |async)[product.product_type_id ].name:'(?)'}}</span></p></td>
      <td><p><span *ngIf="!product.is_manual && product.product_brand_id; else noInfo">{{(features.brandsById$|async)[product.product_brand_id]?(features.brandsById$|async)[product.product_brand_id].name:'(?)'}}</span></p></td>
      <td><p><span *ngIf="!product.is_manual && product.product_size_id; else noInfo" >{{(features.sizesById$ |async)[product.product_size_id] ?(features.sizesById$ |async)[product.product_size_id ].name:'(?)'}}</span></p></td>
      <td><p>{{product.note}}</p></td>
      <ng-template #noInfo>&mdash;</ng-template>
      <td *ngIf="!isSelfServiceSeller && !auth.isMerchant" class="price">
        <span *ngIf="product.sale_row">{{product.sale_row.product_price | currency}}</span>
      </td>
      <td *ngIf="auth.isMerchant || isSelfServiceSeller" class="price">
        <p *ngIf="product.is_manual; else regularPrice">
          <span *ngIf="product.sale_row; else noPrice">{{product.sale_row.product_price | currency}}</span>
        </p>
        <ng-template #regularPrice><p>{{product.price | currency}}</p></ng-template>
        <ng-template #noPrice><p>&mdash;</p></ng-template>
      </td>
      <td *ngIf="auth.isMerchant">
        <span *ngIf="product.sale_row" class="badge badge-light p-1 m-1">{{product.sale_row.created_at | date: 'yyyy-MM-dd HH:mm' }}</span>
        <span *ngIf="product.sale_row && product.sale_row.store_id != period.store.id" class="badge badge-warning p-1 m-1">{{product.sale_row.store_name}}</span>
        <span *ngIf="product.sale_row && product.sale_row.store_id == period.store.id" class="badge badge-info    p-1 m-1">{{product.sale_row.store_name}}</span>
      </td>
      <td>
        <div *ngIf="!auth.isSeller || product.status!='initial'" style="display: inline-flex;">
          <div>
            <span class="badge badge-{{'product_status_style.'+product.status|translate}} p-1">
              {{'product_status.'+product.status|translate|uppercase}}
              <span *ngIf="product.sale_row && product.sale_row.discount_percent > 0"> {{product.sale_row.total|currency}} (-{{product.sale_row.discount_percent}}%) </span>
            </span>
          </div>
          <div *ngIf="auth.isMerchant && product.settled"><span class="badge badge-info p-1 ml-1">{{'common.settled'|translate|uppercase}}</span></div>
          <div *ngIf="auth.isMerchant && !product.settled && product.status=='sold'"><span class="badge badge-warning p-1 ml-1">{{'common.to_settle'|translate|uppercase}}</span></div>
        </div>
      </td>
      <td class="action-buttons">
        <div *ngIf="presentEdit">
          <button *ngIf="mayEdit(product)" class="btn btn-sm btn-primary px-1 py-1 mx-1" (click)="doEdit(product)"><i-bs name="pencil"></i-bs></button>
          <button *ngIf="!mayEdit(product)" class="btn btn-sm btn-outline-primary px-1 py-1 mx-1" [disabled]="true"><i-bs name="pencil"></i-bs></button>
        </div>
        <button *ngIf="auth.isMerchant" class="btn btn-sm btn-outline-primary px-1 py-1 mx-1" (click)="downloadProductPriceTag(product)"><i-bs name="printer"></i-bs></button>
        <div>
          <button *ngIf="product.deletable && !product.deleting && (!product.is_manual || !auth.isSeller)" class="btn btn-sm btn-outline-danger px-1 py-1 mx-1" (click)="doDelete(product)"><i-bs name="x"></i-bs></button>
          <button *ngIf="product.deleting" class="btn btn-sm btn-outline-danger px-1 py-1 mx-1"><span class="spinner-border spinner-border-sm mr-1"></span></button>
        </div>
      </td>
    </tr>
  </tbody>
</table>
