<div class="container-fluid d-flex justify-content-center" style="margin-top: 60px;">

  <div class="jumbotron mt-5 pt-3" style="background-color: white;" *ngIf="!auth.isLoggedIn; else showUserHome">
    <div *ngIf="merchantLogoUrl" class="merchant-logo-wrapper p-4">
      <img class="merchant-logo-img" src="{{merchantLogoUrl}}" alt="logo"/>
    </div>
    <h1 class="display-6">{{'home.welcome_lead'|translate}}</h1>
    <hr class="my-4">

    <div class="d-flex">
      <p class="lead">
        <a class="btn btn-primary btn-lg" routerLink="/login">{{'home.please_login'|translate|uppercase}}</a>
      </p>
      <p *ngIf="sellerSignupEnabled" class="lead ml-auto">
        <a class="btn btn-primary btn-lg" routerLink="/signup">{{'home.please_signup'|translate|uppercase}}</a>
      </p>

    </div>

  </div>

  <ng-template #showUserHome>

    <div class="jumbotron mt-5" *ngIf="auth.isSeller; else showUserHome">
      <h1 class="display-6">{{'home.welcome_lead'|translate}}</h1>
      <p class="welcome-text">{{'home.welcome_text'|translate}}</p>
      <hr class="my-4">
      <div class="lead">
        <div class="d-flex justify-content-between">
          <a class="btn btn-primary" routerLink="/reservation" *ngIf="auth.isSellerReservationEnabled">{{'home.go_to_reservation'|translate|uppercase}}</a>
          <a class="btn btn-primary" routerLink="/periods/current">{{'home.go_to_current'|translate|uppercase}}</a>
          <a class="btn btn-primary" routerLink="/periods/future">{{'home.go_to_future'|translate|uppercase}}</a>
        </div>
      </div>
    </div>

    <!-- merchant -->
    <div class="row" *ngIf="auth.isMerchant">
      <div class="col">

        <div class="card gutter-b">
          <div class="card-header">
            <h1>{{'merchant.home.title'|translate}}</h1>
          </div>
          <div class="card-body">
            <p>{{'merchant.home.welcome'|translate}}</p>
          </div>
        </div>

      </div>
    </div>
  </ng-template><!-- #showUserHome -->

</div>
