import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { AllSellersComponent } from '@/ui/merchant/sellers/all-sellers/all-sellers.component'
import { AuthGuard } from '@/util/auth/auth.guard'
import { CancelledSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/cancelled-sales-periods.component'
import { ConfirmComponent } from '@/ui/common/auth/confirm.component'
import { CurrentSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/current-sales-periods.component'
import { FutureSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/future-sales-periods.component'
import { HomeComponent } from '@/ui/home/home.component'
import { LoginComponent } from '@/ui/common/login/login.component'
import { MerchantCalendarViewComponent } from '@/ui/merchant/calendar/merchant-calendar-view.component'
import { MerchantDashboardViewComponent } from './ui/merchant/dashboard/merchant-dashboard-view.component'
import { MerchantPosComponent } from '@/ui/merchant/pos/merchant-pos.component'
import { MerchantSellerViewComponent } from '@/ui/merchant/sellers/merchant-seller-view.component'
import { PastSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/past-sales-periods.component'
import { PendingSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/pending-sales-periods.component'
import { PreliminarySalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/preliminary-sales-periods.component'
import { ProductsComponent } from '@/ui/merchant/service/products/products.component'
import { RackListComponent } from '@/ui/merchant/racks/rack-list/rack-list.component'
import { RejectedSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/rejected-sales-periods.component'
import { RequestPasswordComponent } from '@/ui/common/password/request-password.component'
import { ResetPasswordComponent } from '@/ui/common/password/reset-password.component'
import { SalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/sales-periods-view.component'
import { SalesPeriodViewComponent } from '@/ui/common/sales-period/sales-period-view.component'
import { SellerBookingViewComponent } from '@/ui/seller/booking/seller-booking-view.component'
import { SellerReservationEnabledGuard } from './util/auth/seller-reservation-enabled-guard'
import { SepaXmlViewComponent } from '@/ui/merchant/settlements/sepa-xml-view.component'
import { ServiceLevelsComponent } from '@/ui/merchant/service/levels/service-levels.component'
import { ServiceProductsViewComponent } from '@/ui/merchant/service/service-products-view.component'
import { ServiceTypesComponent } from '@/ui/merchant/service/types/service-types.component'
import { SettingsViewComponent } from '@/ui/common/settings/settings-view.component'
import { SettlementsViewComponent } from '@/ui/merchant/settlements/settlements-view.component'
import { SignupComponent } from './ui/common/signup/signup.component'
import { UnsettledSalesPeriodsViewComponent } from '@/ui/merchant/sales-periods/unsettled-sales-periods.component'

const routes: Routes = [

  // common

  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'confirm',
    component: ConfirmComponent
  },
  {
    path: 'request_password',
    component: RequestPasswordComponent
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent
  },

  {
    path: 'period/:id',
    component: SalesPeriodViewComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'seller', 'merchant' ] }
  },

  // merchant && seller

  {
    path: 'periods',
    component: SalesPeriodsViewComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'seller', 'merchant' ] },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'current'
      },
      {
        path: 'current',
        component: CurrentSalesPeriodsViewComponent
      },
      {
        path: 'preliminary',
        component: PreliminarySalesPeriodsViewComponent
      },
      {
        path: 'pending',
        component: PendingSalesPeriodsViewComponent
      },
      {
        path: 'future',
        component: FutureSalesPeriodsViewComponent
      },
      {
        path: 'unsettled',
        component: UnsettledSalesPeriodsViewComponent
      },
      {
        path: 'past',
        component: PastSalesPeriodsViewComponent
      },
      {
        path: 'cancelled',
        component: CancelledSalesPeriodsViewComponent
      },
      {
        path: 'rejected',
        component: RejectedSalesPeriodsViewComponent
      }
    ]
  },
  {
    path: 'settings',
    component: SettingsViewComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'seller', 'merchant' ] }
  },

  // merchant

  {
    path: 'dashboard',
    component: MerchantDashboardViewComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'merchant' ] }
  },
  {
    path: 'dashboard/:dates',
    component: MerchantDashboardViewComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'merchant' ] }
  },
  {
    path: 'calendar',
    component: MerchantCalendarViewComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'merchant' ] }
  },
  {
    path: 'merchant/service',
    component: ServiceProductsViewComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'merchant' ] },
    children: [
      {
        path: 'products',
        component: ProductsComponent
      },
      {
        path: 'racks',
        component: RackListComponent
      },
      {
        path: 'levels',
        component: ServiceLevelsComponent
      },
      {
        path: 'types',
        component: ServiceTypesComponent
      },
    ]
  },
  {
    path: 'settlements',
    component: SettlementsViewComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'merchant' ] }
  },
  {
    path: 'sepa-xml',
    component: SepaXmlViewComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'merchant' ] }
  },
  {
    path: 'merchant/sellers/all',
    component: AllSellersComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'merchant' ] }
  },
  {
    path: 'seller/:id',
    component: MerchantSellerViewComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'merchant' ] }
  },
  {
    path: 'merchant/pos',
    component: MerchantPosComponent,
    canActivate: [ AuthGuard ],
    data: { roles: [ 'merchant' ] }
  },

  // seller

  {
    path: 'reservation',
    component: SellerBookingViewComponent,
    canActivate: [ AuthGuard, SellerReservationEnabledGuard ],
    data: { roles: [ 'seller' ] }
  },
  {
    path: 'reservation/:callback',
    component: SellerBookingViewComponent,
    canActivate: [ AuthGuard, SellerReservationEnabledGuard ],
    data: { roles: [ 'seller' ] }
  },

  { path: '**', redirectTo: '' }
]

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
