<div class="container-fluid" style="margin-top: 60px;">

  <div class="row">
    <div class="col">

      <div class="card gutter-b">
        <div class="card-header d-flex">
          <h1 class="mb-5">{{'merchant.settlements.title'|translate}}</h1>
          <div class="ml-auto" *ngIf="merchant.hasSettlements()">

            <div class="btn-group mr-2">
              <button class="btn btn-primary" (click)="getSettlementReport(true)">{{'merchant.settlements.get_report_prev'|translate|uppercase}}<i-bs name="boxArrowDown" class="ml-1"></i-bs></button>
              <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div class="dropdown-menu dropdown-menu-right p-2">
                <h6>{{'merchant.settlements.load_previous_report'|translate}}</h6>
                <table>
                  <tbody>
                    <tr *ngFor="let year of years()">
                      <td class="pr-3"><b>{{year}}</b></td>
                      <td *ngFor="let month of months">
                        <button *ngIf="show(year,month)" class="btn btn-primary btn-sm p-2"
                          (click)="getPastSettlementReport(year, month)"
                          >{{zeroPad2(month)}}</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <button class="btn btn-primary" (click)="getSettlementReport()">{{'merchant.settlements.get_report'|translate|uppercase}}<i-bs name="boxArrowDown" class="ml-1"></i-bs></button>
          </div>
        </div>
        <div class="card-body">

          <div *ngIf="service.settlements | async as settlements">

            <div *ngIf="settlements.length == 0">
              {{'merchant.settlements.no_settlements'|translate}}
            </div>

            <table class="table table-sm table-hover" *ngIf="settlements.length > 0">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">{{'common.time'|translate|titlecase}}</th>
                  <th scope="col">{{'common.number'|translate|titlecase}}</th>
                  <th scope="col">{{'common.seller'|translate|titlecase}}</th>
                  <th scope="col">{{'common.settled_for'|translate|titlecase}}</th>
                  <th scope="col">{{'common.sales'|translate|titlecase}}</th>
                  <th scope="col">{{'common.commission'|translate|titlecase}}</th>
                  <th scope="col">{{'common.service_charges_short'|translate|titlecase}}</th>
                  <th scope="col">{{'common.settlement'|translate|titlecase}}</th>
                  <th scope="col">{{'common.overage'|translate|titlecase}}</th>
                  <th scope="col">{{'common.settlement_method'|translate|titlecase}}</th>
                  <th scope="col">{{'common.paid_status'|translate|titlecase}}</th>
                  <th scope="col">{{'common.paid_time'|translate|titlecase}}</th>
                  <th scope="col">SEPA</th>
                  <th scope="col">PDF</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let settlement of settlements">
                  <td scope="row"><span class="badge badge-light p-1">{{settlement.id}}</span></td>
                  <td scope="row" style="white-space: nowrap;">{{settlement.created_at | date: 'yyyy-MM-dd HH:mm'}}</td>
                  <td scope="row" style="white-space: nowrap;">
                    <span class="badge badge-light mr-1">
                      <a routerLink="/period/{{settlement.sales_period_id}}">{{settlement.sales_period_id}}</a>
                    </span>
                    <span *ngIf="settlement.number > 1">
                      / {{settlement.number}}
                    </span>
                  </td>
                  <td scope="row" style="white-space: nowrap;">{{settlement.seller_name}} <span class="badge badge-light p-1 m-0">{{settlement.seller_id}}</span></td>
                  <td scope="row">
                    <span class="badge" [ngClass]="{'badge-light':settlement.settled_for==='settled_for_seller', 'badge-warning':settlement.settled_for==='settled_for_merchant'}">
                      {{'common.settled_for_' + (settlement.settled_for==='settled_for_seller' ? 'seller' : 'merchant')|translate|uppercase}}
                    </span>
                  </td>

                  <td scope="row">{{settlement.sales_total | currency}}</td>
                  <td scope="row">{{settlement.commission_total | currency}}</td>
                  <td scope="row"><span *ngIf="settlement.service_charges_total > 0">{{settlement.service_charges_total | currency}}</span></td>
                  <td scope="row">{{settlement.settlement_total | currency}}</td>
                  <td scope="row"><span *ngIf="settlement.overage > 0">{{settlement.overage | currency}}</span></td>

                  <td scope="row">
                    <div *ngIf="settlement.settled_for==='settled_for_seller' && settlement.settlement_method">
                      <span class="badge badge-light">{{'settlement_method.' + settlement.settlement_method|translate|uppercase}}</span>
                    </div>
                  </td>
                  <td scope="row">
                    <div *ngIf="settlement.settled_for==='settled_for_seller'">
                      <span class="badge" [ngClass]="{'badge-success':settlement.payment_status==='payment_status_paid', 'badge-warning':settlement.payment_status==='payment_status_none'}">{{'settlement_payment_status.' + settlement.payment_status|translate|uppercase}}</span>
                    </div>
                  </td>
                  <td scope="row">
                    <div *ngIf="settlement.settled_for==='settled_for_seller'">
                      <span *ngIf="settlement.payment_time" class="badge badge-success">{{settlement.payment_time | date: 'yyyy-MM-dd HH:mm'}}</span>
                    </div>
                  </td>

                  <td scope="row">
                    <div *ngIf="settlement.payment_initiation_message_id">
                      <span class="badge badge-light p-1 m-0">{{settlement.payment_initiation_message_id}}</span>
                    </div>
                  </td>

                  <td scope="row">
                    <a role="button" class="btn btn-sm btn-primary p-1" [href]="pdfPath(settlement)" target="_blank" *ngIf="hasPdf(settlement)"><i-bs name="fileText"></i-bs></a>
                    <button class="btn btn-sm btn-outline-light p-1" *ngIf="!hasPdf(settlement)"><i-bs name="fileText"></i-bs></button>
                  </td>
                </tr>
              </tbody>
            </table>

        </div>

        </div><!-- card body -->
      </div><!-- card -->

    </div><!-- col -->
  </div><!-- row -->

</div><!-- container-fluid -->
