import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Observable } from 'rxjs'

import { SalesPeriodsService } from '@/services/sales-periods.service'
import { SellersService } from '@/services/sellers.service'

import { SalesPeriod } from '@/domain/sales-period'
import { Seller } from '@/domain/seller'

@UntilDestroy()
@Component({
  selector: 'merchant-seller-view',
  templateUrl: './merchant-seller-view.component.html',
})
export class MerchantSellerViewComponent implements OnInit {

  seller: Seller
  periods$: Observable<SalesPeriod[]>

  totalSales: number = null

  constructor(
    private _sellers: SellersService,
    private _salesPeriods: SalesPeriodsService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._route.params.pipe(untilDestroyed(this)).subscribe(
      params => {
        const navigatedToId = params['id']
        this._sellers.watch(navigatedToId).subscribe(seller => {
          // this._router.navigate(['/merchant/sellers/all'])
          this.seller = seller
          if (seller) {
            this.periods$ = this._salesPeriods.sellerPeriods$(this.seller)
            this.periods$.subscribe(periods => {
              // this.totalSales = periods.reduce((acc, val) => acc + +val.sales_total, 0.0)
              this.totalSales = null // NB we do not show total sales for now
            })
          }
        })
      }
    )
  }
}
