export class SettlementServiceCharge {
  public id: number
  public created_at: string
  public updated_at: string

  public description: string
  public amount: number
  public tax_amount: number
  public amount_without_tax: number

  public tax_percentage: number
  public tax_name: string
}
