import { StoreClosedWeekday } from '@/domain/store-closed-weekday'
import { StoreClosedDay } from '@/domain/store-closed-day'
import { PlainDate } from '@/util/date-utils'
import { LocalDate } from '@js-joda/core'

export class Store {
  id: number

  name: string
  address: string
  phone: string
  email: string
  url: string
  store_closed_weekdays: StoreClosedWeekday[]
  store_closed_days: StoreClosedDay[]
  last_reservation_date: LocalDate

  constructor(id: number = null, name: string = null) {
    this.id = id;
    this.name = name;
  }

  public static from(json: any): Store {
    const store = Object.assign(new Store(), json)
    if (json.store_closed_weekdays) {
      store.store_closed_weekdays = json.store_closed_weekdays.map((w: any) => { return StoreClosedWeekday.from(w) })
    }
    if (json.store_closed_days) {
      store.store_closed_days = json.store_closed_days.map((w: any) => { return StoreClosedDay.from(w) })
    }
    if (json.last_reservation_date) {
      store.last_reservation_date = LocalDate.parse(json.last_reservation_date)
    }
    return store
  }

  /** @name 'mon', 'tue', etc */
  public isClosedOnWeekday(name: string): boolean {
    let isClosed = false
    if (this.store_closed_weekdays && this.store_closed_weekdays.length > 0) {
      this.store_closed_weekdays.forEach(weekday => {
        isClosed = isClosed || weekday.day == name
      })
    }
    return isClosed
  }

  public isClosedOn(plainDate: PlainDate): boolean {
    if (!plainDate) {
      return false
    }
    let isClosed = false
    let localDate = plainDate.asLocalDate()
    if (this.store_closed_weekdays && this.store_closed_weekdays.length > 0) {
      this.store_closed_weekdays.forEach(weekday => {
        // console.log(this.name + ' closed on ' + weekday + ': ' + weekday.isClosed(localDate))
        isClosed = isClosed || weekday.isClosed(localDate)
      })
    }
    if (isClosed) {
      // console.log(this.name, this.store_closed_weekdays, plainDate, isClosed)
      return true
    }
    if (this.store_closed_days && this.store_closed_days.length > 0) {
      this.store_closed_days.forEach(day => {
        isClosed = isClosed || day.date.equals(localDate)
      })
    }
    return isClosed
  }
}
