<div class="bg-light border-right" id="sidebar-wrapper">
  <div class="sidebar-heading">Product Features</div>
  <div class="list-group list-group-flush">
    <a class="nav-link list-group-item list-group-item-action bg-light" routerLink="/product-features/statuses">Status</a>
    <a class="nav-link list-group-item list-group-item-action bg-light" routerLink="/product-features/categories">Category</a>
    <a class="nav-link list-group-item list-group-item-action bg-light" routerLink="/product-features/types">Type</a>
    <a class="nav-link list-group-item list-group-item-action bg-light" routerLink="/product-features/brands">Brand</a>
    <a class="nav-link list-group-item list-group-item-action bg-light" routerLink="/product-features/sizes">Size</a>
    <a class="nav-link list-group-item list-group-item-action bg-light" routerLink="/product-features/colors">Color</a>
    <a class="nav-link list-group-item list-group-item-action bg-light" routerLink="/product-features/conditions">Condition</a>
  </div>
</div>
