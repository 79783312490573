import { SaleRow } from '@/domain/sale-row'

export class Sale {

  public id: number
  public store_id: number
  public timestamp: string
  public discount_percent: number
  public created_at: string
  public updated_at: string
  public nonce: string

  public total: number

  public sale_rows: SaleRow[]

  constructor() { }

  public static from(json: any): Sale {
    const sale = Object.assign(new Sale(), json)
    if (json.sale_rows) {
      sale.sale_rows = json.sale_rows.map((row: any) => { return SaleRow.from(row) })
    }
    return sale
  }
}
