import { LocalDate } from '@js-joda/core'

export class RackClosedDay {
  public id: number
  public date: LocalDate
  public description: string

  public static from(json: any): RackClosedDay {
    const d = Object.assign(new RackClosedDay(), json)
    if (json.date) {
      d.date = LocalDate.parse(json.date)
    }
    return d
  }
}
