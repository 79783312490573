import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ibanValidator } from 'ngx-iban'
import { TranslateService } from '@ngx-translate/core'
import { UntilDestroy } from '@ngneat/until-destroy'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'
import { MerchantsService } from '@/services/merchants.service'

import { Merchant } from '@/domain/merchant'
import { StoreClosedWeekday } from '@/domain/store-closed-weekday'
import { StoreClosedDay } from '@/domain/store-closed-day'
import { User } from '@/domain/user'
import { LocalDate } from '@js-joda/core'

@UntilDestroy()
@Component({
  selector: 'merchant-profile',
  templateUrl: 'merchant-profile.component.html'
})
export class MerchantProfileComponent implements OnInit {

  @Input()
  merchant: Merchant = null

  @ViewChild('basicInfoName')
  basicInfoName: ElementRef

  @ViewChild('paymentInfoIban')
  paymentInfoIban: ElementRef

  // editable - basic info
  basicInfoForm = this._fb.group({
    name: ['', Validators.required],
    official_name: ['', Validators.required],
    homepage: ['', Validators.required],
    email: ['', Validators.required],
    phone: ['', Validators.required],
    contact: [''],
    address: ['', Validators.required],
    business_id: ['', Validators.required],
    privacy_policy_url: ['', Validators.required],
    terms_and_conditions_url: ['', Validators.required]
  })

  // editable - payment info
  paymentInfoForm = this._fb.group({
    iban: ['', ibanValidator('FI')],
    bic: [''],
    checkout_merchant_id: ['']
  })

  reservationInfoForm = this._fb.group({
    mon: [true],
    tue: [false],
    wed: [false],
    thu: [false],
    fri: [false],
    sat: [false],
    sun: [false],
    seller_registration_enabled: [''],
    seller_reservations_enabled: [''],
    seller_reservation_checkout_enabled: [''],
    seller_reservation_checkout_required: [''],
    // reservation_calendar_last_date: ['']
  })

  // shortcuts
  f3 = this.reservationInfoForm

  // readonly
  primary_admin_user: User
  admin_users: User[]
  static_logo_asset: string
  price_tag_logo_asset: string
  hostname: string
  create_product_features_enabled: boolean

  editingBasicInfo: boolean = false
  editingPaymentInfo: boolean = false
  editingReservationInfo: boolean = false

  constructor(
    private _merchantsService: MerchantsService,
    private _auth: AuthenticationService,
    private _alertService: AlertService,
    private _translate: TranslateService,
    private _fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this._reset()
  }

  editBasicInfo(): void {
    this._enableBasicInfoForm()
    this.editingBasicInfo = true
    this.basicInfoName.nativeElement.focus()
  }

  cancelBasicInfoEdit(): void {
    this._reset()
  }

  editPaymentInfo(): void {
    this._enablePaymentInfoForm()
    this.editingPaymentInfo = true
    this.paymentInfoIban.nativeElement.focus()
  }

  cancelPaymentInfoEdit(): void {
    this._reset()
  }

  editReservationInfo(): void {
    this._enableReservationInfoForm()
    this.editingReservationInfo = true
  }

  cancelReservationInfoEdit(): void {
    this._reset()
  }

  onSubmitBasicForm(): void {

  }

  onSubmitPaymentForm(): void {

  }

  onSubmitReservationForm(): void {

  }

  // private

  private _reset(): void {
    this.editingBasicInfo = false
    this.editingPaymentInfo = false
    this.editingReservationInfo = false
    this._initForms()
    this._disableForms()
  }

  private _enableBasicInfoForm(): void {
    this.basicInfoForm.enable()
  }

  private _enablePaymentInfoForm(): void {
    this.paymentInfoForm.enable()
  }

  private _enableReservationInfoForm(): void {
    this.reservationInfoForm.enable()
  }

  private _disableForms(): void {
    this.basicInfoForm.disable()
    this.paymentInfoForm.disable()
    this.reservationInfoForm.disable()
  }

  private _initForms(): void {
    this._initBasicInfoForm()
    this._initPaymentForm()
    // this._initReservationInfoForm()
  }

  private _initBasicInfoForm(): void {
    this.basicInfoForm.setValue({
      name: this.merchant.name,
      official_name: this.merchant.official_name,
      homepage: this.merchant.homepage,
      email: this.merchant.email,
      phone: this.merchant.phone,
      contact: this.merchant.contact,
      address: this.merchant.address,
      business_id: this.merchant.business_id,
      privacy_policy_url: this.merchant.privacy_policy_url,
      terms_and_conditions_url: this.merchant.terms_and_conditions_url
    })
  }

  private _initPaymentForm(): void {
    this.paymentInfoForm.setValue({
      iban: this.merchant.iban,
      bic: this.merchant.bic,
      checkout_merchant_id: this.merchant.checkout_merchant_id
    })
  }

  // private _initReservationInfoForm(): void {
  //   this.reservationInfoForm.setValue({
  //     mon: this.merchant.isClosedOnWeekday('mon'),
  //     tue: this.merchant.isClosedOnWeekday('tue'),
  //     wed: this.merchant.isClosedOnWeekday('wed'),
  //     thu: this.merchant.isClosedOnWeekday('thu'),
  //     fri: this.merchant.isClosedOnWeekday('fri'),
  //     sat: this.merchant.isClosedOnWeekday('sat'),
  //     sun: this.merchant.isClosedOnWeekday('sun'),
  //     seller_registration_enabled: this.merchant.seller_registration_enabled,
  //     seller_reservations_enabled: this.merchant.seller_reservations_enabled,
  //     seller_reservation_checkout_enabled: this.merchant.seller_reservation_checkout_enabled,
  //     seller_reservation_checkout_required: this.merchant.seller_reservation_checkout_required,
  //     reservation_calendar_last_date: this._asNgbDatePickerModel(this.merchant.reservation_calendar_last_date)
  //   })
  // }

  private _asNgbDatePickerModel(date: LocalDate): any {
    return date ? { day: date.dayOfMonth(), month: date.monthValue(), year: date.year() } : null
  }
}