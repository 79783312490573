import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { Component, ElementRef, Input, OnChanges, OnInit, Output, ViewChild, EventEmitter } from '@angular/core'
import { formatISO } from 'date-fns'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { NgSelectComponent } from '@ng-select/ng-select'
import { saveAs } from 'file-saver'
import { Observable } from 'rxjs'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'
import { ProductFeaturesService } from '@/services/product-features.service'
import { SalesPeriodsService } from '@/services/sales-periods.service'
import { SettlementsService } from '@/services/settlements.service'

import { Product } from '@/domain/product'
import { ProductsService } from '@/services/products.service'
import { SalesPeriod } from '@/domain/sales-period'
import { Settlement } from '@/domain/settlement'
import { SettlementServiceCharge } from '@/domain/settlement-service-charge'

@Component({
  selector: 'sales-period',
  templateUrl: 'sales-period.component.html',
})
export class SalesPeriodComponent implements OnInit, OnChanges {

  @Input()
  period: SalesPeriod

  @Output()
  periodChange = new EventEmitter<SalesPeriod>()

  settlements$: Observable<Settlement[]> = null

  mayCreateFeatures: boolean = false
  mayAddProducts: boolean = false
  mayAddProductNote: boolean = false
  isCreating: boolean = false
  isEditing: boolean = false

  private _editingProductId = null

  maybePrintingTagsForSalesPeriod: SalesPeriod = null
  printingInitialCount: number = 0
  printingConfirmedCount: number = 0
  printingPrintedCount: number = 0
  printingOtherCount: number = 0

  @ViewChild('productTypeSelect') productTypeSelect: NgSelectComponent
  @ViewChild('productBrandSelect') productBrandSelect: NgSelectComponent
  @ViewChild('productSizeSelect') productSizeSelect: NgSelectComponent
  @ViewChild('productPriceInput') productPriceInput: ElementRef

  productForm = this.fb.group({
    type_id    : ['', this.requiredUnlessHas('new_type_name')],
    brand_id   : ['', this.requiredUnlessHas('new_brand_name')],
    size_id    : ['', this.requiredUnlessHas('new_size_name')],
    note       : [''],
    price      : ['', Validators.required],
    init_price : [''],
    new_brand_name: [''],
    new_type_name : [''],
    new_size_name : ['']
  })

  settlementServiceChargeForm = this.fb.group({
    description:    ['', Validators.required],
    amount:         ['', Validators.required],
    tax_name:       ['', Validators.required],
    tax_percentage: ['', Validators.required]
  })

  manualProductForm = this.fb.group({
    manual_products_count: [ 5 ]
  })

  openSalesPeriodModal: boolean = false

  private modalRef = null
  private confirmationModalRef = null

  settelementForDeletionConfirmation: Settlement = null
  private confirmSettlementDeletionModal = null

  settlingForSeller: boolean
  private settlementConfirmationModalRef = null

  settlementServiceCharges: SettlementServiceCharge[] = []
  addingSettlementServiceCharge: boolean = false

  constructor(
    public features: ProductFeaturesService,
    public settlementsService: SettlementsService,
    public auth: AuthenticationService,
    private salesPeriodsService: SalesPeriodsService,
    private productsService: ProductsService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.mayCreateFeatures = this.auth.currentUserValue.merchant && this.auth.currentUserValue.merchant.maybe_create_product_features
  }

  ngOnChanges() {
    if (this.period) {
      this.mayAddProducts = this.auth.isMerchant || (this.auth.isSeller && this.period.service_product.service_level.is_self_service)
      this.mayAddProductNote = this.mayAddProducts
      this._getSettlements()
    }
  }

  openSalePeriodModal(): void {
    this.openSalesPeriodModal = true
  }

  salesPeriodModalClosed($event): void {
    this.openSalesPeriodModal = false
    this.periodChange.emit(this.period)
  }

  mayPrint(): boolean {
    return this.auth.isMerchant && this.period.products && this.period.products.length > 0
  }

  mayDeleteSettlement(settlement: Settlement): boolean {
    return settlement.settled_for == 'settled_for_merchant'
      || (settlement.settlement_method == 'method_cash')
      || (settlement.payment_status != 'payment_status_paid' && !settlement.payment_initiation_message_id)
  }

  maybeDeleteSettlement(settlement: Settlement, modal): void {
    this.settelementForDeletionConfirmation = settlement
    this.confirmSettlementDeletionModal = this.modalService.open(modal, { centered: true, ariaLabelledBy: 'modal-basic-title' })
    this.confirmSettlementDeletionModal.result.then(
      closingReason => {
        if (closingReason == 'confirmed') {
          this.settlementsService.delete(settlement).subscribe(
            success => {
              this.settelementForDeletionConfirmation = null
            },
            error => { }
          )
        }
      },
      dismissalReason => {
        this.settelementForDeletionConfirmation = null
      } )
  }

  hasServiceCharges(settlement: Settlement): boolean {
    return settlement.settlement_service_charges && settlement.settlement_service_charges.length > 0
  }

  maybeConfirm(modal): void {
    this.confirmationModalRef = this.modalService.open(modal, { centered: true, ariaLabelledBy: 'modal-basic-title' })
    this.confirmationModalRef.result.then(
      closingReason => { if (closingReason == 'confirmed') { this.confirmPrices() } },
      dismissalReason => { } )
  }

  addProducts(): void {
    this.isCreating = true
    this.clearProductForm()
  }

  /** A product was selected for editing in the product-table */
  edit(product: Product) {
    this.isCreating = false
    this.isEditing = true
    this._editingProductId = product.id
    this.initializeProductForm(product)
  }

  cancel() {
    this.isCreating = false
    this.isEditing = false
    this._editingProductId = null
    this.clearProductForm()
  }

  hasAnyPricesToConfirm(): boolean {
    return this.period.product_counts.initial > 0
  }

  maybeCancel(modal): void {
    let options = {
      centered: true,
      ariaLabelledBy: 'modal-basic-title'
    }
    this.modalRef = this.modalService.open(modal, options)
    this.modalRef.result.then(
      closingReason => {
        this.salesPeriodsService.setStatus(this.period.id, 'status_cancelled').subscribe(
          period => {
            // TODO 20230105 remove due to cable broadcast
            // this.period.status = 'status_cancelled' // setting field doesn't cause change propagation to sales-period-header
            this.period = period
            this.periodChange.emit(this.period)
          },
          error => { }
        )
      },
      dismissalReason => {  } )
  }

  viewSettlements(modal): void {
    // console.log('viewing settlements, calling service')
    // this._getSettlements()
    let options = {
      centered: true,
      ariaLabelledBy: 'modal-basic-title'
    }
    if (this.auth.isMerchant) {
      options['size'] = 'lg'
    }
    this.modalRef = this.modalService.open(modal, options)
    this.modalRef.result.then(
      closingReason => {  },
      dismissalReason => {  } )
  }

  settle(forSeller: boolean, modal): void {
    this.settlingForSeller = forSeller
    this.settlementConfirmationModalRef = this.modalService.open(modal,
      { centered: true, ariaLabelledBy: 'modal-basic-title' })
    this.settlementConfirmationModalRef.result.then(
      settlementMethod => {
        this.settlementsService.createSettlement(this.period.id, forSeller, settlementMethod, this.settlementServiceCharges).subscribe(
          settlement => {
            this.settlementServiceCharges = [] // reset
            // updates via cable subscription
            // this.settlements.push(settlement)
          },
          error => { this.alertService.error(error.message) }
        )
      },
      dismissalReason => { } )
  }

  markSettlementPaid(settlement: Settlement): void {
    this.settlementsService.markPaid(settlement).subscribe(
      updated => {
        // TODO 20230105 remove due to cable broadcast
        // TODO replace hackety-hack with proper update from service
        settlement.payment_status = updated.payment_status
        settlement.payment_time = updated.payment_time
      },
      error => { this.alertService.error(error.message) }
    )
  }

  hasPdf(settlement: Settlement): boolean {
    return !!settlement.pdf_document && !!settlement.pdf_document.uuid
  }

  pdfPath(settlement: Settlement): string {
    return `/s/${settlement.pdf_document.uuid}`
  }

  asDate(date: string): string {
    return formatISO(new Date(date), { representation: 'date' })
  }

  searchWithSynonyms(term: string, item: any): boolean {
    let t = term.trim().toLowerCase()
    if (item.name && item.name.toLowerCase().includes(t)) {
      return true
    }
    if (item.synonyms) {
      let synonyms = []
      item.synonyms.split(',').forEach(s => { synonyms.push(s.trim().toLowerCase()) })
      for (let i = 0; i < synonyms.length; i++) {
        if (synonyms[i].includes(t)) {
          return true
        }
      }
    }
    return false
  }

  addManualProducts() {
    const count = parseInt(this.manualProductForm.get('manual_products_count').value)
    if (!count || count < 1) {
      return
    }
    this.productsService.createManualProducts(count, this.period.id).subscribe(
      products => {
        this.cancel() // close 'add products' view
        // the sales period will update via cable with new products
      }
    )
  }

  save(): void {
    this.productsService
      .saveOrCreate(this.period.id, this.productForm.value, this._editingProductId)
      .subscribe(
        product => {
          this.didRecentlyCreate(product, 5000)

          // TODO 20230105 remove/update due to cable broadcast
          if (this._editingProductId) {
            this.replace(this._editingProductId, product)
            let priceDiff = product.price - this.productForm.get('init_price').value
            this.period.value_total = parseFloat("" + this.period.value_total) + priceDiff
          }

          this._editingProductId = null
          this.clearProductForm()
          this.isEditing = false // if was editing, close if was creating, leave open
          if (this.isCreating) {
            this.productTypeSelect.focus()
          }
        },
        error => {
          this.alertService.error(error)
        }
      )
  }

  maybeDownloadPriceTags(modal): void {
    this.maybePrintTags(this.period)
    this.modalRef = this.modalService.open(modal, { centered: true, ariaLabelledBy: 'modal-basic-title' })
    this.modalRef.result.then(
      closingReason => {
        if (closingReason == 'confirmed' || closingReason == 'tagged') {
          this.downloadPriceTags(this.period, closingReason)
        }
        this.resetPrinting()
      },
      dismissalReason => {
        this.resetPrinting()
      }
    )
  }

  hasReceiptPdf(): boolean {
    return !!this.period.receipt_doc
  }

  receiptPdfUrl(): string {
    return `/r/${this.period.receipt_doc}.pdf`
  }

  createCharge(): void {
    this.initializeSettlementServiceChargeForm()
    this.addingSettlementServiceCharge = true
  }

  saveCharge(): void {
    let charge = new SettlementServiceCharge()

    charge.description = this.settlementServiceChargeForm.value.description
    charge.description = charge.description.trim()
    if (charge.description == "") {
      charge.description = "Palvelumaksu"
    }

    let value = this.settlementServiceChargeForm.value.amount
    value = value.trim()
    value = value.replace("€", "")
    value = value.replace(",", ".")
    charge.amount = Math.max(parseFloat(value), 0.0)

    charge.tax_name = this.settlementServiceChargeForm.value.tax_name
    charge.tax_name = charge.tax_name.trim()
    if (charge.tax_name == "") {
      charge.tax_name = "ALV"
    }

    value = this.settlementServiceChargeForm.value.tax_percentage
    value = value.trim()
    value = value.replace("%", "")
    charge.tax_percentage = Math.max(parseFloat(value), 0)
    if (charge.tax_percentage == 0) {
      charge.tax_percentage = this._taxRate()
    }

    if (charge.amount > 0.0) {
      this.settlementServiceCharges.push(charge)
    }

    this.cancelAddCharge()
  }

  cancelAddCharge(): void {
    this.addingSettlementServiceCharge = null
    this.clearSettlementServiceChargeForm()
  }

  deleteCharge(charge: SettlementServiceCharge) {
    this.settlementServiceCharges.forEach((item, index) => {
      if (item === charge) {
        this.settlementServiceCharges.splice(index, 1)
      }
    })
  }

  private downloadPriceTags(period: SalesPeriod, status: string): void {
    this.salesPeriodsService.getPriceTags(period, status)
      .subscribe(
        blob => { saveAs(blob, `hintalaput_jakso-${period.id}.pdf`) },
        error => { this.alertService.error(error) }
      )
  }

  private resetPrinting(): void {
    this.maybePrintingTagsForSalesPeriod = null
    this.printingInitialCount = 0
    this.printingConfirmedCount = 0
    this.printingPrintedCount = 0
    this.printingOtherCount = 0
  }

  private maybePrintTags(period: SalesPeriod): void {
    this.resetPrinting()
    if (period && period.products && period.products.length > 0) {
      period.products.forEach(p => {
        this.printingInitialCount   += p.status == 'initial' ? 1 : 0
        this.printingConfirmedCount += p.status == 'confirmed' ? 1 : 0
        this.printingPrintedCount   += p.status == 'tagged' ? 1 : 0
        this.printingOtherCount     += (p.status != 'initial' && p.status != 'confirmed' && p.status != 'tagged') ? 1 : 0
      })
    }
    this.maybePrintingTagsForSalesPeriod = period
  }

  private confirmPrices() {
    const products = this.period.products.filter(p => { return p.status == 'initial' })
    const ids = products.map(p => p.id)
    this.productsService.updateProductStatus('confirmed', ids)
      .subscribe(
        ok => { },
        error => { this.alertService.error(error) }
      )
  }

  private _taxRate(): number {
    let now = new Date()
    let cutoff = new Date('2024-09-01')
    if (now >= cutoff) {
      return 25.5
    } else {
      return this.period.currentVatRate
    }
  }

  private initializeSettlementServiceChargeForm(): void {
    this.settlementServiceChargeForm.setValue({
      description: '',
      amount: '',
      tax_name: 'ALV',
      tax_percentage: '' + this._taxRate()
    })
  }

  private clearSettlementServiceChargeForm(): void {
    this.settlementServiceChargeForm.setValue({
      description: '',
      amount: '',
      tax_name: '',
      tax_percentage: ''
    })
  }

  private initializeProductForm(product: Product): void {
    this.productForm.setValue({
      type_id: product.product_type_id,
      brand_id: product.product_brand_id,
      size_id: product.product_size_id,
      note: product.note,
      price: product.price,
      init_price: product.price,
      new_brand_name: '',
      new_type_name: '',
      new_size_name: ''
    })
    this.manualProductForm.setValue({
      manual_products_count: ''
    })
  }

  private clearProductForm(): void {
    this.productForm.setValue({
      type_id: '',
      brand_id: '',
      size_id: '',
      note: '',
      price: '',
      init_price: '',
      new_brand_name: '',
      new_type_name: '',
      new_size_name: ''
    })
    this.manualProductForm.setValue({
      manual_products_count: 5
    })
  }

  private _getSettlements(): void {
    this.settlements$ = this.settlementsService.salesPeriodSettlements$(this.period.id)
  }

  private requiredUnlessHas(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.parent && control.parent.get(controlName) ? null : Validators.required(control)
    }
  }

  private didRecentlyCreate(product: Product, timeout: number) {
    product.recentlyCreated = true
    setTimeout(() => { product.recentlyCreated = false }, timeout)
  }

  private replace(id, product) {
    let toRemove = this.period.products.find(sp => { return sp.id == id })
    this.period.products.splice(this.period.products.indexOf(toRemove), 1)
    this.period.products.unshift(product)
  }
}