import { Component, OnInit } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { AuthenticationService } from '@/services/authentication.service'
import { MerchantsService } from '@/services/merchants.service'
import { SellersService } from '@/services/sellers.service'

import { Merchant } from '@/domain/merchant'
import { Seller } from '@/domain/seller'

@UntilDestroy()
@Component({
  selector: 'settings-view',
  templateUrl: 'settings-view.component.html'
})
export class SettingsViewComponent implements OnInit {

  seller: Seller = null
  merchant: Merchant = null

  constructor(
    public auth: AuthenticationService,
    private _sellersService: SellersService,
    private _merchantsService: MerchantsService
  ) { }

  ngOnInit(): void {
    if (this.auth.isSeller) {
      this._sellersService.watch(this.auth.currentUserValue.seller.id).subscribe(
        seller => {
          console.log('watching, got', seller)
          this.seller = seller
        },
        error => { }
      )
    }
    if (this.auth.isMerchant) {
      this._merchantsService.currentMerchant.pipe(untilDestroyed(this)).subscribe(
        merchant => { this.merchant = merchant }
      )
    }
  }
}