<div class="container-fluid">
  <div class="card gutter-b">
    <div class="card-header">
      <div class="d-flex">
        <h1 class="mb-4">

          <sales-period-booking-header
            [store]="selectedStore"
            [product]="selectedProduct"
            [variation]="selectedVariation"
          ></sales-period-booking-header>

        </h1>
        <div class="ml-auto">
          <div *ngIf="selectedStore" class="btn-group">
            <label ngbButtonLabel class="btn-info p-1">
              <span class="badge badge-light">{{'common.store'|translate|titlecase}}</span>
              <span class="mx-1">{{selectedStore.name}}</span>
            </label>
          </div>
          <h2>
            <span *ngIf="paymentOnlineOnly"  class="badge badge-info p-2">{{'seller.booking.payment_online_only'|translate}}</span>
            <span *ngIf="paymentInStoreOnly" class="badge badge-info p-2">{{'seller.booking.payment_in_store_only'|translate}}</span>
            <span *ngIf="paymentEitherOr"    class="badge badge-info p-2">{{'seller.booking.payment_either_or'|translate}}</span>
          </h2>
        </div>
      </div>

      <service-product-selector
        [preselectStoreId]="preselectStoreId"
        [preselectProductId]="preselectProductId"
        [preselectVariationId]="preselectVariationId"
        (storeChange)="selectedStoreChanged($event)"
        (productChange)="selectedProductChanged($event)"
        (variationChange)="selectedVariationChanged($event)"
        [(store)]="selectedStore"
        [(product)]="selectedProduct"
        [(variation)]="selectedVariation"
        [enableSearch]="false"
        [showRacks]="false"
        [requireProductSelect]="true"
        [requireVariationSelect]="sellerReservationMode"
        >
      </service-product-selector>

      <!-- <div *ngIf="showPaymentMethodIcons" class="payment-providers" style="display: flex;">
        <div *ngFor="let method of paymentCheckoutService.paymentProviders | async">
          <img style="width: 4em;" src="{{method.svg}}" title="{{method.name}}" alt="{{method.name}}"/>
        </div>
      </div> -->

    </div>
    <div class="card-body" *ngIf="selectedProduct && calendarViewModel">
      <div style="overflow-x: scroll">
        <table class="table table-sm">
          <thead>
            <tr>
              <th class="b-th col-rack" scope="col">{{'common.rack'|translate|titlecase}}</th>
              <th *ngFor="let day of dayRange"
                class="b-th col-day" scope="col"
                [ngStyle]="{'background-color': (day.isWeekend()) ? '#eee' : '#fff'}"
                [ngClass]="{'b-closed': closedOnDay(selectedStore, day)}"
              >
                <div class="col-day-h">
                  {{day.asDate() | date: 'd.M.'}}
                </div>
              </th>
            </tr>
          </thead>
          <tbody style="overflow-y: scroll;">
            <tr *ngFor="let rack of filteredRacks(selectedProduct)">
              <th class="b-th" scope="row"><span class="badge">{{rack.name}}</span></th>

              <td class="b-td"
                *ngFor="let rackDay of calendarViewModel.getTimelineFor(rack.id).rackDays"
                [colSpan]="rackDay.duration"
                [ngClass]="{
                  'b-reserved': (sellerReservationMode && rackDay.busy) || (rackDay.busy && !rackDay.period),
                  'b-closed': rackDay.closed,
                  'b-weekend': rackDay.weekend && !rackDay.busy && !rackDay.hovering,
                  'table-success': selectedVariation && !rackDay.busy && rackDay.hovering
                }"
                (mouseover)="mouseover2(rackDay)"
                (mouseout)="mouseout2(rackDay)"
              >
                <div *ngIf="!rackDay.period || (auth.isSeller && auth.sellerId != rackDay.period.seller_id); else showSalesPeriod" style="width: 100%;" (click)="rackDayClicked(rackDay)">&nbsp;</div>
                <ng-template #showSalesPeriod>
                  <span class="d-flex badge sales-period-span p-1 m-0" [ngClass]="classForPeriodBadge(rackDay)" [ngStyle]="styleForPeriodBadge(rackDay)">
                    <span *ngIf="isSellerOwnPeriod(rackDay.period) || displayFilteredPeriodForMerchant(rackDay.period); else grayedOutPeriod">
                      <span (click)="editSalesPeriod(rackDay.period)" style="cursor: pointer;">
                        <span class="badge badge-light p-1 mr-1">{{rackDay.period.service_product_variation.code}}</span>
                        <span class="badge badge-light p-1 mr-1">{{rackDay.period.id}}</span>
                        {{rackDay.period.seller.name}}
                      </span>
                      <span class="ml-auto sales-period-actions"><a routerLink="/period/{{rackDay.period.id}}"><i-bs name="list" class="p-0 m-0 ml-1" [ngStyle]="styleForSalesPeriodAction(rackDay)"></i-bs></a></span>
                      <span class="ml-auto sales-period-actions"><a routerLink="/period/{{rackDay.period.id}}" target="_blank"><i-bs name="boxArrowUpRight" width="12" height="12" class="p-0 m-0 ml-1" [ngStyle]="styleForSalesPeriodAction(rackDay)"></i-bs></a></span>
                    </span>
                    <ng-template #grayedOutPeriod>
                      <span class="badge badge-light p-1 mr-1">{{rackDay.period.id}}</span>
                    </ng-template>
                  </span>
                </ng-template>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
