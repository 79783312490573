import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { catchError, map, shareReplay } from 'rxjs/operators'
import { domainMerge } from '@/util/domain-utils'

import * as api from '@/config/api-config.json'

import { AlertService } from './alert.service'
import { AuthenticationService } from './authentication.service'
import { CableService } from './cable.service'
import { SalesPeriodsService } from './sales-periods.service'

import { PaymentInitiationMessage } from '@/domain/payment-initiation-message'
import { Settlement } from '@/domain/settlement'
import { SettlementServiceCharge } from '@/domain/settlement-service-charge'

@Injectable({ providedIn: 'root' })
export class SettlementsService {

  private _settlementsSource: Settlement[] = null
  private _settlementSubject: BehaviorSubject<Settlement[]>
  private _settlementsObservable: Observable<Settlement[]>

  private _pimSource: PaymentInitiationMessage[] = null
  private _pimSubject: BehaviorSubject<PaymentInitiationMessage[]>

  constructor(
    private _salesPeriodService: SalesPeriodsService,
    private _cable: CableService,
    private _auth: AuthenticationService,
    private _alertService: AlertService,
    private _http: HttpClient
  ) {
    this._settlementsSource = []
    this._settlementSubject = new BehaviorSubject<Settlement[]>(this._settlementsSource)
    this._settlementsObservable = this._settlementSubject.asObservable()

    if (this._auth.isMerchant) {
      this._pimSubject = new BehaviorSubject<PaymentInitiationMessage[]>([])
    } else {
      this._pimSource = null
      this._pimSubject = null
    }
    this._cable.paymentInitiationMessages.subscribe(pim => { this._handlePim(pim) })
    this._cable.settlements.subscribe(settlement => { this._handleSettlement(settlement) })
  }

  private _handlePim(pim: PaymentInitiationMessage): void {
    this._nextXml(pim)
  }

  private _handleSettlement(settlement: Settlement): void {
    let source = this._settlementsSource || []
    if (source.find(s => s.id == settlement.id)) {
      source = source.map(s => s.id == settlement.id ? settlement : s)
    } else {
      source.push(settlement)
    }
    this._settlementsSource = source
    this._nextSettlements()
  }

  get settlements(): Observable<Settlement[]> {
    this._preloadSettlements()
    return this._settlementsObservable
  }

  get paymentInitiationMessages(): Observable<PaymentInitiationMessage[]> {
    this._loadPaymentInitiationMessages()
    return this._pimSubject.asObservable()
  }

  private _loadingPims: boolean = false
  private _loadPaymentInitiationMessages(): void {
    if (this._pimSource || this._loadingPims) {
      return
    }
    this._loadingPims = true
    this._http.get<PaymentInitiationMessage[]>(api.sepaXmls.all).subscribe(
      xmls => {
        this._pimSource = xmls
        this._pimSubject.next(this._pimSource)
        this._loadingPims = false
      })
  }

  private _initLoadedSettlements: boolean = false
  private _initLoadingSettlements: boolean = false
  private _preloadSettlements(salesPeriodId: number = null) {
    if (this._initLoadingSettlements || this._initLoadedSettlements) {
      return
    }
    this._initLoadingSettlements = true
    let path = api.settlements.all
    if (salesPeriodId) {
      path += `?sales_period_id=${salesPeriodId}`
    }
    this._http.get<Settlement[]>(path).subscribe(
      settlements => {
        this._settlementsSource = domainMerge(this._settlementsSource, settlements)
        this._nextSettlements()
        if (!salesPeriodId) {
          this._initLoadedSettlements = true
        }
      },
      (_err: any) => {},
      () => {
        this._initLoadingSettlements = false
      })
  }

  private _nextSettlements(): void {
    this._settlementsSource = this._settlementsSource.sort((a,b) => { return b.id - a.id })
    this._settlementSubject.next(this._settlementsSource)
  }

  private _nextXml(pim: PaymentInitiationMessage): void {
    this._pimSource = domainMerge(this._pimSource, [ pim ])
    this._pimSubject.next(this._pimSource)
  }

  createPaymentInitiationMessage(): Observable<PaymentInitiationMessage> {
    let requestBody = { 'payment_initiation_message': { } }
    return this._http
      .post<PaymentInitiationMessage>(api.sepaXmls.create, requestBody)
        .pipe(map((message: PaymentInitiationMessage) => {
          if (message) {
            this._refreshSettlements(message.id, message.settlements)
          }
          return message
        }))
  }

  public salesPeriodSettlements$(salesPeriodId: number): Observable<Settlement[]> {
    this._preloadSettlements(salesPeriodId)
    return this._settlementsObservable.pipe(
      map(settlements => settlements.filter(s => s.sales_period_id == salesPeriodId)),
      shareReplay(1)
    )
  }

  delete(settlement: Settlement): Observable<Settlement> {
    return this._http
    .delete<Settlement>(`${api.settlements.delete}/${settlement.id}`)
    .pipe(
      map((deletedSettlement: Settlement) => {
        this._settlementsSource = this._settlementsSource.filter(s => s.id != settlement.id)
        this._nextSettlements()
        return settlement
      }),
      catchError(this.handleError))
  }

  markPaid(settlement: Settlement): Observable<Settlement> {
    let requestBody = {
      'settlement': {
        'payment_status': 'payment_status_paid'
      }
    }
    return this._http
      .patch<Settlement>(`${api.settlements.update}/${settlement.id}`, requestBody)
      .pipe(
        map((settlement: Settlement) => {
          this._salesPeriodService.reload(settlement.sales_period_id)
          return settlement
        }),
        catchError(this.handleError))
  }

  createSettlement(salesPeriodId: number, forSeller: boolean, settlementMethod: string, charges: SettlementServiceCharge[]): Observable<Settlement> {
    let requestBody = {
      'settlement': {
        'sales_period_id': salesPeriodId
      }
    }
    if (forSeller && settlementMethod) {
      requestBody['settlement']['settlement_method'] = settlementMethod
    }
    if (!forSeller) {
      requestBody['settlement']['settled_for'] = 'settled_for_merchant'
    }
    if (charges != null && charges.length > 0) {
      requestBody['settlement']['settlement_service_charges'] = charges
    }

    return this._http
      .post<Settlement>(api.settlements.create, requestBody)
      .pipe(
        map((settlement: Settlement) => {
          this._settlementsSource = domainMerge(this._settlementsSource, [ settlement ])
          this._nextSettlements()
          this._salesPeriodService.reload(salesPeriodId)
          return settlement
        }),
        catchError(this.handleError))
  }

  getPastSettlementReport(year: number, month: number): Observable<Blob> {
    let endpoint = `${api.reports.settlements}?year=${year}&month=${month}`
    return this._http.get(endpoint, { responseType: 'blob', headers: { 'Accept': '*/*' }})
  }

  getSettlementReport(prev: boolean = false): Observable<Blob> {
    let endpoint = `${api.reports.settlements}`
    if (prev) {
      endpoint += '?lastlast=1'
    }
    return this._http.get(endpoint, { responseType: 'blob', headers: { 'Accept': '*/*' }})
  }

  private _refreshSettlements(messageId: number, updatedSettlements: Settlement[]): void {
    let didUpdate = false
    this._settlementsSource.forEach(settlement => {
      let updatedSettlement = updatedSettlements.find(s => { return s.id === settlement.id})
      if (updatedSettlement) {
        settlement.payment_initiation_message_id = messageId
        settlement.payment_status = updatedSettlement.payment_status
        settlement.payment_time = updatedSettlement.payment_time
        didUpdate = true
      }
    })
    if (didUpdate) {
      this._nextSettlements()
    }
  }

  private handleError(error: HttpErrorResponse) {
    this._alertService.error(error.error)
    return throwError(error)
  }
}
