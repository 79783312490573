import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Subscription } from 'rxjs'

import { SalesPeriodsService } from '@/services/sales-periods.service'

import { SalesPeriod } from '@/domain/sales-period'

@UntilDestroy()
@Component({
  selector: 'sales-period-view',
  templateUrl: 'sales-period-view.component.html'
})
export class SalesPeriodViewComponent implements OnInit, OnDestroy {

  period: SalesPeriod = null

  private _subscription: Subscription
  private _navigatedToId: number = null

  constructor(
    public _service: SalesPeriodsService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._route.params.pipe(untilDestroyed(this)).subscribe(
      params => {
        const navigatedToId = +params['id']
        if (!this.period || navigatedToId != this.period.id) {
          this._navigatedToId = navigatedToId
          this._subscription = this._service.watch(navigatedToId).subscribe(p => { this.period = p })
        }
      }
    )
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe()
      this._navigatedToId = null
    }
  }
}