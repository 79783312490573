import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { MerchantsService } from '@/services/merchants.service'

import { AuthenticationService } from '@/services/authentication.service'
import { User } from '@/domain/user'

const DEFAULT_MERCHANT_NAME = 'DEUX'

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  user: User = null
  merchantName: string = DEFAULT_MERCHANT_NAME

  env: string = 'prod'

  sellerSignupEnabled: boolean = false

  constructor(
    public auth: AuthenticationService,
    public merchantsService: MerchantsService,
    private _router: Router
  ) {
    this.auth.currentUser.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
      if (this.user && this.user.merchant) {
        this._setMerchantName(this.user.merchant.name)
      } else {
        this._setMerchantName()
      }
    })

    this.auth.maybePreloadMerchant()
    this.auth.preloadedMerchant.pipe(untilDestroyed(this)).subscribe(merchant => {
      if (merchant && !this.auth.isLoggedIn) {
        this._setMerchantName(merchant.name)
        this.sellerSignupEnabled = merchant.seller_registration_enabled
      }
    })

    const loc = window.location.href
    if (loc.indexOf('qa.deux.fi') > 0) {
      this.env = 'qa'
    } else if (loc.indexOf('varpu.local') > 0) {
      this.env = 'local'
    }
  }

  logout() {
    this.auth.logout()
    this._router.navigate(['/login'])
    this.auth.maybePreloadMerchant()
  }

  private _setMerchantName(name?: string) {
    this.merchantName = name ? name : DEFAULT_MERCHANT_NAME
  }
}
