<div class="container-fluid">
  <div class="row">
    <main role="main" class="col">
      <div class="container">
        <div class="row my-5">
          <div class="col">
            <h1>
              {{'common.settings'|translate}}
            </h1>
            <alert></alert>
          </div>
        </div>
        <div class="row" *ngIf="merchant||seller">
          <div class="col">
            <div class="mb-4">
              <merchant-profile *ngIf="auth.isMerchant" [merchant]="merchant"></merchant-profile>
              <seller-profile *ngIf="auth.isSeller" [seller]="seller"></seller-profile>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
