import { Component, EventEmitter, Injectable, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { addWeeks, endOfMonth, subWeeks, startOfMonth } from 'date-fns'

import { localDateAsDate } from '@/util/date-utils'
import { Subject } from 'rxjs'

import {
  CalendarEvent,
  CalendarDateFormatter,
  CalendarMonthViewBeforeRenderEvent,
  CalendarUtils,
  DAYS_OF_WEEK,
} from 'angular-calendar'

import { GetMonthViewArgs, MonthView, MonthViewDay } from 'calendar-utils'
import { PlainDate } from '@/util/date-utils'

import { AuthenticationService } from '@/services/authentication.service'
import { MerchantsService } from '@/services/merchants.service'
import { PaymentCheckoutService } from '@/services/payment-checkout.service'

import { Merchant } from '@/domain/merchant'
import { SalesPeriod } from '@/domain/sales-period'
import { ServiceProduct } from '@/domain/service-product'
import { ServiceProductVariation } from '@/domain/service-product-variation'

import { SelectedVariationAndDate } from '@/model/calendar'
import { CustomDateFormatter } from './custom-date-formatter.provider'

@Injectable()
export class MyCalendarUtils extends CalendarUtils {
  getMonthView(args: GetMonthViewArgs): MonthView {
    args.viewStart = subWeeks(startOfMonth(args.viewDate), 0);
    args.viewEnd = addWeeks(endOfMonth(args.viewDate), 10);
    return super.getMonthView(args);
  }
}

@UntilDestroy()
@Component({
  selector: 'sales-period-seller-reservation',
  templateUrl: 'sales-period-seller-reservation.component.html',
  styleUrls: [ 'sales-period-seller-reservation.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    {
      provide: CalendarUtils,
      useClass: MyCalendarUtils,
    },
  ]
})
export class SalesPeriodSellerReservationComponent implements OnInit, OnChanges {

  @Input()
  title: string = null

  /**
   * Called when a date is clicked; use for making reservations at the selected time.
   */
  @Output()
  selectedForCreate = new EventEmitter<SelectedVariationAndDate>()

  salesPeriods: SalesPeriod[]

  selectedProduct: ServiceProduct = null
  selectedVariation: ServiceProductVariation = null

  // Calendar configuration
  refresh = new Subject<void>()
  viewDate: Date = new Date()
  availableStartingDays: CalendarEvent[] = []
  locale: string = "fi"
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  private _merchant: Merchant

  constructor(
    public auth: AuthenticationService,
    public paymentCheckoutService: PaymentCheckoutService,
    private _merchantsService: MerchantsService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.auth.currentUser.subscribe(user => {
      if (!user || !user.seller || !user.seller.seller_reservations_enabled) {
        this._router.navigate(['/'])
        return
      }
    })

    this._merchantsService.currentMerchant.pipe(untilDestroyed(this)).subscribe(merchant => {
      if (!merchant) {
        return
      }
      if (!merchant.seller_reservations_enabled && this.auth.isSeller) {
        this._router.navigate(['/'])
        return
      }
      this._merchant = merchant
    })
  }

  ngOnChanges(): void {
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((monthViewDay) => {
    })
  }

  variationSelected(variation: ServiceProductVariation): void {
    if (!variation) {
      this.availableStartingDays = []
    } else {
      let dates = variation.available_start_dates
      if (dates.length == 0) {
        this.availableStartingDays = []
      } else {
        this.availableStartingDays = dates.map(availDate => {
          return <CalendarEvent> {
            id: ""+availDate.rack_id,
            title: availDate.rack_name + " #" + availDate.rack_id,
            start: localDateAsDate(availDate.dates[0])
          }
        })
      }
    }
    this.refresh.next()
  }

  eventClicked(event): void {

    console.log('Reserving', event, this.selectedProduct, this.selectedVariation, 'rack/from/to')

    let rackId = +event.events[0].id
    console.log('rackid', rackId)

    let selection = new SelectedVariationAndDate(
      this.selectedProduct,
      this.selectedVariation,
      null, // rack
      PlainDate.ofDate(event.date),
      null, // to
      rackId)
    console.log('Emitting selection', selection)

    this.selectedForCreate.emit(selection)
  }

  get paymentInStoreOnly(): boolean {
    return this.selectedVariation && (
      !this.paymentOnlineOnly && !this._merchant.seller_reservation_checkout_enabled ||
      !(this.selectedVariation.price > 0))
  }

  get paymentOnlineOnly(): boolean {
    return this.selectedVariation && (
      (this._merchant && this._merchant.seller_reservation_checkout_required) ||
      !(this.selectedVariation.price_in_store > 0))
  }

  get paymentEitherOr(): boolean {
    return this.selectedVariation && (
      !(this._merchant && this._merchant.seller_reservation_checkout_required) &&
      this._merchant.seller_reservation_checkout_enabled &&
      (this.selectedVariation.price > 0) && (this.selectedVariation.price_in_store > 0))
  }
}