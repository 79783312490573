import { LocalDate } from '@js-joda/core'

export class  AvailableStartDate {

  rack_id: number
  rack_name: string
  dates: LocalDate[]

  public static from(json: any): AvailableStartDate {
    const a = Object.assign(new AvailableStartDate(), json)
    if (json.dates) {
      a.dates = json.dates.map(w => { return LocalDate.parse(w) })
    }
    return a
  }
}
