import { Directive, EventEmitter, Input, Output } from "@angular/core"

export type SortColumn = string
export type SortDirection = 'asc' | 'desc' | ''
const rotate: {[key: string]: SortDirection} = { 'asc': 'desc', 'desc': '', '': 'asc' }

export interface SortEvent {
  column: SortColumn
  direction: SortDirection
}

export interface State {
  searchTerm: string
  sortColumn: string
  sortDirection: SortDirection
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '[class.text-info]': 'direction === "asc" || direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeader {

  @Input() sortable: SortColumn = ''
  @Input() direction: SortDirection = ''
  @Output() sort = new EventEmitter<SortEvent>()

  rotate() {
    this.direction = rotate[this.direction]
    this.sort.emit({column: this.sortable, direction: this.direction})
  }
}