import { Component, OnInit } from '@angular/core';

import { ProductFeaturesService } from '@/services/product-features.service';

import { ProductCondition } from '@/domain/product-condition';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html'
})
export class ProductConditionComponent implements OnInit {

  constructor(private featureService: ProductFeaturesService) { }

  conditions: ProductCondition[];

  ngOnInit(): void {
    this.getConditions();
  }

  getConditions(): void {
    this.featureService.getConditions()
      .subscribe(conditions => this.conditions = conditions)
  }
}
