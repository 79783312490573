import { LocalDate } from '@js-joda/core'

export class StoreClosedWeekday {
  static DAYS = ['mon','tue','wed','thu','fri','sat','sun']

  public id: number
  public from: string
  public to: string
  public day: string
  public store_closed: boolean

  public static from(json: any): StoreClosedWeekday {
    return Object.assign(new StoreClosedWeekday(), json)
  }

  /** @param date: 0=sun, etc */
  public isClosed(date: LocalDate): boolean {
    if (!this.store_closed) {
      return false
    }
    if (!this.validOn(date)) {
      // console.log('not valid on', date)
      return false
    }
    const dayOfWeek = StoreClosedWeekday.DAYS.indexOf(this.day)
    return date.dayOfWeek().value() == dayOfWeek + 1
  }

  private validOn(date: LocalDate): boolean {
    const from = this.from ? LocalDate.parse(this.from) : null
    const to = this.to ? LocalDate.parse(this.to) : null
    return (!from || (date.equals(from) || date.isAfter(from))) && (!to || (date.equals(to) || date.isBefore(to)))
  }
}
