import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { AuthenticationService } from '@/services/authentication.service'

import { User } from '@/domain/user'

@UntilDestroy()
@Component({
  templateUrl: 'home.component.html',
  styleUrls: [ 'home.component.scss' ]
})
export class HomeComponent {

  merchantLogoUrl = null
  sellerSignupEnabled: boolean = false

  constructor(
    public auth: AuthenticationService,
    private _router: Router
  ) {
    let user = this.auth.currentUserValue
    if (user) {
      let path = '/'
      switch (user.role) {
        case 'merchant':
          path = "/dashboard"
          break
        case 'seller':
          path = null // "/" // start seller at 'home' page
          break
      }
      if (path) {
        this._router.navigate([ path ]);
      }
    }

    this.auth.preloadedMerchant.pipe(untilDestroyed(this)).subscribe(merchant => {
      this._setMerchantLogo(merchant ? merchant.static_logo_asset : null)
      this.sellerSignupEnabled = merchant && merchant.seller_registration_enabled
    })
  }

  private _setMerchantLogo(asset: string): void {
    this.merchantLogoUrl = asset ? '/' + asset : null
  }
}