import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core'
import { saveAs } from 'file-saver'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'
import { ProductFeaturesService } from '@/services/product-features.service'
import { ProductsService } from '@/services/products.service'
import { productNumber } from '@/util/product-utils'
import { zeroPad } from '@/util/number-utils'

import { Product } from '@/domain/product'
import { SalesPeriod } from '@/domain/sales-period'

@Component({
  selector: 'product-table',
  templateUrl: 'product-table.component.html',
  styleUrls: [ 'product-table.component.scss' ],
})
export class ProductTableComponent implements OnChanges {

  @Input()
  period: SalesPeriod = null

  @Output()
  edit = new EventEmitter<Product>()

  products: Product[] = null

  constructor(
    public auth: AuthenticationService,
    public features: ProductFeaturesService,
    private _productsService: ProductsService,
    private _alertService: AlertService
  ) { }

  ngOnChanges() {
    this._setProducts()
  }

  private _setProducts(): void {
    if (this.auth.isSeller && !this.period.service_product.service_level.is_self_service) {
      this.products = this.period.products_sold
    } else {
      this.products = this.period.products
    }
    if (this.products && this.products.length > 0) {
      this.products.sort((a, b) => { return parseInt(""+b.number) - parseInt(""+a.number) })
    }
  }

  get isSelfServiceSeller(): boolean {
    return this.auth.isSeller && this.period.service_product.service_level.is_self_service
  }

  get presentEdit(): boolean {
    return this.auth.isMerchant || this.isSelfServiceSeller
  }

  downloadProductPriceTag(product: Product): void {
    this._productsService.getPriceTagForProduct(product.id)
      .subscribe(
        blob => {
          saveAs(blob, `hintalappu_tuote-${productNumber(this.period.seller, product)}.pdf`)
        },
        error => { this._alertService.error(error) }
      )
  }

  mayEdit(product: Product): boolean {
    return product.editable && (this.auth.isMerchant || (this.auth.isSeller && this.period.status != 'status_cancelled' && product.status == 'initial'))
  }

  doEdit(product: Product): void {
    this.edit.emit(product)
  }

  doDelete(product: Product): void {
    if (product.isNew || !product.id) {
      this._remove(product)
      return
    }

    // NB this check is after the isNew||!id check above
    if (!product.deletable) {
      // should not happen
      return 
    }

    product.deleting = true
    this._productsService.delete(product.id).subscribe(
      deletedProduct => {
        // TODO 20220105 check wrt. cable broadcasting
        // No need to do anything, because after a successful delete,
        // the products.service triggers an update to the whole sales
        // period, which propagates to here
      })
  }

  zeroPad6(number) {
    return zeroPad(number, 6)
  }

  private _remove(product: Product): void {
    this.period.products.splice(this.period.products.indexOf(product), 1)
    this._setProducts()
  }

}