import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import * as api from '@/config/api-config.json'

import { Rack } from '@/domain/rack'
import { RackAvailability } from '@/domain/rack-availability'

@Injectable({
  providedIn: 'root'
})
export class RacksService {

  private _racks: Rack[]
  private _racksSubject: BehaviorSubject<Rack[]>

  racks: Observable<Rack[]>

  constructor(
    private _http: HttpClient
  ) {
    this._racksSubject = new BehaviorSubject<Rack[]>([])
    this.racks = this._racksSubject.asObservable()
    this._getAll()
  }

  private _getAll(): void {
    this._http.get<Rack[]>(`${api.racks.all}`).subscribe(
      response => {
        this._racks = response.map(m => { return Rack.from(m) })
        this._racksSubject.next(this._racks)
      }
    )
  }

  getAvailability(rackId: number, year: number, month: number): Observable<RackAvailability> {
    const path = `${api.racks.get}/${rackId}/availability?year=${year}&month=${month}`
    return this._http
      .get<RackAvailability>(path)
      .pipe(map((response: RackAvailability) => {
        return RackAvailability.from(response)
      }))
  }
}
