import { LocalDate } from '@js-joda/core'

export class StoreClosedDay {
  public id: number
  public date: LocalDate
  public description: string

  public static from(json: any): StoreClosedDay {
    const d = Object.assign(new StoreClosedDay(), json)
    d.date = LocalDate.parse(json.date)
    return d
  }
}
