import { SalesPeriod } from "@/domain/sales-period"

export function classForPaymentStatus(period: SalesPeriod) {
  const classes = {
    payment_status_cancelled: 'badge-light',
    payment_status_error:     'badge-light',
    payment_status_none:      'badge-warning',
    payment_status_paid:      'badge-success',
    payment_status_pending:   'badge-light',
    payment_status_timeout:   'badge-light'
  }
  return classes[period.payment_status]
}
