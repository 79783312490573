import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { map, shareReplay, catchError } from 'rxjs/operators'

import * as api from '@/config/api-config.json'

import { CableService } from './cable.service'

import { Seller } from '@/domain/seller'
import { domainMerge } from '@/util/domain-utils'

@Injectable({ providedIn: 'root' })
export class SellersService {

  private _source: Seller[]
  private _sellers$: BehaviorSubject<Seller[]>

  public sellers: Observable<Seller[]>

  constructor(
    private _http: HttpClient,
    private _cable: CableService
  ) {
    this._sellers$ = new BehaviorSubject<Seller[]>([])
    this.sellers = this._sellers$.asObservable()
    this._cable.sellers.subscribe(seller => { this._handleReceivedSeller(seller) })
    this._loadAll()
  }

  get(sellerId: number): Observable<Seller> {
    return this._http.get<Seller>(`${api.sellers.get}/${sellerId}`)
  }

  watch(sellerId: number): Observable<Seller> {
    return this._sellers$.pipe(
      map((sellers) => { return sellers.find(s => s.id == sellerId) }),
      shareReplay(1)
    )
  }

  private _handleReceivedSeller(seller: Seller): void {
    this._next(domainMerge(this._source, [ seller ]))
  }

  private _loadAll(): void {
    this._http
      .get<Seller[]>(api.sellers.all)
      .subscribe(sellers => {
        let sellerObjects = sellers.map(json => Seller.from(json))
        this._next(sellerObjects)
     })
  }

  private _next(sellers: Seller[]): void {
    this._source = sellers
    this._sellers$.next(this._source)
  }

  saveContactInfo(sellerId: number, values): Observable<Seller> {
    let requestBody = {
      "seller": {
        "name": values.name,
        "phone": values.phone,
        "accept_marketing": values.accept_marketing,
        "seller_reservations_enabled": values.seller_reservations_enabled
      }
    }
    return this._http
      .patch<Seller>(`${api.sellers.edit}/${sellerId}`, requestBody)
      .pipe<Seller>(catchError(this.handleError))
  }

  saveIban(sellerId: number, values): Observable<Seller> {
    let requestBody = {
      "seller": {
        "iban": values.iban
      }
    }
    return this._http
      .patch<Seller>(`${api.sellers.edit}/${sellerId}`, requestBody)
      .pipe<Seller>(catchError(this.handleError))
  }

  saveOrCreateWithUser(seller: Seller, sellerId: number, passwd: string): Observable<Seller> {
    let requestBody = {
      "seller": {
        "name": seller.name,
        "phone": seller.phone,
        "accept_marketing": seller.accept_marketing,
        "seller_reservations_enabled": seller.seller_reservations_enabled,
        "iban": seller.iban,
        "user_email": seller.user_email
      }
    }
    if (sellerId) {
      return this._http
        .patch<Seller>(`${api.sellers.edit}/${sellerId}`, requestBody)
        .pipe<Seller>(catchError(this.handleError))
    } else {
      requestBody.seller["user_password"] = passwd
      return this._http
        .post<Seller>(api.sellers.new, requestBody)
        .pipe<Seller>(catchError(this.handleError))
    }
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error)
  }
}
