import { Component, OnInit } from '@angular/core';

import { ServiceProductsStorage } from '@/storage/service-products.storage'

@Component({
  selector: 'service-levels',
  templateUrl: './service-levels.component.html'
})
export class ServiceLevelsComponent implements OnInit {

  constructor(
    public service: ServiceProductsStorage
  ) { }

  ngOnInit(): void {
  }

}
