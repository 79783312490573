<div class="sales-period-table">
  <p *ngIf="!salesPeriods || salesPeriods.length == 0; else salesPeriodTable">
    <!-- {{'sales_period_table.none'|translate}} -->
  </p>
  <ng-template #salesPeriodTable>
    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <!-- <th scope="col">{{'common.sales_period_short'|translate|titlecase}}</th> -->
          <th scope="col" *ngIf="seller">{{'common.status'|translate|titlecase}}</th>
          <th scope="col" *ngIf="multistore && (auth.isSeller || !store)">{{'common.store'|translate|titlecase}}</th>
          <th scope="col" style="width: 4em;">{{'common.number'|translate|titlecase}}</th>
          <th scope="col" *ngIf="auth.isMerchant && !seller">{{'common.seller'|translate|titlecase}}</th>
          <th scope="col" style="width: 4em;">{{'common.service'|translate|titlecase}}</th>
          <th scope="col" style="width: 3em;">{{'common.rack'|translate|titlecase}}</th>
          <th scope="col">{{'common.from_to'|translate|titlecase}}</th>
          <th scope="col" *ngIf="normal">{{'common.price'|translate|titlecase}}</th>
          <th scope="col" *ngIf="normal">{{'common.sale__discount'|translate|titlecase}}</th>
          <th scope="col" *ngIf="normal">{{'common.commission'|translate|titlecase}}</th>
          <th scope="col" *ngIf="show('productCount')">{{'common.products'|translate|titlecase}}</th>
          <th scope="col" *ngIf="show('unsettledProductCount') && auth.isMerchant">{{'common.unsettled_count'|translate}}</th>
          <th scope="col" *ngIf="normal">{{'common.sales_period_note'|translate|titlecase}}</th>
          <th scope="col" *ngIf="normal && auth.isMerchant">{{'common.internal_note'|translate}}</th>
          <th scope="col" *ngIf="auth.isMerchant && hasCustomPeriodStatuses()">{{'common.status'|translate|titlecase}}</th>
          <th scope="col" *ngIf="normal && auth.isMerchant">{{'common.payment_status_heading'|translate}}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sales_period of salesPeriods$ | async" [ngClass]="{'table-info': sales_period == selectedSalesPeriod}">
          <td scope="row" *ngIf="seller"><sales-period-status-badge [period]="sales_period" [includeSettlementStatus]="true"></sales-period-status-badge></td>
          <td scope="row" *ngIf="multistore && (auth.isSeller || !store)">{{sales_period.store.name}}</td>
          <td scope="row"><span class="badge badge-light"><ngb-highlight [result]="sales_period.id.toString()" [term]="search"></ngb-highlight></span></td>
          <td *ngIf="auth.isMerchant && !seller" style="white-space: nowrap;">
            <ngb-highlight [result]="sales_period.seller.name" [term]="search"></ngb-highlight>&nbsp;
            <span class="badge badge-light p-1">{{sales_period.seller.id}}</span>
            <a class="btn-outline-primary px-1 py-0 ml-1" routerLink="/seller/{{sales_period.seller.id}}" target="_blank"><i-bs name="boxArrowUpRight" width="12" height="12"></i-bs></a>
          </td>
          <td><span class="badge badge-light"><ngb-highlight [result]="sales_period.service_product_variation.code" [term]="search"></ngb-highlight></span></td>
          <td><span class="badge badge-light"><ngb-highlight [result]="sales_period.rack_time_slot.reck.name" [term]="search"></ngb-highlight></span></td>
          <td style="white-space: nowrap;">{{sales_period.rack_time_slot.from | date:'d.M.'}}&ndash;{{sales_period.rack_time_slot.to | date:'d.M.'}} ({{duration(sales_period.rack_time_slot)}}{{'common.days_abbrev'|translate}})</td>
          <td class="price" *ngIf="normal"><ngb-highlight [result]="currency(sales_period.price)" [term]="search"></ngb-highlight></td>
          <td class="commission" *ngIf="normal"><ngb-highlight [result]="percent(sales_period.discount)" [term]="search"></ngb-highlight></td>
          <td class="commission" *ngIf="normal"><ngb-highlight [result]="percent(sales_period.commission)" [term]="search"></ngb-highlight></td>
          <td *ngIf="show('productCount')" class="deux-number">{{sales_period.product_counts.total}}</td>
          <td *ngIf="show('unsettledProductCount') && auth.isMerchant">
            <span *ngIf="!sales_period.unsettled_products_count || sales_period.unsettled_products_count === 0">{{sales_period.unsettled_products_count}}</span>
            <span *ngIf="sales_period.unsettled_products_count && sales_period.unsettled_products_count > 0" class="badge badge-warning p-2 m-0">{{sales_period.unsettled_products_count}}</span>
          </td>
          <td *ngIf="normal" style="line-height: 1;"><ngb-highlight [result]="sales_period.seller_note" [term]="search"></ngb-highlight></td>
          <td *ngIf="normal && auth.isMerchant" style="line-height: 1;"><ngb-highlight [result]="sales_period.internal_note" [term]="search"></ngb-highlight></td>
          <td *ngIf="normal && auth.isMerchant && hasCustomPeriodStatuses()">
            <span *ngIf="sales_period.custom_period_status" class="badge p-2" [ngStyle]="{ 'background-color': sales_period.custom_period_status.color }">{{sales_period.custom_period_status.name}}</span>
          </td>
          <td *ngIf="normal && auth.isMerchant">
            <span class="badge p-2" [ngClass]="classForPaymentStatus(sales_period)">{{'payment_status.'+sales_period.payment_status|translate|uppercase}}</span>
          </td>
          <td scope="row" class="action-buttons" style="display: flex;">
            <div *ngIf="auth.isMerchant && approvalActions">
              <span role="button" class="btn btn-sm btn-primary p-1 m-1" (click)="approve(sales_period)">{{'common.approve'|translate|uppercase}}</span>
              <span role="button" class="btn btn-sm btn-warning p-1 m-1" (click)="reject(sales_period)">{{'common.reject'|translate|uppercase}}</span>
            </div>
            <div *ngIf="linkToPeriod; else selectionEvent" style="display: flex;">
              <span class="btn btn-sm btn-primary px-1 py-1 ml-1 mr-0"><a routerLink="/period/{{sales_period.id}}" style="color: white;"><i-bs name="list"></i-bs></a></span>
              <span class="btn btn-sm btn-primary px-1 py-1 ml-0 mr-1"><a routerLink="/period/{{sales_period.id}}" style="color: white;" target="_blank"><i-bs name="boxArrowUpRight"></i-bs></a></span>
            </div>
            <ng-template #selectionEvent>
              <div role="button" *ngIf="sales_period != selectedSalesPeriod" class="btn btn-sm btn-primary px-1 py-1 mx-1" (click)="selectPeriod(sales_period)">
                <span *ngIf="normal; else compactOpen">{{'common.open'|translate|uppercase}}</span>
                <ng-template #compactOpen><i-bs name="list"></i-bs></ng-template>
              </div>
              <div *ngIf="sales_period == selectedSalesPeriod" class="btn btn-sm btn-secondary px-1 py-1 mx-1" (click)="deselectPeriod()">
                <span *ngIf="normal; else compactClose">{{'common.close'|translate|uppercase}}</span>
                <ng-template #compactClose><i-bs name="x"></i-bs></ng-template>
              </div>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</div>