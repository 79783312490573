import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-active-sellers',
  templateUrl: './active-sellers.component.html'
})
export class ActiveSellersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
