import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { first } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'

@UntilDestroy()
@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {

  loginForm: FormGroup
  loading = false
  submitted = false
  returnUrl: string
  error = ''

  merchantLogoUrl = null

  constructor(
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthenticationService,
    private _alertService: AlertService
  ) {
    // redirect to home if already logged in
    if (this._auth.currentUserValue) {
      this._router.navigate(['/'])
    }

    this._auth.preloadedMerchant.pipe(untilDestroyed(this)).subscribe(merchant => {
      this._setMerchantLogo(merchant ? merchant.static_logo_asset : null)
    })
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/'
  }

  private _setMerchantLogo(asset: string): void {
    this.merchantLogoUrl = asset ? '/' + asset : null
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls }

  onSubmit() {
    this.submitted = true

    // reset alerts on submit
    this._alertService.clear()

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return
    }

    this.loading = true;
    this._auth.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this._router.navigate([this.returnUrl])
        },
        error => {
          this._alertService.error(error)
          this.loading = false
        });
  }
}