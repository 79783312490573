import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'

import * as api from '@/config/api-config.json'

import { AuthenticationService } from '@/services/authentication.service'

import { Merchant } from '@/domain/merchant'
import { Store } from '@/domain/store'

@Injectable({ providedIn: 'root' })
export class MerchantsService {

  private _merchants: Merchant[]
  private _merchantsSubject: BehaviorSubject<Merchant[]>

  public merchants: Observable<Merchant[]>
  public signupMerchants: Observable<Merchant[]>

  public currentMerchant: Observable<Merchant>
  public stores$: Observable<Store[]>

  private __user = null

  constructor(
    private _auth: AuthenticationService,
    private _http: HttpClient
  ) {
    this._merchantsSubject = new BehaviorSubject<Merchant[]>([])
    this.merchants = this._merchantsSubject.asObservable()

    this.signupMerchants = this.merchants.pipe(
      // skip(1),
      map((merchants: Merchant[]) => {
        return merchants.filter(m => { return m.seller_registration_enabled })
      })
    )

    this.currentMerchant = this.merchants.pipe(
      map((merchants: Merchant[]) => {
        return merchants.find(m => { return m.id == this._auth.merchantId })
      }),
      shareReplay(1)
    )
    this.stores$ = this.currentMerchant.pipe(
      map((merchant: Merchant) => {
        return merchant ? merchant.stores : []
      }),
      shareReplay(1)
    )

    this._auth.currentUser.subscribe(user => {
      // haq
      if (user && user != this.__user) {
        // reload merchants on login
        this._getAll()
      }
      this.__user = user
    })

    this._getAll()
  }

  private _getAll(): void {
    this._http.get<Merchant[]>(`${api.merchants.all}`).subscribe(
      response => {
        this._merchants = response.map(m => { return Merchant.from(m) })
        this._merchantsSubject.next(this._merchants)
        this._auth.updateMerchants(this._merchants)
      }
    )
  }
}
