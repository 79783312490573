import { Injectable } from '@angular/core'
import { ActionCableService, Channel, Cable } from 'angular2-actioncable'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { AuthenticationService } from '@/services/authentication.service'

import { PaymentInitiationMessage } from '@/domain/payment-initiation-message'
import { Product } from '@/domain/product'
import { ProductBrand } from '@/domain/product-brand'
import { ProductType } from '@/domain/product-type'
import { ProductSize } from '@/domain/product-size'
import { Sale } from '@/domain/sale'
import { SaleRow } from '@/domain/sale-row'
import { SalesPeriod } from '@/domain/sales-period'
import { Seller } from '@/domain/seller'
import { Settlement } from '@/domain/settlement'

const PAYMENT_INITIATION_MESSAGE_CHANNEL = 'PaymentInitiationMessageChannel'
const PRODUCT_CHANNEL = 'ProductChannel'
const PRODUCT_BRAND_CHANNEL = 'ProductBrandChannel'
const PRODUCT_SIZE_CHANNEL = 'ProductSizeChannel'
const PRODUCT_TYPE_CHANNEL = 'ProductTypeChannel'
const SALE_CHANNEL = 'SaleChannel'
const SALE_ROW_CHANNEL = 'SaleRowChannel'
const SALES_PERIOD_CHANNEL = 'SalesPeriodChannel'
const SELLER_CHANNEL = 'SellerChannel'
const SETTLEMENT_CHANNEL = 'SettlementChannel'

@Injectable({ providedIn: 'root' })
export class CableService {

  private _cable: Cable = null

  private _paymentInitiationMessageChannel: Channel = null
  private _productChannel: Channel = null
  private _productBrandChannel: Channel = null
  private _productSizeChannel: Channel = null
  private _productTypeChannel: Channel = null
  private _salesPeriodChannel: Channel = null
  private _saleChannel: Channel = null
  private _saleRowChannel: Channel = null
  private _sellerChannel: Channel = null
  private _settlementChannel: Channel = null

  constructor(
    public _auth: AuthenticationService,
    private _cableService: ActionCableService
  ) {}

  private _debug(channel, item): void {
    // console.log(channel + ': received cable', item)
  }

  get salesPeriods(): Observable<SalesPeriod> {
    return this._salesPeriods.received().pipe(map(json => {
      const p = SalesPeriod.from(json)
      return p
    }))
  }

  get paymentInitiationMessages(): Observable<PaymentInitiationMessage> {
    return this._paymentInitiationMessages.received().pipe(tap(x=>{this._debug('paymentInitiationMessages',x)}),map(json => PaymentInitiationMessage.from(json)))
  }

  get products(): Observable<Product> {
    return this._products.received().pipe(tap(x=>{this._debug('products',x)}),map(string => Product.from(JSON.parse(string))))
  }

  get productBrands(): Observable<ProductBrand> {
    return this._productBrands.received().pipe(tap(x=>{this._debug('productBrands',x)}),map(obj => ProductBrand.from(obj)))
  }

  get productTypes(): Observable<ProductType> {
    return this._productTypes.received().pipe(tap(x=>{this._debug('productTypes',x)}),map(obj => ProductType.from(obj)))
  }

  get productSizes(): Observable<ProductSize> {
    return this._productSizes.received().pipe(tap(x=>{this._debug('productSizes',x)}),map(obj => ProductSize.from(obj)))
  }

  get sales(): Observable<Sale> {
    return this._sales.received().pipe(tap(x=>{this._debug('sales',x)}),map(string => Sale.from(JSON.parse(string))))
  }

  get saleRows(): Observable<SaleRow> {
    return this._saleRows.received().pipe(tap(x=>{this._debug('saleRows',x)}),map(string => SaleRow.from(JSON.parse(string))))
  }

  get sellers(): Observable<Seller> {
    return this._sellers.received().pipe(tap(x=>{this._debug('sellers',x)}),map(string => Seller.from(JSON.parse(string))))
  }

  get settlements(): Observable<Settlement> {
    return this._settlements.received().pipe(tap(x=>{this._debug('settlements',x)}),map(string => {
      const s = Settlement.from(JSON.parse(string))
      return s
    } ))
  }

  private get _paymentInitiationMessages(): Channel {
    if (!this._paymentInitiationMessageChannel) {
      this._paymentInitiationMessageChannel = this._channel(PAYMENT_INITIATION_MESSAGE_CHANNEL)
    }
    return this._paymentInitiationMessageChannel
  }

  private get _products(): Channel {
    if (!this._productChannel) {
      this._productChannel = this._channel(PRODUCT_CHANNEL)
    }
    return this._productChannel
  }

  private get _productBrands(): Channel {
    if (!this._productBrandChannel) {
      this._productBrandChannel = this._channel(PRODUCT_BRAND_CHANNEL)
    }
    return this._productBrandChannel
  }

  private get _productTypes(): Channel {
    if (!this._productTypeChannel) {
      this._productTypeChannel = this._channel(PRODUCT_TYPE_CHANNEL)
    }
    return this._productTypeChannel
  }

  private get _productSizes(): Channel {
    if (!this._productSizeChannel) {
      this._productSizeChannel = this._channel(PRODUCT_SIZE_CHANNEL)
    }
    return this._productSizeChannel
  }

  private get _salesPeriods(): Channel {
    if (!this._salesPeriodChannel) {
      this._salesPeriodChannel = this._channel(SALES_PERIOD_CHANNEL)
    }
    return this._salesPeriodChannel
  }

  private get _sales(): Channel {
    if (!this._saleChannel) {
      this._saleChannel = this._channel(SALE_CHANNEL)
    }
    return this._saleChannel
  }

  private get _saleRows(): Channel {
    if (!this._saleRowChannel) {
      this._saleRowChannel = this._channel(SALE_ROW_CHANNEL)
    }
    return this._saleRowChannel
  }

  private get _sellers(): Channel {
    if (!this._sellerChannel) {
      this._sellerChannel = this._channel(SELLER_CHANNEL)
    }
    return this._sellerChannel
  }

  private get _settlements(): Channel {
    if (!this._settlementChannel) {
      this._settlementChannel = this._channel(SETTLEMENT_CHANNEL)
    }
    return this._settlementChannel
  }

  private _channel(name: string): Channel {
    const channel = this.cable.channel(name)
    // console.log(name + ': opened channel')
    return channel
  }

  private get cable(): Cable {
    if (!this._cable) {
      const cableUrl = 'wss://' + location.hostname + '/cable'
      const user_token = this._auth.currentUserValue.token
      const params = { token: user_token }
      if (this._auth.isSeller) {
        params['seller_id'] = this._auth.sellerId
      }
      const cable = this._cableService.cable(cableUrl, params)
      this._cable = cable
    }
    return this._cable
  }
}