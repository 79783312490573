<div class="card">
  <div class="card-header">
    <div class="d-flex">
      <h2>
        <span *ngIf="isToday">{{'common.today'|translate|titlecase}}</span>
        <span *ngIf="isTomorrow">{{'common.tomorrow'|translate|titlecase}}</span>
        <span *ngIf="otherDay">{{displayDate}}</span>
      </h2>
      <div class="ml-auto">
        <form class="form-inline">
          <div class="form-group">
            <div class="input-group">
              <input ngbDatepicker class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="selectedDate" (dateSelect)="dateSelectionChanged()" #d="ngbDatepicker" [footerTemplate]="footer">
              <div class="input-group-append"><button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i-bs name="calendar3"></i-bs></button></div>
              <ng-template #footer>
                <div class="d-flex">
                  <button class="btn btn-outline-primary btn-sm m-2 p-1" (click)="gotoToday(d)">{{'common.today'|translate|titlecase}}</button>
                  <button class="btn btn-outline-primary btn-sm m-2 p-1 ml-auto" (click)="gotoTomorrow(d)">{{'common.tomorrow'|translate|titlecase}}</button>
                </div>
              </ng-template>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="card-body">
    <h3>{{'common.starting'|translate|titlecase}} <span *ngIf="filteredStartingPeriods">&bull; {{filteredStartingPeriods.length}}</span></h3>

    <sales-period-table
      [title]="'starting'"
      [salesPeriods]="filteredStartingPeriods"
      [mode]="'compact'"
      (select)="selectPeriod($event)">
    </sales-period-table>

    <h3>{{'common.ending'|translate|titlecase}} <span *ngIf="filteredEndingPeriods">&bull; {{filteredEndingPeriods.length}}</span></h3>

    <sales-period-table
      [title]="'starting'"
      [salesPeriods]="filteredEndingPeriods"
      [mode]="'compact'"
      (select)="selectPeriod($event)">
    </sales-period-table>

  </div>
</div>
