import { Component, OnInit } from '@angular/core';

import { ProductFeaturesService } from '@/services/product-features.service'; 

import { ProductCategory } from '@/domain/product-category';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html'
})
export class ProductCategoryComponent implements OnInit {

  constructor(private featureService: ProductFeaturesService) { }

  categories: ProductCategory[];

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories(): void {
    this.featureService.getCategories()
      .subscribe(categories => this.categories = categories)
  }

}
