import { CustomPeriodStatus } from '@/domain/custom-period-status'
import { Payment } from '@/domain/payment/checkout/payment'
import { Product } from '@/domain/product'
import { ProductCounts } from '@/domain/product-counts'
import { Rack } from '@/domain/rack'
import { RackTimeSlot } from '@/domain/rack-time-slot'
import { Seller } from '@/domain/seller'
import { ServiceProduct } from '@/domain/service-product'
import { ServiceProductVariation } from '@/domain/service-product-variation'
import { Store } from '@/domain/store'

export class SalesPeriod {

  public id: number
  public created_at: string
  public updated_at: string

  public status: string
  public pending_until?: Date
  public checkout_payment?: Payment
  public receipt_doc: string

  public custom_period_status_id?: number
  public custom_period_status?: CustomPeriodStatus

  public price: number
  public commission: number
  public duration: number
  public capacity: number
  public discount: number

  public seller_id: number
  public seller: Seller
  public seller_note?: string
  public internal_note?: string
  // public is_donate?: boolean // TODO use null-able boolean type

  public value_total?: number
  public sales_total?: number

  public service_product: ServiceProduct

  public service_product_variation_id: number
  public service_product_variation: ServiceProductVariation

  public rack?: Rack // used while creating a period

  public rack_time_slot_id: number
  public rack_time_slot: RackTimeSlot

  public store: Store

  public products?: Product[]
  public products_sold?: Product[]

  public product_counts: ProductCounts
  public unsettled_products_count: number

  public settlement_status?: string
  public payment_status?: string
  public payment_method?: string
  public payment_time?: string

  // TODO remove
  public currentVatRate: number
  public to: Date
  public from: Date
  public label: string

  constructor( ) { }

  // transient
  public isDeleting?: boolean = false
  public recentlyCreated?: boolean = false

  public static from(json: any): SalesPeriod {
    return Object.assign(new SalesPeriod(), json)
  }

  public static clone(source: SalesPeriod): SalesPeriod {
    return SalesPeriod.from(JSON.parse(JSON.stringify(source)))
  }
}
