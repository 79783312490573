<div class="sales-period-calendar vh-100 d-flex flex-column">
  <!-- dodge the nav bar (actual height 42px) -->
  <div class="flex-column">
    <div style="height:60px">HEADER</div>
  </div>

  <div class="flex-column flex-grow-1 overflow-auto">

    <sales-period-calendar
      [sellerReservationMode]="false"
      (selectedForCreate)="selectedForCreate($event)"
      (selectedForEdit)="selectedForEdit($event)"
      >
    </sales-period-calendar>

  </div>
</div>

<sales-period-edit-modal
  [open]="openSalesPeriodModal"
  [(period)]="salesPeriodToEdit"
  [selection]="selection"
  (closed)="salesPeriodModalClosed($event)"
  [link]="true"
  >
</sales-period-edit-modal>
