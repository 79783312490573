<ng-template #salesPeriodModalTemplate let-modal>

  <div class="modal-header d-flex">
    <h4 class="modal-title" id="modal-basic-title">
      <span *ngIf="editingPeriod">
        {{'merchant.salesperiod.modal_title'|translate}}
        <span class="badge badge-light ml-2">{{editingPeriod.id}}</span>
      </span>
      <span *ngIf="!editingPeriod">
        {{'merchant.salesperiod.create_new'|translate}}
      </span>
    </h4>
    <div class="ml-auto">
      <a role="button" *ngIf="linkToPeriod && editingPeriod && auth.isMerchant" class="btn btn-primary" [href]="periodLinkHref(editingPeriod)">{{'common.open'|translate|uppercase}}<i-bs name="list"></i-bs></a>
      <a role="button" *ngIf="linkToPeriod && editingPeriod && auth.isMerchant" class="btn btn-primary ml-1" [href]="periodLinkHref(editingPeriod)" target="_blank"><i-bs name="boxArrowUpRight"></i-bs></a>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <form [formGroup]='salesPeriodForm' (ngSubmit)="onSubmitSalesPeriod()">
    <div class="modal-body">
      <alert></alert>

      <div class="row">
        <!-- modal left column -->
        <div class="col-md-6">

          <!-- store -->
          <div class="form-group">
            <label for="store">{{'common.store'|translate|titlecase}}</label>
            <ng-select formControlName="store_id" name="store">
              <ng-option *ngFor="let store of stores" [value]="store.id">{{store.name}}</ng-option>
            </ng-select>
          </div>

          <!-- service product -->
          <div class="form-group">
            <label for="service_product">{{'common.service_product'|translate|titlecase}}</label>
            <div *ngIf="serviceProductsForSelectedStore == null || serviceProductsForSelectedStore.length == 0; else showProducts">
              <input disabled type="text" class="form-control" value="{{'merchant.salesperiod.select_store'|translate}}"/>
            </div>
            <ng-template #showProducts>
              <ng-select *ngIf="serviceProductsForSelectedStore.length > 0" formControlName="service_product_id" name="service_product">
                <ng-option *ngFor="let sp of serviceProductsForSelectedStore" [value]="sp.id"><span class="badge badge-light">{{sp.code}}</span> {{sp.name}}</ng-option>
              </ng-select>
            </ng-template>
          </div>

          <!-- variation and rack -->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="rack">{{'common.service_product_variation'|translate}}</label>
                <input *ngIf="!salesPeriodForm.controls['service_product_id'].value; else showVariationSelect" disabled type="text" class="form-control" value="{{'merchant.salesperiod.choose_a_product_level'|translate}}" />
                <ng-template #showVariationSelect>
                  <div *ngIf="getServiceProductVariations(salesPeriodForm.controls['service_product_id'].value); else noVariationsInServiceProduct">
                    <ng-select *ngIf="salesPeriodForm.controls['service_product_id'].value" formControlName="service_product_variation_id" id="service_product_variation" name="service_product_variation" placeholder="{{'common.choose_service_product_variation'|translate}}">
                      <ng-option *ngFor="let variation of getServiceProductVariations(salesPeriodForm.controls['service_product_id'].value)" [value]="variation.id"><span class="badge badge-sm badge-light">{{variation.code}}</span></ng-option>
                    </ng-select>
                  </div>
                  <ng-template #noVariationsInServiceProduct>
                    <input disabled type="text" class="form-control" value="{{'merchant.salesperiod.no_variations_in_sp'|translate}}"/>
                  </ng-template>
                </ng-template>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="rack">{{'common.place_no'|translate|titlecase}} / {{'common.rack'|translate|titlecase}}</label>
                <input *ngIf="!salesPeriodForm.controls['service_product_id'].value; else showRackSelect" disabled type="text" class="form-control" value="{{'merchant.salesperiod.choose_a_service_product'|translate}}" />
                <ng-template #showRackSelect>
                  <div *ngIf="getProductRecksForStore(salesPeriodForm.controls['store_id'].value,salesPeriodForm.controls['service_product_id'].value); else noRacksForServiceProductAndStore">
                    <ng-select *ngIf="salesPeriodForm.controls['service_product_id'].value" formControlName="rack_id" id="rack" name="rack" placeholder="{{'common.choose_rack'|translate}}">
                      <ng-option *ngFor="let rack of getProductRecksForStore(salesPeriodForm.controls['store_id'].value,salesPeriodForm.controls['service_product_id'].value)" [value]="rack.id"><span class="badge badge-sm badge-light">{{rack.name}}</span> {{rack.store_name}}</ng-option>
                    </ng-select>
                  </div>
                  <ng-template #noRacksForServiceProductAndStore>
                    <input disabled type="text" class="form-control" value="{{'merchant.salesperiod.no_racks_in_sp'|translate}}"/>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>

          <!-- date range picker-->
          <div class="datepicker-wrapper">
            <div class="form-group">
              <label>{{'common.from'|translate|titlecase}}
                <!-- <span *ngIf="fromDate">{{ngbToDate(fromDate)|date:'E'}} {{fromDate.day}}.{{fromDate.month}}.</span> -->
              </label>
              <div class="input-group">
                <input #dpFromDate formControlName="from" name="dpFromDate"
                  class="form-control" placeholder="yyyy-mm-dd"
                  (input)="selectionFromDate = validateInput(selectionFromDate, dpFromDate.value)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar p-2" (click)="toggle(datepicker)" type="button"><i-bs name="calendarDate"></i-bs></button>
                </div>
              </div>
            </div>
            <div class="form-group ml-2">
              <label for="dpToDate">{{'common.to'|translate|titlecase}}
                <!-- <span *ngIf="toDate">{{ngbToDate(toDate)|date:'E'}} {{toDate.day}}.{{toDate.month}}.</span> -->
              </label>
              <div class="input-group">
                <input #dpToDate formControlName="to" name="dpToDate"
                  class="form-control" placeholder="yyyy-mm-dd"
                  (input)="selectionToDate = validateInput(selectionToDate, dpToDate.value)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar p-2" (click)="toggle(datepicker)" type="button"><i-bs name="calendarDate"></i-bs></button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group hidden">
            <div class="input-group">
              <input name="datepicker" class="form-control"
                    ngbDatepicker #datepicker="ngbDatepicker"
                    outsideDays="hidden"
                    [autoClose]="'outside'"
                    [displayMonths]="2"
                    [dayTemplate]="dayTemplate"
                    [footerTemplate]="datePickerFooter"
                    (closed)="onDatePickerClosed($event)"
                    (dateSelect)="onDateSelection($event)"
                    (navigate)="onDatePickerNavigate($event)"
                    tabindex="-1">
                    <!-- [startDate]="selectionFromDate!" -->
              <ng-template #dayTemplate let-date let-focused="focused">
                <span class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      [class.unavailable]="!isHovered(date) && !isRange(date) && isUnavailable(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
              </ng-template>
              <ng-template #datePickerFooter>
                <div class="d-flex">
                  <div></div>
                  <button class="btn btn-outline-primary btn-sm m-2 p-1 ml-auto" (click)="datepicker.close()">{{'common.close'|translate|titlecase}}</button>
                </div>
              </ng-template>
            </div>
          </div>

          <!-- price, commission, max items, discount -->
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="price">{{'common.price'|translate|titlecase}}</label>
                <input class="form-control" type="text" id="price" formControlName="price" name="price">
                <span *ngIf="isPaid()" class="text text-warning">{{'merchant.salesperiod.cannot_edit_price_coz_paid'|translate}}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="commission">{{'common.commission'|translate|titlecase}}</label>
                <input class="form-control" type="text" id="commission" formControlName="commission" name="commission">
                <span *ngIf="editingPeriod">{{'merchant.salesperiod.provision_change_affects_new'|translate}}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="max_items">{{'common.max_items'|translate}}</label>
                <input type="text" class="form-control" id="max_items" formControlName="max_items" name="max_items" placeholder="{{'common.optional'|translate|titlecase}}">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="discount">{{'common.sale__discount'|translate}}</label>
                <input type="text" class="form-control" id="discount" formControlName="discount" name="discount" placeholder="{{'common.optional'|translate|titlecase}}">
              </div>
            </div>
          </div>

          <!-- payment status -->
          <div class="row">
            <div class="col">
              <label for="paymentStatusRadio">{{'common.payment_status'|translate|titlecase}}</label>
              <br/>
              <div class="d-flex">
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="paymentStatusRadio" formControlName="payment_status">
                  <label ngbButtonLabel [ngClass]="classForPaymentStatusRadioElement('payment_status_paid')">
                    <input ngbButton type="radio" value="payment_status_paid">{{'payment_status.payment_status_paid'|translate}}
                  </label>
                  <label ngbButtonLabel [ngClass]="classForPaymentStatusRadioElement('payment_status_none')">
                    <input ngbButton type="radio" value="payment_status_none">{{'payment_status.payment_status_none'|translate}}
                  </label>
                </div>
                <div *ngIf="editingPeriod && editingPeriod.payment_status != 'payment_status_paid' && editingPeriod.payment_status != 'payment_status_none'" class="ml-2">
                  <button class="btn" [ngClass]="classForPaymentStatus(editingPeriod.payment_status)" [disabled]="true">{{'payment_status.'+editingPeriod.payment_status|translate}}</button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- modal right column -->
        <div class="col-md-6">
          <div class="form-group">
            <div class="d-flex">
              <label for="seller" class="mr-2">{{'common.seller'|translate|titlecase}}</label>
              <a *ngIf="selectedSellerId" class="btn btn-primary ml-auto px-1 py-0" routerLink="/seller/{{selectedSellerId}}"><i-bs name="list"></i-bs></a>
              <a *ngIf="selectedSellerId" class="btn btn-primary px-2 py-0 ml-1" routerLink="/seller/{{selectedSellerId}}" target="_blank"><i-bs name="boxArrowUpRight" width="12" height="12"></i-bs></a>
            </div>
            <ng-select formControlName="seller_id" bindLabel="id" id="seller_id" name="seller" placeholder="{{'common.choose_seller'|translate}}">
              <ng-option *ngFor="let s of sellersService.sellers | async" [value]="s.id">{{s.name}} <span class="badge badge-light">{{s.user.email}}</span></ng-option>
            </ng-select>
          </div>
          <div class="form-group">
            <label for="seller_note">{{'common.seller_note'|translate}}</label>
            <textarea type="text" rows="5" class="form-control p-1" id="seller_note"
              formControlName="seller_note" name="seller_note"></textarea>
          </div>
          <div class="form-group">
            <label for="internal_note">{{'common.internal_note'|translate}}</label>
            <textarea type="text" rows="5" class="form-control p-1" id="internal_note"
              formControlName="internal_note" name="internal_note"></textarea>
          </div>

          <!-- custom status -->
          <div class="row" *ngIf="customPeriodStatusesEnabled()">
            <div class="col">
              <div class="form-group">
                <label for="customPeriodStatusRadio">{{'common.custom_period_status'|translate|titlecase}}</label>
                <br/>
                <div class="d-flex">
                  <div class="btn-group btn-group-toggle" ngbRadioGroup name="customPeriodStatusRadio" formControlName="custom_period_status_id">
                    <label ngbButtonLabel *ngFor="let status of customPeriodStatuses()" class="badge badge-light" [ngStyle]="styleForCustomStatus(status)">
                      <input ngbButton type="radio" value="{{status.id}}" (change)="customStatusSelected(status)">
                      {{status.name}}
                      <span class="badge badge-secondary p-1 ml-1 mb-0" *ngIf="isCustomStatusSelected(status)"><i-bs name="x"></i-bs></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div><!-- modal-body -->

    <div class="modal-footer">
      <span *ngIf="!salesPeriodForm.valid" class="mr-auto">{{'common.invalid'|translate}}</span>
      <button type="button" class="btn btn-secondary ml-auto" (click)="cancelSalePeriodEdit()">{{'common.cancel'|translate|uppercase}}</button>
      <button type="submit" class="btn btn-primary" [disabled]="!salesPeriodForm.valid || datePickerOpen">{{'common.save'|translate|uppercase}}</button>
    </div>
  </form>

</ng-template>
