import { PlainDate } from '@/util/date-utils'

import { Rack } from '@/domain/rack'
import { ServiceProduct } from '@/domain/service-product'
import { ServiceProductVariation } from '@/domain/service-product-variation'

export const FREE_DAY = 'free'
export const BUSY_DAY = 'busy'
export const CLOSED_DAY = 'closed' // store closed

export class SelectedVariationAndDate {
  constructor(
    public product: ServiceProduct,
    public variation: ServiceProductVariation,
    public rack: Rack,
    public from: PlainDate,
    public to: PlainDate,
    public rackId?: number
  ) {}
}
