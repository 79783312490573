<div class="col-md-4 offset-md-4 mt-5" style="margin-top: 60px;">

  <div class="card" style="margin-top: 100px;">
    <h4 class="card-header">{{'account.confirm_title'|translate}}</h4>
    <div class="card-body">

      <alert></alert>

      <div *ngIf="!token">
        <div class="alert alert-info">{{'account.nothing_to_confirm'|translate}}</div>
        <a class="btn btn-primary btn-lg" routerLink="/login">{{'account.goto_login'|translate|uppercase}}</a>
      </div>

      <div class="alert alert-info" *ngIf="confirming">
        {{'account.confirming'|translate}}
        <span class="spinner-border spinner-border-sm ml-1"></span>
        <br/>
        <span class="badge badge-light mt-2">{{token}}</span>
      </div>

      <div *ngIf="confirmationTimeout">
        <div class="alert alert-warning">
          {{'account.confirmation_timeout'|translate}}
        </div>
      </div>

      <div *ngIf="invalidToken">
        <div class="alert alert-warning">
          {{'account.invalid_token'|translate}}
        </div>
      </div>

      <div *ngIf="reconfirmationSent">
        <div class="alert alert-success">
          {{'account.reconfirmation_sent'|translate}}
        </div>
      </div>

      <div *ngIf="success || alreadyConfirmed">
        <div class="alert alert-success" *ngIf="success">{{'account.confirmed_success'|translate}}</div>
        <div class="alert alert-success" *ngIf="alreadyConfirmed">{{'account.already_confirmed'|translate}}</div>
        <a *ngIf="!merchantHostname" class="btn btn-primary btn-lg" style="width: 100%;" routerLink="/login">{{'account.goto_login'|translate|uppercase}}</a>
        <a *ngIf="merchantHostname" class="btn btn-primary btn-lg" style="width: 100%;" href="{{hostnameLink()}}">{{'account.goto_login'|translate|uppercase}}</a>
      </div>

      <div class="alert alert-danger" *ngIf="failure">
        {{'account.confirmed_failure'|translate}}
      </div>

    </div>
    <div class="card-footer d-flex" *ngIf="confirmationTimeout">
      <button class="btn btn-primary ml-auto" (click)="reconfirm()">{{'account.reconfirm'|translate|uppercase}}</button>
    </div>
  </div>
</div>
