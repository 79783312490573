import { Component } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

import { SalesPeriodsService } from '@/services/sales-periods.service'

import { TranslateService } from '@ngx-translate/core'

@UntilDestroy()
@Component({
  selector: 'preliminary-sales-periods',
  template: `
    <div class="mt-4">
      <sales-period-list
        [salesPeriods]="periods.preliminary$|async" 
        [title]="title"
        [hide]="'productCount,unsettledProductCount'"
      ></sales-period-list>
    </div>
  `
})
export class PreliminarySalesPeriodsViewComponent {

  constructor(
    public periods: SalesPeriodsService,
    private _translate: TranslateService,
  ) { }

  get title(): string {
    return this._translate.instant('pricing.title.preliminary')
  }
}
