import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'

import * as api from '@/config/api-config.json'

import { CableService } from './cable.service'

import { ProductStatus } from '@/domain/product-status'
import { ProductCategory } from '@/domain/product-category'
import { ProductType } from '@/domain/product-type'
import { ProductBrand } from '@/domain/product-brand'
import { ProductSize } from '@/domain/product-size'
import { ProductColor } from '@/domain/product-color'
import { ProductCondition } from '@/domain/product-condition'

function toObjectMap(arr: any[]) {
  return arr.reduce(function(map, item) { 
    map[item.id] = item
    return map
  }, {})
}

@Injectable({ providedIn: 'root' })
export class ProductFeaturesService {

  // productStatuses: ProductStatus[]
  // productCategories: ProductCategory[]
  // productColors: ProductColor[]
  // productConditions: ProductCondition[]

  private _allTypes$ = new BehaviorSubject<ProductType[]>([])
  private _allBrands$ = new BehaviorSubject<ProductBrand[]>([])
  private _allSizes$ = new BehaviorSubject<ProductSize[]>([])

  private _types$: Observable<ProductType[]>
  private _brands$: Observable<ProductBrand[]>
  private _sizes$: Observable<ProductSize[]>

  private _typesById$: Observable<{}>
  private _brandsById$: Observable<{}>
  private _sizesById$: Observable<{}>

  constructor(
    private _cables: CableService,
    private _http: HttpClient
  ) { 
    this.getBrands().subscribe(x => this._allBrands$.next(x))
    this.getSizes().subscribe(x => this._allSizes$.next(x))
    this.getTypes().subscribe(x => this._allTypes$.next(x))

    this._cables.productBrands.subscribe((item: ProductBrand) => { this._allBrands$.next(this._allBrands$.value.concat([item])) })
    this._cables.productSizes.subscribe((item: ProductSize) => { this._allSizes$.next(this._allSizes$.value.concat([item])) })
    this._cables.productTypes.subscribe((item: ProductType) => { this._allTypes$.next(this._allTypes$.value.concat([item])) })

    this._brands$ = this._allBrands$.asObservable()
    this._sizes$ = this._allSizes$.asObservable()
    this._types$ = this._allTypes$.asObservable()

    this._brandsById$ = this._brands$.pipe(map(types => toObjectMap(types)))
    this._sizesById$ = this._sizes$.pipe(map(types => toObjectMap(types)))
    this._typesById$ = this._types$.pipe(map(types => toObjectMap(types)))
  }

  get types$(): Observable<ProductType[]> { return this._types$ }
  get brands$(): Observable<ProductType[]> { return this._brands$ }
  get sizes$(): Observable<ProductType[]> { return this._sizes$ }

  get typesById$(): Observable<{}> { return this._typesById$ }
  get brandsById$(): Observable<{}> { return this._brandsById$ }
  get sizesById$(): Observable<{}> { return this._sizesById$ }

  getStatuses(): Observable<ProductStatus[]> {
    return this._http.get<ProductStatus[]>(api.productFeatures.status)
  }

  getCategories(): Observable<ProductCategory[]> {
    return this._http.get<ProductCategory[]>(api.productFeatures.category)
  }

  getTypes(): Observable<ProductType[]> {
    return this._http.get<ProductType[]>(api.productFeatures.type)
  }

  getBrands(): Observable<ProductBrand[]> {
    return this._http.get<ProductBrand[]>(api.productFeatures.brand)
  }

  getSizes(): Observable<ProductSize[]> {
    return this._http.get<ProductSize[]>(api.productFeatures.size)
  }

  getColors(): Observable<ProductColor[]> {
    return this._http.get<ProductColor[]>(api.productFeatures.color)
  }

  getConditions(): Observable<ProductCondition[]> {
    return this._http.get<ProductCondition[]>(api.productFeatures.condition)
  }

}
