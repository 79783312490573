import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

import * as api from '@/config/api-config.json'

import { ServiceProduct } from '@/domain/service-product'

@Injectable({ providedIn: 'root' })
export class ServiceProductsService {

  private _source: ServiceProduct[] = null
  private _subject: BehaviorSubject<ServiceProduct[]>
  private _observable: Observable<ServiceProduct[]>

  constructor(private _http: HttpClient) { 
    this._subject = new BehaviorSubject<ServiceProduct[]>([])
    this._observable = this._subject.asObservable()
  }

  get serviceProducts(): Observable<ServiceProduct[]> {
    this._load()
    return this._observable
  }

  private _loading: boolean = false
  private _load(): void {
    if (this._source || this._loading) {
      return
    }
    this._loading = true
    this._http.get<ServiceProduct[]>(api.serviceProducts.all).subscribe(
      p => {
        this._source = ServiceProduct.fromMany(p)
        this._subject.next(this._source)
        this._loading = false
      }
    )
  }

  add(product: ServiceProduct): Observable<ServiceProduct> {
    return this._http.post<ServiceProduct>(api.serviceProducts.new, product)
  }
}
