export enum ErrorType {
  ERROR,
  WARNING
}

export class ErrorCode {
  public code: string
  public description: string
  public type: ErrorType

  constructor(
    code: string,
    description: string
  ) {
    this.code = code
    this.description = description
    this.type = code.charAt(0) === 'E' ? ErrorType.ERROR : ErrorType.WARNING
  }
}

const E0001  = new ErrorCode('E0001',  'Error')
const E01011 = new ErrorCode('E01011', 'Rack not available')
const E02001 = new ErrorCode('E02001', 'Confirmation token timeout')
const E02002 = new ErrorCode('E02002', 'Invalid confirmation token')
const E02003 = new ErrorCode('E02003', 'Token already confirmed')

const W0001  = new ErrorCode('W0001',  'Warning')
const W01011 = new ErrorCode('E01011', 'Overlapping reservation on rack')


export {
  E0001,
  E01011,
  E02001,
  E02002,
  E02003,
  W0001,
  W01011
}