import { Component, OnInit } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { ApplicationUpdateService } from '@/services/application-update.service'

@UntilDestroy()
@Component({ 
  selector: 'application-update-alert', 
  templateUrl: 'application-update-alert.component.html' 
})
export class ApplicationUpdateAlertComponent implements OnInit {

  updateNeeded: boolean = false

  constructor(private service: ApplicationUpdateService) { }

  ngOnInit() {
    this.service.getNotification().pipe(untilDestroyed(this))
      .subscribe(needed => {
        if (needed) {
          this.updateNeeded = true
        }
      })
  }

  update() {
    this.service.update()
  }
}