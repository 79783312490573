
import { Component } from '@angular/core'
import { saveAs } from 'file-saver'

import { AuthenticationService } from '@/services/authentication.service'
import { SettlementsService } from '@/services/settlements.service'

import { Merchant } from '@/domain/merchant'
import { Settlement } from '@/domain/settlement'

@Component({
  selector: 'settlements-view',
  templateUrl: './settlements-view.component.html',
})
export class SettlementsViewComponent {

  merchant: Merchant
  months: number[] = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ]
  today: Date = new Date()

  constructor(
    public auth: AuthenticationService,
    public service: SettlementsService
  ) {
    this.merchant = this.auth.currentUserValue.merchant
  }

  hasPdf(settlement: Settlement): boolean {
    return !!settlement.pdf_document && !!settlement.pdf_document.uuid
  }

  pdfPath(settlement: Settlement): string {
    return `/s/${settlement.pdf_document.uuid}`
  }

  getPastSettlementReport(year: number, month: number): void {
    this.service.getPastSettlementReport(year, month).subscribe(
      blob => {
        saveAs(blob, `tilitysraportti-${year}-${month}.pdf`)
      }
    )
  }

  getSettlementReport(prev: boolean = false): void {
    this.service.getSettlementReport(prev).subscribe(
      blob => {
        saveAs(blob, `tilitysraportti.pdf`)
      }
    )
  }

  years(): number[] {
    if (!this.merchant.hasSettlements()) {
      return []
    }
    let startYear = this.merchant.earliest_settlement_date.year()
    let currentYear = this.today.getFullYear()
    let years = []
    for (var i = currentYear; i >= startYear; i--) {
      years.push(i)
    }
    return years
  }

  show(year: number, month: number): boolean {
    let earliestDate = this.merchant.earliest_settlement_date
    if (earliestDate == null) {
      return false
    }
    if (year < earliestDate.year() || year > this.today.getFullYear()) {
      return false
    }
    if (year == earliestDate.year()) {
      return month >= earliestDate.month().value() && (earliestDate.year() != this.today.getFullYear() || month <= (this.today.getMonth() + 1))
    }
    if (year > earliestDate.year() && year < this.today.getFullYear()) {
      return true
    }
    return month <= (this.today.getMonth() + 1)
  }

  zeroPad2(n: number): string {
    return n ? (""+n).padStart(2, '0') : ''
  }
}
