import { Router } from '@angular/router'
import { Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core'
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { startOfToday, startOfTomorrow, isToday, isTomorrow, formatISO } from 'date-fns'

import { AuthenticationService } from '@/services/authentication.service'
import { SalesPeriodsService } from '@/services/sales-periods.service'

import { SalesPeriod } from '@/domain/sales-period'
import { Store } from '@/domain/store'

@Component({
  selector: 'sales-periods-summary-list',
  templateUrl: 'sales-periods-summary-list.component.html'
})
export class SalesPeriodsSummaryListComponent implements OnInit, OnChanges {

  @Input()
  date: Date = new Date()

  @Input()
  store: Store = null

  @Output()
  change = new EventEmitter<Date>()

  selectedDate: NgbDateStruct

  startingPeriods: SalesPeriod[] = null
  endingPeriods: SalesPeriod[] = null

  filteredStartingPeriods: SalesPeriod[] = null
  filteredEndingPeriods: SalesPeriod[] = null

  displayDate: string = ''

  constructor(
    public auth: AuthenticationService,
    private salesPeriodsService: SalesPeriodsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this._reload(false)
  }

  ngOnChanges(): void {
    this._filterByStore()
  }

  get isToday(): boolean {
    return isToday(this.date)
  }

  get isTomorrow(): boolean {
    return isTomorrow(this.date)
  }

  get otherDay(): boolean {
    return !this.isToday && !this.isTomorrow
  }

  dateSelectionChanged() {
    this.date = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day)
    this._reload()
  }

  gotoToday(d) {
    d.close()
    this.date = startOfToday()
    this._reload()
  }

  gotoTomorrow(d) {
    d.close()
    this.date = startOfTomorrow()
    this._reload()
  }

  selectPeriod(period: SalesPeriod): void {
    this.router.navigate([ '/period', period.id ])
  }

  // private

  private _reload(emit: boolean = true) {
    if (emit) {
      this.change.emit(this.date)
    }
    this.salesPeriodsService.getStartingOnDate(this.date).subscribe(periods => {
      this.startingPeriods = this._filterAndSort(periods)
      this._filterByStore()
    })
    this.salesPeriodsService.getEndingOnDate(this.date).subscribe(periods => {
      this.endingPeriods = this._filterAndSort(periods)
      this._filterByStore()
    })

    this.displayDate = formatISO(this.date, { representation: 'date' })
    this.selectedDate = this.asNgbDateStruct(this.date)
  }

  private _filterByStore() {
    if (this.store && this.startingPeriods && this.endingPeriods) {
      this.filteredStartingPeriods = this.startingPeriods.filter(p => p.store.id === this.store.id)
      this.filteredEndingPeriods = this.endingPeriods.filter(p => p.store.id === this.store.id)
    } else {
      this.filteredStartingPeriods = this.startingPeriods
      this.filteredEndingPeriods = this.endingPeriods
    }
  }

  private _filterAndSort(periods: SalesPeriod[]): SalesPeriod[] {
    let noncancelled = periods.filter(p => { return p.status === 'status_reserved' })
    return noncancelled.sort((a, b) => { return a.rack_time_slot.reck.name.localeCompare(b.rack_time_slot.reck.name) })
  }

  private asNgbDateStruct(date: Date): NgbDateStruct {
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
  }
}
