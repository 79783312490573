import { Component, OnInit } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { AuthenticationService } from '@/services/authentication.service'
import { SalesPeriodsService } from '@/services/sales-periods.service'

@UntilDestroy()
@Component({
  selector: 'sales-periods-view',
  templateUrl: 'sales-periods-view.component.html',
  styleUrls: [ 'sales-periods-view.component.scss' ]
})
export class SalesPeriodsViewComponent implements OnInit {

  // currentCount: number = null
  // futureCount: number = null
  // completedCount: number = null
  // cancelledCount: number = -1

  constructor(
    public auth: AuthenticationService,
    private _salesPeriodsService: SalesPeriodsService
  ) { }

  ngOnInit(): void {
    // const today = this.today()
    // this._salesPeriodsService.salesPeriods
    //   .pipe(untilDestroyed(this))
    //   .subscribe(all => {
    //     this.currentCount = all.filter(p => { return p.status != 'status_cancelled' && new Date(p.rack_time_slot.from) <= today && new Date(p.rack_time_slot.to) >= today }).length
    //     this.futureCount = all.filter(p => { return p.status != 'status_cancelled' && new Date(p.rack_time_slot.from) > today }).length
    //     this.completedCount = all.filter(p => { return p.status != 'status_cancelled' && new Date(p.rack_time_slot.to) < today }).length
    //     this.cancelledCount = all.filter(p => { return p.status == 'status_cancelled' }).length
    //   })
  }

  // private today(): Date {
  //   let d = new Date()
  //   d.setHours(0,0,0,0)
  //   return d
  // }
}