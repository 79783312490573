import { add, differenceInCalendarDays } from 'date-fns'
import { isEqual, isAfter, isBefore, isWeekend } from 'date-fns'
import { LocalDate } from '@js-joda/core'

import { RackTimeSlot } from '@/domain/rack-time-slot'

/**
 * Representation of a date without a time
 */
export class PlainDate {
  static DAYS = ['sun','mon','tue','wed','thu','fri','sat']

  /** @param date as Date */
  static ofDate(date: Date): PlainDate {
    return new PlainDate(date)
  }

  /** @param date as 'YYYY-MM-DD' */
  static ofString(date: string): PlainDate {
    return new PlainDate(new Date(date))
  }

  static generateRange(from: PlainDate, to: PlainDate): PlainDate[] {
    let range: PlainDate[] = []
    let start = from.asDate()
    let end = to.asDate()
    for (let day = start; day <= end; day.setDate(day.getDate() + 1)) {
      range.push(PlainDate.ofDate(day))
    }
    return range
  }

  /** Full year, e.g. 2021 */
  public year: number

  /** Month, 1-12 */
  public month: number
  
  /** Date, 1-31 */
  public date: number

  private _date: Date

  private constructor(date: Date) {
    this.year = date.getFullYear()
    this.month = date.getMonth() + 1
    this.date = date.getDate()
    this._date = new Date(this.year, this.month - 1, this.date)
  }

  public isWeekend(): boolean {
    return isWeekend(this._date)
  }

  public asDate(): Date {
    return this._date
  }

  public getWeekDay(): number {
    return this._date.getDay() // 0=sunday
  }

  /** @return 'mon', 'tue', etc */
  public getWeekDayStringShortLC(): string {
    return PlainDate.DAYS[this.getWeekDay()]
  }

  public asLocalDate(): LocalDate {
    return LocalDate.of(this._date.getFullYear(), this._date.getMonth()+1, this._date.getDate())
  }
}

export function duration(slot: RackTimeSlot): number {
  return differenceInCalendarDays(new Date(slot.to), new Date(slot.from)) + 1
}

export function remainingDuration(slot: RackTimeSlot): number {
  return differenceInCalendarDays(new Date(slot.to), new Date()) + 1
}

export function isOnOrAfter(date1: Date | string, date2: Date): boolean {
  let d1 = typeof(date1) === 'string' ? new Date(date1) : plainDateFrom(date1)
  let d2 = typeof(date2) === 'string' ? new Date(date2) : plainDateFrom(date2)
  let x = isEqual(d1, d2) || isAfter(d1, d2)
  return x
}

export function areEqual(d1: PlainDate, d2: PlainDate): boolean {
  return isEqual(d1.asDate(), d2.asDate())
}

export function isOnOrBefore(date1: Date | string, date2: Date): boolean {
  let d1 = typeof(date1) === 'string' ? new Date(date1) : plainDateFrom(date1)
  let d2 = typeof(date2) === 'string' ? new Date(date2) : plainDateFrom(date2)
  return isEqual(d1, d2) || isBefore(d1, d2)
}

function plainDateFrom(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export function addDays(date: PlainDate, numberOfDays: number): PlainDate {
  return PlainDate.ofDate(add(date.asDate(), { days: numberOfDays }))
}

export function isDayBefore(date: Date, dateToCompare: Date): boolean {
  return isBefore(asDay(date), asDay(dateToCompare))
}

export function isDayOnOrBefore(date: Date, dateToCompare: Date): boolean {
  const d1 = asDay(date)
  const d2 = asDay(dateToCompare)
  return isEqual(d1, d2) || isBefore(d1, d2)
}

export function isDayAfter(date: Date, dateToCompare: Date): boolean {
  return isAfter(asDay(date), asDay(dateToCompare))
}

export function isDayOnOrAfter(date: Date, dateToCompare: Date): boolean {
  const d1 = asDay(date)
  const d2 = asDay(dateToCompare)
  return isEqual(d1, d2) || isAfter(d1, d2)
}

export function isDayOnOrBeforeToday(date: Date): boolean {
  return isDayOnOrBefore(date, new Date())
}

export function isDayOnOrAfterToday(date: Date): boolean {
  return isDayOnOrAfter(date, new Date())
}

export function isTodayOnOrBetween(date1: Date, date2: Date): boolean {
  return isDayOnOrBeforeToday(asDay(date1)) && isDayOnOrAfterToday(asDay(date2))
}

export function isAfterToday(date: Date): boolean {
  return isDayAfter(date, new Date())
}

export function isBeforeToday(date: Date): boolean {
  return isDayBefore(date, new Date())
}

/** Get date as Y M D without time of day */
function asDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export function localDateAsDate(localDate: LocalDate): Date {
  return new Date(localDate.year(), localDate.monthValue()-1, localDate.dayOfMonth())
}