import { Pipe, PipeTransform } from '@angular/core'
import { Settlement } from '@/domain/settlement'

@Pipe({ name: 'ysort' })
export class YSortPipe implements PipeTransform {
  transform(settlements: Settlement[]): Settlement[] {
    return settlements.sort((a, b) => {
      return a.sales_period_id === b.sales_period_id ? (a.number - b.number) : (a.sales_period_id - b.sales_period_id)
    })
  }
}
