import { Merchant } from '@/domain/merchant'
import { Seller } from '@/domain/seller'

export class User {
  id: number
  email?: string
  username?: string
  password?: string
  firstName?: string
  lastName?: string
  role?: string
  token?: string
  created_at?: string
  updated_at?: string

  merchant?: Merchant
  seller?: Seller
}