<div class="container-fluid">

  <div class="card gutter-t gutter-b">
    <div class="card-header">
      <h1>
        {{'common.service_products'|translate}}
      </h1>
    </div>
    <div class="card-body foo">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{'common.code'|translate}}</th>
            <th scope="col">{{'common.name'|translate}}</th>
            <th scope="col">{{'common.description'|translate}}</th>
            <th scope="col">{{'common.service_level'|translate}}</th>
            <th scope="col">{{'common.service_type'|translate}}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of products">
            <!--{{product.id}}-->
            <th scope="row"><span class="badge badge-light p-2">{{product.code}}</span></th>
            <td>{{product.name}}</td>
            <td>{{product.description}}</td>
            <td>{{product.service_level.name}}</td>
            <td>{{product.service_type.name}}</td>
            <td>
              <div class="btn-group" ngbDropdown role="group" aria-label="Service product actions dropdown">
                <button type="button" class="btn btn-secondary btn-sm">Racks</button>
                <button class="btn btn-outline-secondary btn-sm" ngbDropdownToggle>Actions</button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button ngbDropdownItem class="btn-sm">Edit</button>
                  <button ngbDropdownItem class="btn-sm">Archive</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
