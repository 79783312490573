<div class="card mb-4" *ngIf="seller">
  <div class="card-header">
    <div class="d-flex">
      <h2>{{'seller.profile.contact_header'|translate}}</h2>
      <div class="ml-auto">
        <button class="btn btn-primary" (click)="editContact()" *ngIf="!editingContactInfo; else contactEditButtons">{{'common.edit'|translate|uppercase}}</button>
        <ng-template #contactEditButtons>
          <button class="btn btn-secondary mr-2" (click)="cancelContactEdit()">{{'common.cancel'|translate|uppercase}}</button>
          <button class="btn btn-primary" (click)="onSubmitContactForm()">{{'common.save'|translate|uppercase}}</button>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="card-body">

    <form [formGroup]="contactInfoForm" (ngSubmit)="onSubmitContactForm()">

      <div class="row form-group">
        <label class="col-md-3 col-form-label">
          {{'common.email'|translate|titlecase}}
        </label>
        <div class="col-md-9">
          <input type="text" formControlName="email" class="form-control form-control-lg"/>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-md-3 col-form-label">
          {{'common.name'|translate|titlecase}}
        </label>
        <div class="col-md-9">
          <input #contactName type="text" formControlName="name" class="form-control form-control-lg"/>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-md-3 col-form-label">
          {{'common.phone'|translate|titlecase}}
        </label>
        <div class="col-md-9">
          <input type="text" formControlName="phone" class="form-control form-control-lg"/>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-md-3 col-form-label">
          {{'common.marketing'|translate|titlecase}}
        </label>
        <div class="col-md-9">
          <input #marketingCheckBox type="checkbox" formControlName="accept_marketing" class="form-control form-control-lg"/>
        </div>
      </div>

    </form>

  </div>
</div>

<div class="card mb-4" *ngIf="seller">
  <div class="card-header">
    <div class="d-flex">
      <h2>{{'seller.profile.payments_header'|translate}}</h2>
      <div class="ml-auto">
        <button class="btn btn-primary" (click)="editPayment()" *ngIf="!editingPaymentInfo; else paymentEditButtons">{{'common.edit'|translate|uppercase}}</button>
        <ng-template #paymentEditButtons>
          <button class="btn btn-secondary mr-2" (click)="cancelPaymentEdit()">{{'common.cancel'|translate|uppercase}}</button>
          <button class="btn btn-primary" (click)="onSubmitPaymentForm()">{{'common.save'|translate|uppercase}}</button>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="paymentInfoForm" (ngSubmit)="onSubmitPaymentForm()">
      <div class="row form-group">
        <label class="col-md-3 col-form-label">
          {{'common.iban'|translate}}
        </label>
        <div class="col-md-9">
          <input #paymentIban type="text" formControlName="iban" value="{{seller.iban}}" class="form-control form-control-lg"/>
        </div>
      </div>
    </form>
  </div>
</div>
