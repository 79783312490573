import { Injectable } from '@angular/core'
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { tap, catchError } from 'rxjs/operators'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        // tap(event => {
        //   if (event instanceof HttpResponse && event.body && event.body.errors) {
        //     this.alertService.errors(event.body.errors)
        //   }
        // }),
        catchError(response => {
          if ([401, 403].indexOf(response.status) !== -1) {
            let error = response.error
            let errorMessage = error != null ? error.error : 'Error'
            if (errorMessage == 'Signature has expired') {
              // TODO refresh the token and keep going ...
            }
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            this.authenticationService.logout()
            location.reload()
          }
          return throwError(response.error)
        })
      )
    }
}
