<!-- title -->
<span *ngIf="title">{{title}}</span>
<span *ngIf="title && product" class="mx-2">&bull;</span>

<!-- store -->
<span *ngIf="store">
  <span class="badge badge-light p-2 mr-2">{{store.name}}</span>

  <!-- product -->
  <span *ngIf="product">
    <span class="mr-2">&bull;</span>
    <span class="badge badge-light p-2">{{product.code}}</span>
    <span *ngIf="auth.isSeller" class="ml-2">{{product.name}}</span>
    <span *ngIf="readmore && auth.isSeller && product.readmore_url" class="ml-2">(<a [href]="product.readmore_url" target="_blank">{{'common.readmore'|translate}}</a>)</span>
  </span>
</span>

<!-- variation -->
<span *ngIf="auth.isSeller"><br/></span>
<span class="badge badge-light p-2" [ngClass]="{'ml-2': auth.isMerchant}" *ngIf="variation">
  {{variation.code}}
</span>
<span *ngIf="auth.isSeller && variation" class="ml-2">{{variation.name}}</span>
<span *ngIf="readmore && auth.isSeller && variation && variation.readmore_url" class="ml-2">(<a [href]="variation.readmore_url" target="_blank">{{'common.readmore'|translate}}</a>)</span>
<span *ngIf="!variation"><br/>&nbsp;<br/>&nbsp;</span>
<span *ngIf="variation">
  <br/>
  <!-- duration -->
  {{'common.duration'|translate|titlecase}}
  <span class="badge badge-light p-2">{{variation.duration}}{{'common.days_abbrev'|translate}}</span>
  &bull;
  <!-- capacity -->
  <span *ngIf="capacity != '–'">
    {{'common.max_items'|translate}} <span class="badge badge-light p-2 mr-2">{{capacity}}</span>
    &bull;
  </span>
  <!-- commission -->
  <span *ngIf="commission != '–'">
    {{'common.commission'|translate|titlecase}} <span class="badge badge-light p-2 mr-2">{{commission}}</span>
  </span>
  <!-- prices -->
  <br/>
  <span *ngIf="price">
    <span *ngIf="priceInStore">{{'common.price_in_web'|translate}}</span>
    <span *ngIf="!priceInStore">{{'common.price'|translate}}</span>
    <span class="badge badge-light p-2 mx-2">{{price}}</span>
  </span>
  <span *ngIf="priceInStore">
    <span *ngIf="price">{{'common.price_in_store'|translate}}</span>
    <span *ngIf="!price">{{'common.price'|translate}}</span>
    <span class="badge badge-light p-2 ml-2">{{priceInStore}}</span>
  </span>
</span>
