<div class="col-md-4 offset-md-4 mt-5" style="margin-top: 60px;">

  <div class="card" style="margin-top: 100px;">
    <form [formGroup]="requestForm" (ngSubmit)="onSubmit()">

      <div *ngIf="merchantLogoUrl" class="merchant-logo-wrapper p-4">
        <img class="merchant-logo-img" src="{{merchantLogoUrl}}" alt="logo"/>
      </div>
      <h4 class="card-header">{{'account.request_password_title'|translate}}</h4>
      <div class="card-body">
        <alert></alert>
        <div *ngIf="!didRequest"><p>{{'account.request_password_pre_msg'|translate}}</p></div>
        <div *ngIf="!didRequest" class="form-group">
          <label for="email">{{'common.email'|translate}}</label>
          <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">{{'account.email_required'|translate}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="!didRequest" class="card-footer d-flex">
        <div class="ml-auto t-right">
          <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{'account.request_password_button'|translate|uppercase}}
          </button>
        </div>
      </div>
    </form>

  </div>
</div>
