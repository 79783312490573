<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top" style="margin-top: 40px; background-color: white !important; padding-top: 0; padding-bottom: 0;">
  <div class="navbar-brand col-sm-3 col-md-2 mr-0"></div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav">
      <!-- <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="current">{{'pricing.title.active'|translate}} <span *ngIf="currentCount">&nbsp;&bull; {{currentCount}}</span></a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="future">{{'pricing.title.current_reservations'|translate}} <span *ngIf="futureCount">&nbsp;&bull; {{futureCount}}</span></a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="past">{{'pricing.title.completed'|translate}} <span *ngIf="completedCount">&nbsp;&bull; {{completedCount}}</span></a></li> -->
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="current">{{'pricing.title.active'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="preliminary" *ngIf="auth.isMerchant">{{'pricing.title.preliminary'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="pending">{{'pricing.title.pending'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="future">{{'pricing.title.current_reservations'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="unsettled" *ngIf="auth.isMerchant">{{'pricing.title.unsettled'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="past">{{'pricing.title.completed'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="cancelled" *ngIf="auth.isMerchant">{{'pricing.title.cancelled'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="rejected" *ngIf="auth.isMerchant">{{'pricing.title.rejected'|translate}}</a></li>

    </ul>
  </div>
</nav>

<div class="container-fluid" style="margin-top: 82px;">
  <div class="row">
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
