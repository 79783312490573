<div *ngIf="stores && stores.length > 1" class="d-inline-flex m-2 p-2" style="background: white;">
  <div class="btn-group btn-group-toggle">
    <label *ngFor="let store of stores" ngbButtonLabel [ngClass]="classForStore(store)" class="d-flex btn p-1">
      <input type="checkbox" ngbButton id="{{store.id}}"
        [(ngModel)]="storeChoice[store.id]"
        (change)="toggleStore(store.id)"/>
      <h3 class="mx-1">{{store.name}}</h3>
      <span *ngIf="selected && selected.id == store.id"
        class="badge badge-secondary p-1 ml-1 mb-0"><i-bs name="x"></i-bs></span>
    </label>
  </div>
</div>
