<div class="container-fluid">

  <div class="card gutter-t gutter-b">
    <div class="card-header">
      <div class="d-flex">
        <h1>
          {{'common.service_types'|translate}} 
        </h1>
        <!-- <button class="btn btn-primary ml-auto">{{'common.add_new'|translate|uppercase}}</button> -->
      </div>
    </div>
    <div class="card-body" *ngIf="(service.types$ | async)?.length > 0">

      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{'common.name'|translate|titlecase}}</th>
            <th scope="col">{{'common.description'|translate|titlecase}}</th>
            <th scope="col">{{'common.service_products'|translate|titlecase}}</th>
            <th scope="col"><!-- actions --></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let type of service.types$ | async">
            <td>
              {{type.name}}
              <!-- <input type="text" class="form-control" value="blah"/>form-control-lg -->
            </td>
            <td>
              {{type.description}}
            </td>
            <td>
              2
            </td>
            <td>
              <span class="btn btn-primary p-1 m-1 mr-2"><i-bs name="pencil" class="p-0 m-0"></i-bs></span>
              <span class="btn btn-outline-danger p-1 m-1 mr-2"><i-bs name="x" class="p-0 m-0"></i-bs></span>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>

</div>