import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@/domain/user';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) { }

  // private host = 'https://bluto.varpu.local';
  private host = '';

  getAll() {
    return this.http.get<User[]>(`${this.host}/users`);
  }

  register(user: User) {
    return this.http.post(`${this.host}/users/register`, user);
  }

  delete(id: number) {
    return this.http.delete(`${this.host}/users/${id}`);
  }

  getById(id: number) {
    let url = `${this.host}/users/${id}`;
    // console.log(`Loading ${url}`)
    return this.http.get<User>(url);
  }
}