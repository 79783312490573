<!-- 
<div *ngIf="storesAvailable === null">{{ 'common.loading' | translate | titlecase }}</div>
<div *ngIf="storesAvailable === false">{{ 'common.stores_unavailable' | translate }}</div> 
-->

<div class="service-product-selector d-flex" *ngIf="serviceProducts">

  <div class="service-product-filters">

    <!-- store, if >1 -->
    <div *ngIf="availableStoreIds.length > 1" class="d-flex mb-1">
      <div class="btn-group btn-group-toggle">
        <label ngbButtonLabel class="p-1"
          *ngFor="let storeId of availableStoreIds"
          [ngClass]="{
            'btn-info': storeFilter[storeId],
            'btn-outline-secondary': !storeFilter[storeId]
          }">
          <input type="checkbox" ngbButton id="{{storeId}}"
            [(ngModel)]="storeFilter[storeId]"
            (change)="storeFilterChanged($event)"/>
          <span class="mx-1 mb-0">{{storeNameById[storeId]}}</span>
          <span class="badge badge-secondary p-1 ml-1 mb-0" *ngIf="storeFilter[storeId]"><i-bs name="x"></i-bs></span>
        </label>
      </div>
    </div>

    <!-- product -->
    <div *ngIf="store" class="d-flex mb-1">
      <p *ngIf="filteredProducts.length===0">{{'common.no_service_products'|translate}}</p>
      <div class="btn-group btn-group-toggle">
        <label ngbButtonLabel class="p-1"
          *ngFor="let product of filteredProducts"
          [ngClass]="{
            'btn-info': productFilter[product.id],
            'btn-outline-secondary': !productFilter[product.id]
          }">
          <input type="checkbox" ngbButton id="{{product.id}}"
            [(ngModel)]="productFilter[product.id]"
            (change)="productFilterChanged($event)"/>
          <span class="badge badge-light">{{product.code}}</span>
          <span class="mx-1" *ngIf="!compact">{{product.name}}</span>
          <span class="badge badge-secondary p-1 ml-1" *ngIf="!requireProductSelect && productFilter[product.id]"><i-bs name="x"></i-bs></span>
        </label>
      </div>
    </div>

    <!-- variations -->
    <div *ngIf="showVariations && product" class="mb-1">
      <div class="btn-group btn-group-toggle">
        <label ngbButtonLabel class="p-1"
          *ngFor="let variation of product.service_product_variations"
          [ngClass]="{
            'btn-info': variationFilter[variation.id],
            'btn-outline-secondary': !variationFilter[variation.id]
          }">
          <input type="checkbox" ngbButton id="{{variation.id}}"
            [(ngModel)]="variationFilter[variation.id]"
            (change)="variationFilterChanged($event)"/>
          <span class="badge badge-light">{{variation.code}}</span>
          <span class="ml-1" *ngIf="!compact">{{variation.name}}</span>
          <span class="badge badge-secondary p-1 ml-1" *ngIf="!requireVariationSelect && variationFilter[variation.id]"><i-bs name="x"></i-bs></span>
        </label>
      </div>
    </div>

    <!-- racks -->
    <div *ngIf="showRacks && product" class="mb-1">
      <div class="btn-group btn-group-toggle" style="display: inline;">
        <label ngbButtonLabel class="p-1"
          *ngFor="let rack of product.recks"
          [ngClass]="{
            'btn-info': rackFilter[rack.id],
            'btn-outline-secondary': !rackFilter[rack.id]
          }">
          <input type="checkbox" ngbButton id="{{rack.id}}"
            [(ngModel)]="rackFilter[rack.id]"
            (change)="rackFilterChanged($event)"/>
          <span class="badge badge-light">{{rack.name}}</span>
        </label>
      </div>
    </div>

  </div>

  <div class="sales-period-text-search ml-auto">
    <div class="btn-toolbar ml-auto" role="toolbar" *ngIf="enableSearch">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <div class="input-group-text py-1 px-2" id="btnGroupAddon">{{'common.search'|translate|titlecase}}</div>
        </div>
        <input type="text" class="form-control" [formControl]="filter" aria-describedby="btnGroupAddon"/>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary p-1" type="button" id="button-addon2" (click)="clearSearchFilterContent()"><i-bs name="x"></i-bs></button>
        </div>
      </div>
    </div>
  </div>

</div>