import { Component, OnInit } from '@angular/core'

import { ServiceProduct } from '@/domain/service-product'

@Component({
  selector: 'app-rack-list',
  templateUrl: './rack-list.component.html'
})
export class RackListComponent implements OnInit {

  selectedServiceProduct: ServiceProduct = null

  constructor() { }

  ngOnInit(): void {
  }

  selectProduct(product) {
    this.selectedServiceProduct = product
  }

  unselectProduct(product) {
    this.selectedServiceProduct = null
  }
}
