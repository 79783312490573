import { PlainDate } from '@/util/date-utils'
import { addDays } from '@/util/date-utils'

import { SalesPeriod } from '@/domain/sales-period'

import { FREE_DAY, BUSY_DAY, CLOSED_DAY } from '@/model/calendar'
import { RackTimeline } from '@/model/rack-timeline'

/**
 * A day in the life of a rack
 */
export class RackDay {
  public date: PlainDate
  public status: string         // free, busy
  public duration: number = 1   // if a day starts a period that lasts >1 days; used for colspan
  public hoverToDate: PlainDate // actual duration of a 'hover'
  public period: SalesPeriod = null
  public periodStatus: string = null
  public isFirstAvailableDate: boolean = false

  constructor(private _timeline: RackTimeline) {}

  private _hover = false

  get rackId(): number {
    return this._timeline.rackId
  }

  public hoverIfAvailable(duration: number) {
    this._timeline.hoverIfAvailable(this, duration)
  }

  public unhover2() {
    this._timeline.unhover()
  }

  /** Indicate how many actual days a hover spans, i.e. considering store closed days etc */
  public hoverDuration(duration: number) {
    this.hoverToDate = addDays(this.date, duration - 1)
  }

  public hover(): void {
    this._hover = true
  }

  public unhover(): void {
    this._hover = false
    this.hoverToDate = null
  }

  get free(): boolean {
    return this.status === FREE_DAY
  }

  get busy(): boolean {
    return this.status === BUSY_DAY
  }

  get closed(): boolean {
    return this.status === CLOSED_DAY
  }

  get weekend(): boolean {
    return this.date.isWeekend()
  }

  get hovering(): boolean {
    return this._hover
  }
}
