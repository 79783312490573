
import { Rack } from '@/domain/rack'
import { ServiceLevel } from '@/domain/service-level'
import { ServiceProductVariation } from '@/domain/service-product-variation'
import { ServiceType } from './service-type'
import { LocalDate } from '@js-joda/core'

export class ServiceProduct {

  id: number
  created_at?: string
  updated_at?: string

  code: string
  name: string
  description: string
  description_formatted: string
  readmore_url: string
  seller_reservations_enabled: boolean
  last_reservation_date: LocalDate

  service_level_id: number
  service_level?: ServiceLevel

  service_type_id: number
  service_type?: ServiceType

  service_product_variations: ServiceProductVariation[]
  recks: Rack[]

  public static from(json: any): ServiceProduct {
    const sp = Object.assign(new ServiceProduct(), json)
    if (json.service_product_variations) {
      sp.service_product_variations = json.service_product_variations.map(w => { return ServiceProductVariation.from(w) })
    }
    if (json.last_reservation_date) {
      sp.last_reservation_date = LocalDate.parse(json.last_reservation_date)
    }
    return sp
  }

  public static fromMany(jsons: any[]): ServiceProduct[] {
    return jsons.map(json => { return ServiceProduct.from(json) })
  }
}
