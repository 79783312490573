import { Component, OnInit, OnDestroy } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Location } from '@angular/common'
import { ActivatedRoute } from "@angular/router";
import { startOfToday, startOfTomorrow, isToday, isTomorrow, isMatch, parseISO, formatISO } from 'date-fns'
import { saveAs } from 'file-saver'
import { Subscription } from 'rxjs'

import { AuthenticationService } from '@/services/authentication.service'
import { CableService } from '@/services/cable.service'
import { SalesPeriodsService } from '@/services/sales-periods.service'
import { Store } from '@/domain/store';

@UntilDestroy()
@Component({
  selector: 'merchant-dashboard-view',
  templateUrl: 'merchant-dashboard-view.component.html'
  // styleUrls: [ 'merchant-dashboard-view.component.scss' ]
})
export class MerchantDashboardViewComponent implements OnInit, OnDestroy {

  salesSubscription: Subscription

  day1: Date = startOfToday()
  day2: Date = startOfTomorrow()

  storeSelection: Store = null

  constructor(
    public salesPeriodsService: SalesPeriodsService,
    public auth: AuthenticationService,
    private _route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() {
    // this.salesSubscription = this._cableService.sales.subscribe(sale => {
    //   console.log('received sale', sale)
    // })

    this._route.params.pipe(untilDestroyed(this)).subscribe(params => {
      if (params['dates']) {
        const dates = params['dates'].split(',')
        if (dates.length > 0) {
          if (dates[0] === 'today') {
            this.day1 = startOfToday()
          } else if (isMatch(dates[0], 'yyyy-MM-dd')) {
            this.day1 = parseISO(dates[0])
          }
        }
        if (dates.length > 1) {
          if (dates[1] === 'tomorrow') {
            this.day2 = startOfTomorrow()
          } else if (isMatch(dates[1], 'yyyy-MM-dd')) {
            this.day2 = parseISO(dates[1])
          }
        }
      }
      console.log('initializing dashboard')
      var storeId = this.loadStoreChoice()
      if (storeId && this.auth.currentUserValue.merchant) {
        let stores = this.auth.currentUserValue.merchant.stores
        this.storeSelection = stores.find(s => s.id == storeId)
      } else {
        this.storeSelection = null
      }
    })
  }

  ngOnDestroy() {
    // this.salesSubscription.unsubscribe()
  }

  day1Changed(date: Date): void {
    this.day1 = date
    this.updateLocation()
  }

  day2Changed(date: Date): void {
    this.day2 = date
    this.updateLocation()
  }

  getDailySalesReport(when: string): void {
    this.salesPeriodsService.getDailySalesReport(when).subscribe(
      blob => {
        saveAs(blob, `daily-sales.xlsx`)
      },
      error => {})
  }

  selectedStore(store: Store): void {
    this.storeSelection = store
    if (store) {
      this.saveStoreChoice(store.id)
    } else {
      this.clearStoreChoice()
    }
  }

  private loadStoreChoice(): number {
    var id = localStorage.getItem('dashboard.storeId')
    return id ? +id : null
  }

  private saveStoreChoice(id: number): void {
    localStorage.setItem('dashboard.storeId', ''+id)
  }

  private clearStoreChoice(): void {
    localStorage.removeItem('dashboard.storeId')
  }

  private updateLocation(): void {
    let second = 'tomorrow'
    if (!isTomorrow(this.day2)) {
      second = formatISO(this.day2, { representation: 'date' })
    }
    let first = 'today'
    if (!isToday(this.day1)) {
      first = formatISO(this.day1, { representation: 'date' })
    }
    const dateRoute = first + ',' + second
    const path = dateRoute === 'today,tomorrow' ? '' : dateRoute
    this.location.go('dashboard/' + path)
  }
}