import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UntilDestroy } from '@ngneat/until-destroy'

import { AuthenticationService } from '@/services/authentication.service'
import { SalesPeriodsService } from '@/services/sales-periods.service'

import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'cancelled-sales-periods',
  template: `
    <div class="mt-4">
      <sales-period-list [salesPeriods]="periods.cancelled$|async" [title]="title"></sales-period-list>
    </div>
  `
})
export class CancelledSalesPeriodsViewComponent implements OnInit {

  constructor(
    public periods: SalesPeriodsService,
    private _auth: AuthenticationService,
    private _translate: TranslateService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    if (this._auth.isSeller) {
      this._router.navigate(['/'])
      return
    }
  }

  get title(): string {
    return this._translate.instant('pricing.title.cancelled')
  }
}
