import { Component, OnInit } from '@angular/core';

import { ProductFeaturesService } from '@/services/product-features.service';

import { ProductSize } from '@/domain/product-size';

@Component({
  selector: 'app-size',
  templateUrl: './size.component.html'
})
export class ProductSizeComponent implements OnInit {

  constructor(private featureService: ProductFeaturesService) { }

  sizes: ProductSize[];

  ngOnInit(): void {
    this.getSizes();
  }

  getSizes(): void {
    this.featureService.getSizes()
      .subscribe(sizes => this.sizes = sizes)
  }
}
