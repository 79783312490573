<div class="container-fluid">
  <div class="card gutter-b">
    <div class="card-header">

      <div class="mb-4">
        <service-product-selector
          [(product)]="selectedProduct"
          [(variation)]="selectedVariation"
          [enableSearch]="false"
          [showRacks]="false"
          [requireProductSelect]="true"
          [requireVariationSelect]="true"
          (variationChange)="variationSelected($event)"
          >
        </service-product-selector>
      </div>

      <div class="d-flex">
        <h1>
          <sales-period-booking-header
            [title]="title"
            [product]="selectedProduct"
            [variation]="selectedVariation"
            ></sales-period-booking-header>
        </h1>
        <div class="ml-auto">
          <h2>
            <span *ngIf="paymentOnlineOnly"  class="badge badge-info p-2">{{'seller.booking.payment_online_only'|translate}}</span>
            <span *ngIf="paymentInStoreOnly" class="badge badge-info p-2">{{'seller.booking.payment_in_store_only'|translate}}</span>
            <span *ngIf="paymentEitherOr"    class="badge badge-info p-2">{{'seller.booking.payment_either_or'|translate}}</span>
          </h2>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div *ngIf="selectedProduct" class="d-flex mt-2">
            <h5 class="productDescription">
              <span class="collapse" id="productDescriptionText" [innerHTML]="selectedProduct.description_formatted"></span>

              <a class="collapsed mt-2" data-toggle="collapse" href="#productDescriptionText" aria-expanded="false" aria-controls="productDescriptionText">
                <span class="readmore"><i-bs name="chevronDoubleDown"></i-bs></span>
                <span class="readless"><i-bs name="chevronDoubleUp"></i-bs></span>
              </a>

            </h5>
          </div>
          <div *ngIf="selectedVariation" class="d-flex mt-2">
            <h5>
              <span [innerHTML]="selectedVariation.description_formatted"></span>
            </h5>
          </div>
        </div>
      </div>

    </div>

    <div class="card-body" *ngIf="selectedProduct">
      <div style="overflow-x: scroll">
        <div class="row">
          <div class="col-md-6">
            <div *ngIf="availableStartingDays.length == 0">
              <h3>{{'seller.booking.no_availability'|translate}}</h3>
            </div>
            <div *ngIf="availableStartingDays.length > 0">
              <mwl-calendar-month-view
                (beforeViewRender)="beforeMonthViewRender($event)"
                (eventClicked)="eventClicked($event)"
                [weekStartsOn]="weekStartsOn"
                [viewDate]="viewDate"
                [events]="availableStartingDays"
                [refresh]="refresh"
                [locale]="locale"
                [cellTemplate]="customCellTemplate123"
                ></mwl-calendar-month-view>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #customCellTemplate123 let-day="day">
  <div class="cal-month-day {{day.cssClass}}">

    <div class="p-1" style="width: 100%; background-color: #eee;">
      <span>
        {{day.date|date:'d.M.'}}
      </span>
    </div>

    <div *ngIf="day.events.length == 0">
      <span style="font-size: 75%; color: gray;"> </span>
    </div>

    <div *ngIf="day.events.length > 0">
      <a role="button" (click)="eventClicked(day)">
        <!--{{day.events[0].title}}-->
        <span class="btn btn-primary p-1 m-1">{{'common.reserve'|translate|uppercase}}</span>
      </a>
    </div>

  </div>
</ng-template>
