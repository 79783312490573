import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { add, startOfToday } from 'date-fns'
import { merge, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

import { isOnOrAfter, isOnOrBefore, PlainDate, localDateAsDate } from '@/util/date-utils'
import { domainMerge } from '@/util/domain-utils'

import { AuthenticationService } from '@/services/authentication.service'
import { MerchantsService } from '@/services/merchants.service'
import { PaymentCheckoutService } from '@/services/payment-checkout.service'
import { RacksService } from '@/services/racks.service'
import { SalesPeriodsService } from '@/services/sales-periods.service'

import { Merchant } from '@/domain/merchant'
import { Rack } from '@/domain/rack'
import { SalesPeriod } from '@/domain/sales-period'
import { ServiceProduct } from '@/domain/service-product'
import { ServiceProductVariation } from '@/domain/service-product-variation'
import { Store } from '@/domain/store'
import { LocalDate } from '@js-joda/core'

import { SelectedVariationAndDate } from '@/model/calendar'
import { CalendarViewModel } from '@/model/calendar-view-model'
import { RackDay } from '@/model/rack-day'

@UntilDestroy()
@Component({
  selector: 'sales-period-calendar',
  templateUrl: 'sales-period-calendar.component.html',
  styleUrls: [ 'sales-period-calendar.component.scss' ]
})
export class SalesPeriodCalendarComponent implements OnInit, OnChanges {

  /**
   * First date
   */
  from: Date

  /**
   * Last date, set from merchant settings
   */
  to: Date

  @Input()
  sellerReservationMode: boolean = false

  /**
   * Set to true to show the mousehover reservation gizmo and to get click events.
   */
  @Input()
  showSelector: boolean = false

  /**
   * Called when a date is clicked; use for making reservations at the selected time.
   */
  @Output()
  selectedForCreate = new EventEmitter<SelectedVariationAndDate>()

  /**
   * Called when a sales period is clicked; use for editing the period or something.
   */
  @Output()
  selectedForEdit = new EventEmitter<SalesPeriod>()

  salesPeriods: SalesPeriod[] = []

  selectedStore: Store = null
  selectedProduct: ServiceProduct = null
  selectedVariation: ServiceProductVariation = null

  preselectStoreId: number = null
  preselectProductId: number = null
  preselectVariationId: number = null

  calendarViewModel: CalendarViewModel = null

  days = []
  dayRange: PlainDate[]
  timelinesByRackId = {}

  hoverRack: Rack = null
  hoverDay: Date = null
  hoverDayRangeEnd: Date = null

  private _merchant: Merchant
  private _racks: Rack[] = null

  constructor(
    public auth: AuthenticationService,
    public paymentCheckoutService: PaymentCheckoutService,
    private _salesPeriodsService: SalesPeriodsService,
    private _racksService: RacksService,
    private _merchantsService: MerchantsService,
    private _router: Router
  ) {
    this.from = startOfToday()
    this._setCalendarFurthestDate()
  }

  ngOnChanges(): void { }

  ngOnInit(): void {
    this._initFilterSelections()
    this._merchantsService.currentMerchant.pipe(untilDestroyed(this)).subscribe(merchant => {
      if (!merchant) {
        return
      }
      if (!merchant.seller_reservations_enabled && this.auth.isSeller) {
        this._router.navigate(['/'])
        return
      }
      // var changed = this._merchant && this._merchant.last_calendar_date_rolling != merchant.last_calendar_date_rolling
      this._merchant = merchant

      combineLatest([
        this._racksService.racks,
        merge(this._salesPeriodsService.current$, this._salesPeriodsService.future$)
      ])
      .pipe(map(([ racks, periods ]) => ({ racks, periods })))
      .subscribe(({ racks, periods }) => {
        if (racks && racks.length > 0) {
          this._racks = racks
        }
        if (periods && periods.length > 0) {
          const filtered = periods.filter(period => { return (this.auth.isSeller && period.status != 'status_cancelled') || period.status === 'status_reserved' || period.status === 'status_preliminary' })
          this.salesPeriods = domainMerge(this.salesPeriods, filtered)
        }
        if (this._racks && this._racks.length > 0) {
          this._redrawCalendar()
        }
      })

      // if (changed) {
      //   this._redrawCalendar()
      // }
    })
  }

  private _initFilterSelections() {
    let storeId = localStorage.getItem('calendar.store_id')
    if (storeId && !isNaN(+storeId)) {
      this.preselectStoreId = +storeId
    }
    let productId = localStorage.getItem('calendar.product_id')
    if (productId && !isNaN(+productId)) {
      this.preselectProductId = +productId
    }
    let variationId = localStorage.getItem('calendar.variation_id')
    if (variationId && !isNaN(+variationId)) {
      this.preselectVariationId = +variationId
    }
  }

  public selectedStoreChanged(store: Store): void {
    if (store) {
      localStorage.setItem('calendar.store_id', store.id.toString())
    } else {
      localStorage.removeItem('calendar.store_id')
    }
  }

  public selectedProductChanged(product: ServiceProduct): void {
    if (product) {
      localStorage.setItem('calendar.product_id', product.id.toString())
    } else {
      localStorage.removeItem('calendar.product_id')
    }
  }

  public selectedVariationChanged(variation: ServiceProductVariation): void {
    if (variation) {
      localStorage.setItem('calendar.variation_id', variation.id.toString())
    } else {
      localStorage.removeItem('calendar.variation_id')
    }
  }

  private _setCalendarFurthestDate(date?: LocalDate): void {
    const today = LocalDate.now()
    if (date && (date.equals(today) || date.isAfter(today))) {
      this.to = localDateAsDate(date)
    } else {
      this.to = add(startOfToday(), { days: 89 })
    }
  }

  get paymentInStoreOnly(): boolean {
    return this.selectedVariation && (
      !this.paymentOnlineOnly && !this._merchant.seller_reservation_checkout_enabled ||
      !(this.selectedVariation.price > 0))
  }

  get paymentOnlineOnly(): boolean {
    return this.selectedVariation && (
      (this._merchant && this._merchant.seller_reservation_checkout_required) ||
      !(this.selectedVariation.price_in_store > 0))
  }

  get paymentEitherOr(): boolean {
    return this.selectedVariation && (
      !(this._merchant && this._merchant.seller_reservation_checkout_required) &&
      this._merchant.seller_reservation_checkout_enabled &&
      (this.selectedVariation.price > 0) && (this.selectedVariation.price_in_store > 0))
  }

  public filteredRacks(product: ServiceProduct): Rack[] {
    let racks = product.recks.filter(rack => this.selectedStore == null || rack.store_id == this.selectedStore.id)
    // TODO sort
    return racks
  }

  public displayFilteredPeriodForMerchant(period: SalesPeriod): boolean {
    return this.auth.isMerchant && (!this.selectedVariation || period.service_product_variation.id == this.selectedVariation.id)
  }

  public styleForSalesPeriodAction(rackDay: RackDay): any {
    return rackDay.period.custom_period_status
      ? { 'color': 'black' }
      : {}
  }

  public styleForPeriodBadge(rackDay: RackDay): any {
    return rackDay.period.custom_period_status
      ? {
          'background-color': rackDay.period.custom_period_status.color,
          'color': 'black',
          'font-weight': 'initial'
        }
      : { }
  }

  public classForPeriodBadge(rackDay: RackDay): string {
    // NB order of these ifs matters
    if (this.isSellerOwnPeriod(rackDay.period)) {
      return 'badge-info' // seller sees own periods
    }
    if (!this.displayFilteredPeriodForMerchant(rackDay.period)) {
      return 'b-period-noshow'
    }
    switch (rackDay.periodStatus) {
      case 'current':            return 'badge-primary'
      case 'future':             return 'badge-info'
      case 'status_preliminary': return 'badge-warning'
    }
    return ''
  }

  public isSellerOwnPeriod(period: SalesPeriod) {
    return period && period.seller_id == this.auth.sellerId
  }

  rackDayClicked(rackDay: RackDay): void {
    if (rackDay.hovering) {
      const rack = this._racks.find(r => { return r.id === rackDay.rackId })
      const selection = new SelectedVariationAndDate(this.selectedProduct, this.selectedVariation, rack, rackDay.date, rackDay.hoverToDate)
      this.selectedForCreate.emit(selection)
    }
  }

  editSalesPeriod(period: SalesPeriod): void {
    this.selectedForEdit.emit(period)
  }

  mouseover2(rackDay: RackDay): void {
    if (!this.selectedVariation) {
      return
    }
    let duration = this.selectedVariation.duration
    rackDay.hoverIfAvailable(duration)
  }

  mouseout2(rackDay: RackDay): void {
    rackDay.unhover2()
  }

  isHovering(rack: Rack, day: Date): boolean {
    return rack == this.hoverRack
      && this.hoverDay && this.hoverDayRangeEnd
      && (isOnOrAfter(day, this.hoverDay) && isOnOrBefore(day, this.hoverDayRangeEnd))
  }

  closedOnDay(store: Store, date: PlainDate): boolean {
    return store ? store.isClosedOn(date) : false
  }

  private _redrawCalendar() {
    this._setCalendarFurthestDate(this._merchant && this._merchant.reservationCalendarLastDate())
    this.dayRange = PlainDate.generateRange(PlainDate.ofDate(this.from), PlainDate.ofDate(this.to))
    this.calendarViewModel = new CalendarViewModel(this.salesPeriods, this.dayRange, this._merchant, this._racks)
  }
}
