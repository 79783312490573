import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { isPast, isFuture, differenceInCalendarDays, startOfDay, endOfDay } from 'date-fns'

import { AuthenticationService } from '@/services/authentication.service'

import { RackTimeSlot } from '@/domain/rack-time-slot'
import { SalesPeriod } from '@/domain/sales-period'
import { FormBuilder } from '@angular/forms'

export const FUTURE = "sales_period_status.future"
export const CURRENT = "sales_period_status.current"
export const PAST = "sales_period_status.past"
export const CANCELLED = "sales_period_status.status_cancelled"
export const PRELIMINARY = "sales_period_status.status_preliminary"
export const INITIAL = "sales_period_status.status_reserved"
export const PENDING_APPROVAL = "sales_period_status.status_pending_approval"
export const REJECTED = "sales_period_status.status_rejected"

@Component({
  selector: 'sales-period-header',
  templateUrl: 'sales-period-header.component.html',
  styleUrls: [ 'sales-period-header.component.scss' ]
})
export class SalesPeriodHeaderComponent implements OnInit, OnChanges {

  @Input()
  period: SalesPeriod = null

  @Input()
  showNotes: boolean = false

  statusCode: string
  statusIndicatorClass: string

  notesFormGroup = null

  constructor(
    public auth: AuthenticationService,
    private _fb: FormBuilder
  ) {
    this.notesFormGroup = this._fb.group({
      'seller_note': [''],
      'internal_note': ['']
    })
    this.notesFormGroup.disable()
  }

  ngOnInit(): void {
    this.updateFromPeriod()
  }

  ngOnChanges(): void {
    // console.log('sales-period-header ngOnChanges', this.period.status, this.period)
    this.updateFromPeriod()
  }

  duration(slot: RackTimeSlot): number {
    return differenceInCalendarDays(new Date(slot.to), new Date(slot.from)) + 1
  }

  private updateFromPeriod(): void {
    const from = new Date(this.period.rack_time_slot.from)
    const to = new Date(this.period.rack_time_slot.to)
    if (this.period.status === 'status_preliminary') {
      this.statusCode = PRELIMINARY
      this.statusIndicatorClass = 'badge badge-info'
    } else if (this.period.status == 'status_pending_approval') {
      this.statusCode = PENDING_APPROVAL
      this.statusIndicatorClass = 'badge badge-info'
    } else if (this.period.status == 'status_rejected') {
      this.statusCode = REJECTED
      this.statusIndicatorClass = 'badge badge-dark'
    } else if (this.period.status === 'status_cancelled') {
      this.statusCode = CANCELLED
      this.statusIndicatorClass = 'badge badge-warning'
    } else if (isFuture(startOfDay(from))) {
      this.statusCode = FUTURE
      this.statusIndicatorClass = 'badge badge-secondary'
    } else if (isPast(endOfDay(to))) {
      this.statusCode = PAST
      this.statusIndicatorClass = 'badge badge-dark'
    } else {
      this.statusCode = CURRENT
      this.statusIndicatorClass = 'badge badge-success'
    } 
    
    this.notesFormGroup.setValue({
      seller_note: this.period.seller_note,
      internal_note: this.period.internal_note || '',
    })
  }

  currency(value: any): string {
    if (!value) {
      return ''
    }
    let displayValue = value % 1 === 0 ? parseFloat(value).toFixed(0) : parseFloat(value).toFixed(2)
    return displayValue + "€"
  }

}