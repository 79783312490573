import { Component, OnInit, Input, Output, EventEmitter, OnChanges, QueryList, ViewChildren } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { isPast, isFuture, differenceInCalendarDays, startOfDay, endOfDay } from 'date-fns'
import { debounceTime, switchMap } from 'rxjs/operators'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { SortEvent, NgbdSortableHeader, State } from '@/ui/common/util/ngbd-things'
import { classForPaymentStatus } from '@/util/sales-period-utils'

import { AuthenticationService } from '@/services/authentication.service'

import { RackTimeSlot } from '@/domain/rack-time-slot'
import { SalesPeriod } from '@/domain/sales-period'
import { Seller } from '@/domain/seller'
import { Store } from '@/domain/store'
import { SalesPeriodsService } from '@/services/sales-periods.service'

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0

@UntilDestroy()
@Component({
  selector: 'sales-period-table',
  templateUrl: 'sales-period-table.component.html'
})
export class SalesPeriodTableComponent implements OnInit, OnChanges {

  @Input()
  seller: Seller = null

  @Input()
  store: Store = null

  @Input()
  salesPeriods: SalesPeriod[] = null

  @Input()
  mode: string = 'normal' // eller 'compact'

  @Input()
  search: string = ''

  @Input()
  linkToPeriod: boolean = false

  @Input()
  approvalActions: boolean = false

  /** List of columns to hide */
  @Input()
  hide: string = null

  @Output()
  select = new EventEmitter<SalesPeriod>()

  @Output()
  deselect = new EventEmitter<SalesPeriod>()

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>

  salesPeriods$: Observable<SalesPeriod[]>
  private _salesPeriods$ = new BehaviorSubject<SalesPeriod[]>([])
  private _search$ = new Subject<void>()

  hideColumns: String[] = []

  private _hasManyStores: boolean = false

  private _state: State = {
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  }

  selectedSalesPeriod: SalesPeriod = null

  constructor(
    public auth: AuthenticationService,
    public salesPeriodsService: SalesPeriodsService
  ) {
    this.auth.currentUser.subscribe(user => {
      // user is null on logout
      this._hasManyStores = user && user.merchant && user.merchant.stores && user.merchant.stores.length > 1
    })
  }

  ngOnInit(): void {
    this.hideColumns = this.hide ? this.hide.split(',') : []
    this.initializeFiltering()
  }

  ngOnChanges(): void {
    this.hideColumns = this.hide ? this.hide.split(',') : []
    this._state.searchTerm = this.search
    this._search$.next()
  }

  hasCustomPeriodStatuses(): boolean {
    return this.auth.currentUserValue && this.auth.currentUserValue.merchant.hasCustomPeriodStatuses()
  }

  public show(name: String): boolean {
    return !this.hideColumns.find(c => c == name)
  }

  get multistore(): boolean {
    return this._hasManyStores
  }

  get normal(): boolean {
    return this.mode === 'normal'
  }

  get compact(): boolean {
    return this.mode === 'compact'
  }

  approve(period: SalesPeriod): void {
    this.salesPeriodsService.approveReservation(period.id).subscribe(
      success => {},
      failure => {}
    )
  }

  reject(period: SalesPeriod): void {
    this.salesPeriodsService.rejectReservation(period.id).subscribe(
      success => {},
      failure => {}
    )
  }

  duration(slot: RackTimeSlot): number {
    return differenceInCalendarDays(new Date(slot.to), new Date(slot.from)) + 1
  }

  selectPeriod(salesPeriod: SalesPeriod) {
    this.selectedSalesPeriod = salesPeriod
    this.select.emit(salesPeriod)
  }

  deselectPeriod() {
    let period = this.selectedSalesPeriod
    this.selectedSalesPeriod = null
    this.deselect.emit(period)
  }


  onSort({column, direction}: SortEvent) {
    // reset other headers; TODO enable secondary ordering?
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = ''
      }
    })
    this._state.sortColumn = column
    this._state.sortDirection = direction
    this._search$.next()
  }

  currency(value: any): string {
    return value ? (parseFloat(value).toFixed(0) + "€") : ''
  }

  percent(value: number): string {
    return value ? value + "%" : ''
  }

  classForPaymentStatus(period: SalesPeriod) {
    return classForPaymentStatus(period)
  }

  // private

  private fieldForSortColumn(column: string, period: SalesPeriod): string | number {
    switch (column) {
      case 'id': return parseInt(''+period.id)
      case 'service_product_variation_name': return period.service_product_variation.code
      case 'rack_name': return period.rack_time_slot.reck.name
      case 'from': return period.rack_time_slot.from.getTime()
      case 'to': return period.rack_time_slot.to.getTime()
      case 'price': return parseFloat(""+period.price)
      case 'commission': return parseInt(""+period.commission)
      case 'capacity': return parseInt(""+period.capacity)
      case 'product_count': return period.products ? period.products.length : 0
      case 'product_totalvalue': return parseFloat(""+period.value_total)
      case 'seller_name': return period.seller.name
      default: return period.id
    }
  }

  private initializeFiltering() {
    this._search$.pipe(
      debounceTime(200),
      switchMap(() => this._search())
    ).pipe(untilDestroyed(this)).subscribe(result => {
      this._salesPeriods$.next(result)
    })
    this.salesPeriods$ = this._salesPeriods$.asObservable()
    this._search$.next();
  }

  private _search(): Observable<SalesPeriod[]> {
    const { sortColumn, sortDirection, searchTerm } = this._state;

    let sourcePeriods = this.salesPeriods || []
    let sortedPeriods = this.sort2(sourcePeriods, sortColumn, sortDirection)
    let searchedPeriods = sortedPeriods.filter(period => { return this.matches(period, searchTerm) })
    return of(searchedPeriods)
  }

  private sort2(periods: SalesPeriod[], column: string, direction: string): SalesPeriod[] {
    if (direction === '') {
      return periods
    }
    else {
      return [...periods].sort((a, b) => {
        const aa = this.fieldForSortColumn(column, a)
        const bb = this.fieldForSortColumn(column, b)
        const res = compare(aa, bb)
        return direction === 'asc' ? res : -res
      })
    }
  }

  private matches(period: SalesPeriod, term: string): boolean {
    const t = term.trim().toLowerCase()
    const m = period.id.toString().includes(t)
      || period.service_product.code.toLowerCase().includes(t)
      || period.service_product_variation.code.toLowerCase().includes(t)
      || period.rack_time_slot.reck.name.toLowerCase().includes(t)
      || (period.price && period.price.toString().includes(t))
      || (period.commission && period.commission.toString().includes(t))
      || (period.capacity && period.capacity.toString().includes(t))
      || (period.seller && period.seller.name && period.seller.name.toLowerCase().includes(t))
      || (period.internal_note && period.internal_note.toLowerCase().includes(t))
      || (period.seller_note && period.seller_note.toLowerCase().includes(t))
    return m
  }
}