import { Component, OnInit } from '@angular/core';

import { ProductFeaturesService } from '@/services/product-features.service';

import { ProductType } from '@/domain/product-type';

@Component({
  selector: 'app-product-type',
  templateUrl: './type.component.html'
})
export class ProductTypeComponent implements OnInit {

  constructor(private featureService: ProductFeaturesService) { }

  types: ProductType[];

  ngOnInit(): void {
    this.getTypes();
  }

  getTypes(): void {
    this.featureService.getTypes()
      .subscribe(types => this.types = types)
  }

}
