import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core'
import { isPast, isFuture, startOfDay, endOfDay } from 'date-fns'

import { SalesPeriod } from '@/domain/sales-period'
import { AuthenticationService } from '@/services/authentication.service'


export const FUTURE = "sales_period_status.future"
export const CURRENT = "sales_period_status.current"
export const PAST = "sales_period_status.past"
export const CANCELLED = "sales_period_status.status_cancelled"
export const PRELIMINARY = "sales_period_status.status_preliminary"
export const INITIAL = "sales_period_status.status_reserved"

@Component({
  selector: 'sales-period-status-badge',
  template: `
    <div *ngIf="period" style="white-space: nowrap;">
      <span style="display: inline-block;" [ngClass]="statusIndicatorClass">{{statusCode|translate|uppercase}}</span>
      <span class="badge ml-2" style="display: inline-block;" *ngIf="includeSettlementStatus && auth.isMerchant && period.settlement_status !== 'settlement_status_init'" [ngClass]="{'badge-success':period.settlement_status==='settlement_status_settled','badge-warning':period.settlement_status==='settlement_status_unsettled'}">{{'settlement_status.'+period.settlement_status|translate|uppercase}}</span>
    </div>
  `
})
export class SalesPeriodStatusBadgeComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  period: SalesPeriod

  @Input()
  includeSettlementStatus: boolean = false

  statusCode: string
  statusIndicatorClass: string

  constructor(public auth: AuthenticationService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngOnChanges(): void {
    this._update()
  }

  private _update(): void {
    if (!this.period) {
      this.statusCode = null
      this.statusIndicatorClass = null
      return
    }
    const from = new Date(this.period.rack_time_slot.from)
    const to = new Date(this.period.rack_time_slot.to)
    if (this.period.status === 'status_preliminary') {
      this.statusCode = PRELIMINARY
      this.statusIndicatorClass = 'badge badge-info'
    } else if (this.period.status === 'status_cancelled') {
      this.statusCode = CANCELLED
      this.statusIndicatorClass = 'badge badge-warning'
    } else if (isFuture(startOfDay(from))) {
      this.statusCode = FUTURE
      this.statusIndicatorClass = 'badge badge-secondary'
    } else if (isPast(endOfDay(to))) {
      this.statusCode = PAST
      this.statusIndicatorClass = 'badge badge-dark'
    } else {
      this.statusCode = CURRENT
      this.statusIndicatorClass = 'badge badge-success'
    }
  }
}