import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core'

import { AuthenticationService } from '@/services/authentication.service'

import { Store } from '@/domain/store'

@Component({
  selector: 'store-selector',
  templateUrl: './store-selector.component.html'
})
export class StoreSelectorComponent implements OnInit, OnDestroy {

  @Input()
  initialStore: Store = null

  @Output()
  selectedStore = new EventEmitter<Store>()

  selected: Store = null
  stores: Store[] = null
  storeChoice: {}

  constructor(
    public auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.auth.currentUser.subscribe(user => {
      if (!user) {
        this.stores = []
      } else {
        this.stores = user.merchant.stores
      }
      if (this.initialStore) {
        this._init(this.initialStore.id)
      } else {
        this._refresh()
      }
    })
  }

  ngOnDestroy() {
  }

  classForStore(store: Store): any {
    return store && this.selected && store.id == this.selected.id ? 'btn-info' : 'btn-outline-secondary'
  }

  toggleStore(storeId: number) {
    if (this.selected && storeId == this.selected.id) {
      this.unselect()
    } else {
      this.select(storeId)
    }
  }

  select(storeId: number) {
    this.selected = this.stores.find(s => s.id == storeId)
    this._refresh()
    this.selectedStore.emit(this.selected)
  }

  unselect() {
    this.selected = null
    this._refresh()
    this.selectedStore.emit(null)
  }

  private _init(storeId: number) {
    this.select(storeId)
  }

  private _refresh(): void {
    this.storeChoice = {}
    this.stores.forEach(s => { this.storeChoice[s.id] = this.selected && this.selected.id == s.id })
  }
}
