import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { ApplicationUpdateService } from '@/services/application-update.service'
import { AuthenticationService } from '@/services/authentication.service';
import { User } from '@/domain/user';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html' 
})
export class AppComponent {
  currentUser: User;

  whom = { value: 'world' }

  constructor(
    private translate: TranslateService,
    private appUpdate: ApplicationUpdateService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    appUpdate.init()

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fi');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('fi');

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
