import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'
import { E02001, E02002, E02003 } from '@/common/error-codes'

@Component({ templateUrl: 'confirm.component.html' })
export class ConfirmComponent implements OnInit {

  confirming: boolean = false
  success: boolean = false
  failure: boolean = false
  token: string = null

  alreadyConfirmed: boolean = false
  invalidToken: boolean = false
  confirmationTimeout: boolean = false
  reconfirmationSent: boolean = false

  merchantHostname: string = null

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthenticationService,
    private _alertService: AlertService
  ) {
    // redirect to home if already logged in
    if (this._auth.currentUserValue) {
        this._router.navigate(['/']);
    }
  }

  ngOnInit() {
    let params = this._route.snapshot.queryParams
    if (params['token']) {
      this.confirming = true
      this.token = params['token']

      this._auth.confirm(this.token).subscribe(
        data => {
          this.confirming = false
          this.success = true
          this.merchantHostname = data.merchant.hostname
        },
        error => {
          this.confirming = false
          if (error.error_code === E02001.code) {
            this.confirmationTimeout = true
          } else if (error.error_code === E02002.code) {
            this.invalidToken = true
          } else if (error.error_code === E02003.code) {
            this.alreadyConfirmed = true
          } else {
            this._alertService.error(error.message)
          }
        }
      )
    }
  }

  reconfirm(): void {
    this.confirmationTimeout = false
    this._auth.reconfirm(this.token).subscribe(
      ok => {
        this.reconfirmationSent = true
      }
    )
  }

  hostnameLink(): string {
    return 'https://' + this.merchantHostname
  }
}