<div class="container-fluid" style="margin-top: 60px;">
  <div class="row mt-4">
    <div class="col-sm">
      <div class="card gutter-b">
        <div class="card-header">

          <div *ngIf="seller" class="d-flex justify-content-start mb-4">
            <div class="mr-auto">
              <h1 style="white-space: nowrap;">
                <span class="">{{seller.name}}</span>
                <span class="badge badge-light p-2 ml-2">{{seller.id}}</span>
                <span class="ml-2">&bull;</span>
                {{(periods$|async).length}} {{'common.sales_periods_a'|translate}}
              </h1>
              <h2>
                {{'common.email'|translate|titlecase}} <span class="badge badge-light p-2 mr-2">{{seller.user.email}}</span>
                <span class="ml-2">&bull;</span>
                {{'common.phone'|translate|titlecase}}
                  <span *ngIf="seller.phone" class="badge badge-light p-2">{{seller.phone}}</span>
                  <span *ngIf="!seller.phone" class="badge badge-warning p-2">{{'common.missing'|translate|titlecase}}</span>
              </h2>
              <h3>
                {{'common.iban_short'|translate}}
                  <span *ngIf="seller.iban" class="badge badge-light p-2">{{seller.iban}}</span>
                  <span *ngIf="!seller.iban" class="badge badge-warning p-2">{{'common.missing'|translate|titlecase}}</span>
                <span class="ml-2">&bull;</span>
                {{'common.marketing'|translate|titlecase}} <span class="badge badge-light p-2 mr-2">{{(seller.accept_marketing?'common.yes':'common.no')|translate|titlecase}}</span>
              </h3>
            </div>
            <div style="text-align: right;">
              <h1 *ngIf="totalSales">
                {{'common.sales'|translate}} <span class="badge badge-success p-2">{{totalSales|currency}}</span>
              </h1>
              <button id="createButton" *ngIf="false" class="btn btn-primary ml-auto">{{'merchant.salesperiod.create_new'|translate|uppercase}}</button>
            </div>
          </div>

        </div>
        <div class="card-body">
          <sales-period-table
            [seller]="seller"
            [salesPeriods]="periods$|async"
            [linkToPeriod]="true">
          </sales-period-table>
        </div>
      </div>
    </div>
  </div>
</div>