<div class="container-fluid" style="margin-top: 60px;">

  <div class="row">
    <div class="col">

      <div class="card gutter-b">
        <div class="card-header">
          <h1 class="mb-5">{{'merchant.sellers.title'|translate}}
            <span *ngIf="(sellers$ | async) as sellers">
              &bull; {{sellers.length}}
            </span>
          </h1>
          <div class="d-flex">
            <button class="btn btn-primary" (click)="create(editSellerModal)">{{'common.add_new'|translate|uppercase}}</button>
            <div class="btn-toolbar ml-auto" role="toolbar" *ngIf="(sellers$ | async) as sellers">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text" id="btnGroupAddon">{{'common.search'|translate|titlecase}}</div>
                </div>
                <input type="text" class="form-control" [formControl]="filter" aria-describedby="btnGroupAddon" autofocus/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="clearFilter()"><i-bs name="x"></i-bs></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">

          <ng-template #loadingSellers>
            <p *ngIf="loading">{{'common.loading'|translate}} ...</p>
            <p *ngIf="!loading">{{'common.no_sellers'|translate}}</p>
          </ng-template>

          <table class="table table-sm table-hover" *ngIf="(sellers$ | async) as sellers; else loadingSellers">
            <thead>
              <tr>
                <th scope="col" sortable="id" (sort)="onSort($event)">{{'common.number'|translate|titlecase}}</th>
                <th scope="col" sortable="name" (sort)="onSort($event)">{{'common.name'|translate|titlecase}}</th>
                <th scope="col" sortable="email" (sort)="onSort($event)">{{'common.email_account'|translate|titlecase}}</th>
                <th scope="col">{{'common.phone'|translate|titlecase}}</th>
                <th scope="col">{{'common.marketing'|translate|titlecase}}</th>
                <th scope="col">{{'common.seller_reservations_enabled'|translate|titlecase}}</th>
                <th scope="col">{{'common.iban_short'|translate|uppercase}}</th>
                <th scope="col" sortable="period_count" (sort)="onSort($event)">{{'common.sales_periods'|translate|titlecase}}</th>
                <th scope="col"><!-- actions --></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let seller of sellers$ | async" [ngClass]="{'table-success': seller.recentlyModified}">
                <!-- <th scope="row">{{seller.id}}</span></th> -->
                <!-- <th scope="row"><span class="badge badge-light"><ngb-highlight [result]="seller.idString()" [term]="filter.value"></ngb-highlight></span></th> -->
                <th scope="row"><ngb-highlight [result]="seller.idString()" [term]="filter.value" class="badge badge-light"></ngb-highlight></th>
                <td><ngb-highlight [result]="seller.name" [term]="filter.value"></ngb-highlight></td>
                <td><ngb-highlight [result]="seller.user.email" [term]="filter.value"></ngb-highlight></td>
                <td><ngb-highlight [result]="seller.phone" [term]="filter.value"></ngb-highlight></td>
                <td><input type="checkbox" [checked]="seller.accept_marketing" [disabled]="true"/></td>
                <td><input type="checkbox" [checked]="seller.seller_reservations_enabled" [disabled]="true"/></td>
                <td><ngb-highlight [result]="seller.iban|iban" [term]="filter.value"></ngb-highlight></td>

                <td>{{seller.sales_periods_count}}</td>
                <td>
                  <div class="d-flex">
                    <button class="btn btn-sm btn-primary p-1 m-1" (click)="edit(seller, editSellerModal)">{{'common.edit'|translate|uppercase}}</button>
                    <a role="button" class="btn btn-primary px-1 py-0 ml-1" routerLink="/seller/{{seller.id}}"><i-bs name="list" width="12" height="12"></i-bs></a>
                    <a role="button" class="btn btn-primary px-1 py-0 ml-0" routerLink="/seller/{{seller.id}}" target="_blank"><i-bs name="boxArrowUpRight" width="12" height="12"></i-bs></a>
                    <!-- <button class="btn btn-sm btn-outline-danger p-1 m-1"><i-bs name="x"></i-bs></button> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div><!-- card body -->
      </div><!-- card -->

    </div><!-- col -->
  </div><!-- row -->

</div><!-- container-fluid -->

<ng-template #editSellerModal let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <span *ngIf="!editingSellerId">{{'merchant.sellers.add_new'|translate}}</span>
      <span *ngIf="editingSellerId">{{'merchant.sellers.edit'|translate}} <span class="badge badge-light">{{editingSellerId}}</span></span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"><span aria-hidden="true">&times;</span></button>
  </div>

  <form [formGroup]="sellerForm" (ngSubmit)="save()">
    <div class="modal-body">

      <alert></alert>

      <div class="form-group">
        <label for="name">{{'common.name'|translate|titlecase}}</label>
        <input type="text" formControlName="name" class="form-control" id="name" name="name" autofocus/>
        <div [hidden]="sellerForm.controls.name.valid || sellerForm.controls.name.pristine"><span class="text-danger">{{'validation.name_req'|translate}}</span></div>
      </div>
      <div class="form-group">
        <label for="email">{{'common.email'|translate|titlecase}}</label>
        <input type="text" formControlName="user_email" class="form-control" id="email" name="email"/>
        <div [hidden]="sellerForm.controls.user_email.valid || sellerForm.controls.user_email.pristine"><span class="text-danger">{{'validation.acct_email_req'|translate}}</span></div>
      </div>
      <div class="form-group">
        <label for="name">{{'common.phone'|translate|titlecase}}</label>
        <input type="text" formControlName="phone" class="form-control" id="phone" name="phone"/>
      </div>
      <div class="form-group">
        <label for="accept_marketing">{{'common.marketing'|translate|titlecase}}</label>
        <div>
          <input #marketingCheckBox type="checkbox" formControlName="accept_marketing"/>
        </div>
      </div>
      <div class="form-group">
        <label for="seller_reservations_enabled">{{'common.seller_reservations_enabled'|translate|titlecase}}</label>
        <div>
          <input #reservationsEnabledCheckBox type="checkbox" formControlName="seller_reservations_enabled"/>
        </div>
      </div>
      <div class="form-group">
        <label for="iban">{{'common.iban'|translate}}</label>
        <input type="text" formControlName="iban" class="form-control" id="iban" name="iban"/>
      </div>
      <div class="form-group">
        <label for="name">{{'merchant.sellers.password_if_not_already_seller'|translate}}</label>
        <input type="text" formControlName="user_password" class="form-control monospace" id="password" name="password"/>
        <span class="text-danger" *ngIf="isCreating">{{'merchant.sellers.write_down_passwd'|translate}}</span>
      </div>
    </div><!-- modal-body -->

    <div class="modal-footer">
      <input type="button" class="btn btn-secondary" (click)="modal.close()" value="{{'common.cancel'|translate|uppercase}}"/>
      <input type="submit" class="btn btn-primary" value="{{'common.save'|translate|uppercase}}"/>
    </div>

  </form>
</ng-template>
