// import { Deserializable } from './deserializable'

import { RackClosedDay } from '@/domain/rack-closed-day'
import { SalesPeriod } from '@/domain/sales-period'
import { PlainDate } from '@/util/date-utils'

export class Rack {

  id: number
  name: string
  is_virtual: boolean
  service_product_id: number

  store_id: number
  store_name: string

  created_at?: string
  updated_at?: string

  salesPeriods: SalesPeriod[]
  rack_closed_days: RackClosedDay[]

  public static from(json: any): Rack {
    const rack = Object.assign(new Rack(), json)
    if (json.rack_closed_days) {
      rack.rack_closed_days = json.rack_closed_days.map((d: any) => { return RackClosedDay.from(d) })
    }
    return rack
  }

  public isClosedOn(plainDate: PlainDate): boolean {
    if (!plainDate) {
      return false
    }
    let isClosed = false
    let localDate = plainDate.asLocalDate()
    this.rack_closed_days.forEach(d => {
      isClosed = isClosed || d.date.equals(localDate)
    })
    return isClosed
  }

  // constructor(
  //   public id: number,
  //   public name: string,
  //   public is_virtual: boolean,

  //   public created_at?: string,
  //   public updated_at?: string
  // ) { }

  // getSalesPeriodStartingOn(date: Date): SalesPeriod {
  //   // for (let period of self.salesPeriods) {
  //   //   if (this.sameDay(date, period.from)) {
  //   //     return period
  //   //   }
  //   // }
  //   return null
  // }

  // private sameDay(d1: Date, d2: Date) {
  //   return d1.getFullYear() === d2.getFullYear() &&
  //     d1.getMonth() === d2.getMonth() &&
  //     d1.getDate() === d2.getDate();
  // }

  // deserialize(input: any) {
  //   Object.assign(this, input);
  //   return this;
  // }
}
