import { BehaviorSubject, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import * as api from '@/config/api-config.json'

import { AuthenticationService } from '@/services/authentication.service'

import { ServiceLevel } from '@/domain/service-level'
import { ServiceType  } from '@/domain/service-type'
import { Rack } from '@/domain/rack'

/**
 * Storage service for Service Products, Service Levels, Service Types, and Racks.
 * It seems convenient and reasonable to bundle the interface to those entites
 * into this one storage service.
 * 
 */
@Injectable({ providedIn: 'root' })
export class ServiceProductsStorage {

  private _racks: Rack[]
  private _racksSubject: BehaviorSubject<Rack[]>

  private _serviceLevels: ServiceLevel[]
  private _serviceLevelsSubject: BehaviorSubject<ServiceLevel[]>

  private _serviceTypes: ServiceType[]
  private _serviceTypesSubject: BehaviorSubject<ServiceType[]>

  /**
   * Observe this for Racks
   */
  public racks$: Observable<Rack[]>

  /**
   * Observe this for Service Levels
   */
  public levels$: Observable<ServiceLevel[]>

  /**
   * Observe this for Service Types
   */
  public types$: Observable<ServiceType[]>

  constructor(
    private _auth: AuthenticationService,
    private _http: HttpClient
  ) {
    this._initEmpty()
    this._auth.currentUser.subscribe(user => {
      this._initEmpty()
      if (user) {
        this._reloadAll()
      }
    })
  }

  private _initEmpty() {
    this._racks = []
    this._racksSubject = new BehaviorSubject<Rack[]>([])
    this.racks$ = this._racksSubject.asObservable()

    this._serviceLevels = []
    this._serviceLevelsSubject = new BehaviorSubject<ServiceLevel[]>([])
    this.levels$ = this._serviceLevelsSubject.asObservable()

    this._serviceTypes = []
    this._serviceTypesSubject = new BehaviorSubject<ServiceType[]>([])
    this.types$ = this._serviceTypesSubject.asObservable()
  }

  private _reloadAll() {
    this._http.get<Rack[]>(api.racks.all).subscribe(
      all => {
        this._racks = all
        this._racksSubject.next(this._racks)
      }
    )
    this._http.get<ServiceLevel[]>(api.serviceLevels.all).subscribe(
      all => {
        this._serviceLevels = all
        this._serviceLevelsSubject.next(this._serviceLevels)
      }
    )
    this._http.get<ServiceType[]>(api.serviceTypes.all).subscribe(
      all => {
        this._serviceTypes = all
        this._serviceTypesSubject.next(this._serviceTypes)
      }
    )
  }
}
