import { Component, Input } from '@angular/core'

import { AuthenticationService } from '@/services/authentication.service'
import { currencyFiEur } from '@/util/number-utils'

import { ServiceProductVariation } from '@/domain/service-product-variation'
import { ServiceProduct } from '@/domain/service-product'
import { Store } from '@/domain/store'

@Component({
  selector: 'sales-period-booking-header',
  templateUrl: 'sales-period-booking-header.component.html'
})
export class SalesPeriodBookingHeaderComponent {

  @Input()
  title: string

  @Input()
  readmore: boolean = true

  @Input()
  store: Store

  @Input()
  product: ServiceProduct

  @Input()
  variation: ServiceProductVariation

  constructor(
    public auth: AuthenticationService
  ) {}

  get price(): string|null {
    if (!this.auth.currentUserValue.merchant.seller_reservation_checkout_enabled && !this.auth.currentUserValue.merchant.seller_reservation_checkout_required) {
      return null
    }
    return (this.variation.price && this.variation.price >= 0)
      ? this._currency(this.variation.price)
      : null
  }

  get priceInStore(): string|null {
    if (this.auth.currentUserValue.merchant.seller_reservation_checkout_required) {
      return null
    }
    return (this.variation.price_in_store && this.variation.price_in_store >= 0)
      ? this._currency(this.variation.price_in_store)
      : null
  }

  get commission(): string {
    return (this.variation.commission && this.variation.commission >= 0)
      ? this._percent(this.variation.commission)
      : '–'
  }

  get capacity(): string {
    return (this.variation.capacity && this.variation.capacity > 0)
      ? ''+this.variation.capacity
      : '–'
  }

  private _currency(value: any): string {
    return currencyFiEur(value)
  }

  private _percent(value: number): string {
    return value ? value + "%" : ''
  }

}