import { Component, OnInit } from '@angular/core';

import { ProductFeaturesService } from '@/services/product-features.service';

import { ProductColor } from '@/domain/product-color';

@Component({
  selector: 'app-color',
  templateUrl: './color.component.html'
})
export class ProductColorComponent implements OnInit {

  constructor(private featureService: ProductFeaturesService) { }

  colors: ProductColor[];

  ngOnInit(): void {
    this.getColors();
  }

  getColors(): void {
    this.featureService.getColors()
      .subscribe(colors => this.colors = colors)
  }

}
