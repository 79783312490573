import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { v4 as uuid } from 'uuid'
import { currencyFiEur } from '@/util/number-utils'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'
import { ProductFeaturesService } from '@/services/product-features.service'
import { ProductsService } from '@/services/products.service'
import { SalesService } from '@/services/sales.service'

import { Product } from '@/domain/product'
import { ProductSold } from '@/domain/product-sold'
import { Sale } from '@/domain/sale'
import { SaleRow } from '@/domain/sale-row'
import { Store } from '@/domain/store'

@Component({
  selector: 'merchant-pos',
  templateUrl: './merchant-pos.component.html',
  styleUrls: [ './merchant-pos.component.scss' ],
})
export class MerchantPosComponent implements OnInit {

  @ViewChild('barcodeInput')
  barcodeInput: ElementRef

  @ViewChild('priceEntryModal')
  priceEntryModal: ElementRef

  barcodeScanForm = this.fb.group({
    scanned_number: ['']
  })

  productSearchForm = this.fb.group({
    seller_number: [''],
    product_number: ['']
  })

  store: Store = null
  stores: Store[] = null
  storeChoice: {}

  latestSale: Sale = null

  productAlreadySelected: boolean = false
  productNotFound: boolean = false
  unfoundBarcode: string = null
  unsellableProductFound = false
  unsellableStatus = null

  foundProducts: Product[] = []
  selectedProducts: Product[] = []

  isEiku: boolean = false
  createdSale: Sale = null

  nonce: string = null

  priceBeingSpecified
  private _priceEntryModalRef: NgbModalRef
  private _productBeingPriced: Product

  private _hasManyStores: boolean = false

  priceEntryForm = this.fb.group({
    price: ['']
  })

  constructor(
    public features: ProductFeaturesService,
    public productsService: ProductsService,
    private salesService: SalesService,
    public auth: AuthenticationService,
    private alertService: AlertService,
    private _modalService: NgbModal,
    private fb: FormBuilder
  ) { }

  get multistore(): boolean {
    return this._hasManyStores
  }

  ngOnInit(): void {
    this.auth.currentUser.subscribe(user => {
      if (!user) {
        // logout
        this.stores = []
        this._hasManyStores = false
      } else {
        this.stores = user.merchant.stores
        this._hasManyStores = this.stores.length > 1
      }
      this.reset()
    })
  }

  private clear(): void {
    this.nonce = uuid()
    this.isEiku = false
    this.createdSale = null
    this.resetFoundProducts()
    this.selectedProducts = []
    this.refreshLatest()
  }

  private reset(): void {
    this.clear()
    this.storeChoice = {}
    this.stores.forEach(s => { this.storeChoice[s.id] = false })
    if (this.multistore) {
      var id = this.loadStoreChoice()
      if (id) {
        this.store = this.stores.find(s => s.id == id)
      }
    } else {
      this.store = this.stores[0]
    }
  }

  storeChosen($event) {
    if (!this.multistore) {
      return
    }

    let chosenStoreId = $event.target.id
    if (this.store && chosenStoreId == this.store.id) {
      return
    }

    this.store = this.stores.find(s => s.id == chosenStoreId)
    this.saveStoreChoice(this.store.id)
    this.clearLatest()
    this.clear()
  }

  unselectStore() {
    if (!this.multistore) {
      return
    }
    this.store = null
    this.clearStoreChoice()
    this.clearLatest()
  }

  private loadStoreChoice(): number {
    var id = localStorage.getItem('pos.storeId')
    return id ? +id : null
  }

  private saveStoreChoice(id: number): void {
    localStorage.setItem('pos.storeId', ''+id)
  }

  private clearStoreChoice(): void {
    localStorage.removeItem('pos.storeId')
  }

  private resetFoundProducts(): void {
    this.foundProducts = []
  }

  next(): void {
    this.reset()
  }

  eiku(): void {
    this.resetFoundProducts()
    this.isEiku = true
  }

  kassalle(): void {
    let soldProducts = this.selectedProducts.filter(p => { return !p.to_be_removed })
    let products_sold = soldProducts.map(p => new ProductSold(p.id, p.pos_set_price))
    let saleToUpdate = this.isEiku ? this.createdSale.id : null
    this.salesService.createOrUpdateSale(this.store, products_sold, saleToUpdate, this.nonce).subscribe(
      sale => {
        if (!sale) {
          // got http 204 indicating a repeated creation: no action needed
          return
        }
        this.createdSale = sale

        // clean up eiku statuses, bue we leave the
        // selectedProducts otherwise in place, in case
        // of repeated eiku again
        if (this.isEiku) {
          let toRemove = []
          this.selectedProducts.forEach(p => {
            if (p.to_be_added) {
              p.to_be_added = false // was just added to sale
            }
            if (p.to_be_removed) {
              toRemove.push(p) // was just removed from sale
            }
          })
          toRemove.forEach(p => {
            this.doRemove(p)
          })
          this.isEiku = false
        }
      },
      error => { this.alertService.error(error.message) }
    )
  }

  specifyPrice(product: Product): void {
    this._displayPriceEntryModal(product)
  }

  submitPrice(): void {
    this._priceEntryModalRef.close()
  }

  private _setPriceFromForm(product: Product): void {
    const value = parseFloat(this.priceEntryForm.get('price').value)
    if (value >= 0.0) {
      product.pos_set_price = value
    }
    this.priceBeingSpecified = null
  }

  private _displayPriceEntryModal(product: Product): void {
    this._productBeingPriced = product
    const value = product.pos_set_price && product.pos_set_price >= 0.0 ? product.pos_set_price : ''
    this.priceEntryForm.setValue({ price: value })
    this._priceEntryModalRef = this._modalService.open(this.priceEntryModal, { size: 'sm', centered: true, ariaLabelledBy: 'modal-basic-title' })
    this._priceEntryModalRef.result.then(
      success => { this._setPriceFromForm(product) },
      dismiss => { }
    )
  }

  mayProceedToKassa(): boolean {
    return !this.productNotFound && this.selectedProducts.length > 0 && this._allManualProductsArePriced()
  }

  sum(): string {
    if (!this._allManualProductsArePriced()) {
      return '? '
    }
    let sum: number = this.selectedProducts
      .map(product => { return this._priceConsideringDiscount(product) })
      .reduce((a, b) => { return +a + +b }, 0)
    return sum.toFixed(2)
  }

  private _allManualProductsArePriced(): boolean {
    return this.selectedProducts && !this.selectedProducts.find(p => { return p.is_manual && (!p.pos_set_price || p.pos_set_price < 0) })
  }

  isSelected(product: Product): boolean {
    return this.selectedProducts.find(sp => { return sp.id === product.id }) !== undefined
  }

  select(product: Product) {
    if (!this.isSelected(product)) {
      this.selectedProducts.unshift(product)
      this.unfind(product)

      if (this.isEiku) {
        product.to_be_added = true
      }
    }
  }

  submitBarcodeScan(): void {
    let v = this.barcodeScanForm.value['scanned_number']
    if (!v || (v.trim().length != 12 && v.trim().length != 13)) {
      this.setProductNotFound(v.trim())
      this.clearForms()
      return
    }
    this.searchByProductNumber(v.trim(), true)
  }

  submitProductSearch(): void {
    let v = this.productSearchForm.value
    let sn: number = this.productSearchForm.value['seller_number']
    let pn: number = this.productSearchForm.value['product_number']
    if (v['seller_number'].length == 12) {
      sn = parseInt(v['seller_number'].substr(0, 6))
      pn = parseInt(v['seller_number'].substr(6, 6))
    }
    if (v['product_number'].length == 12) {
      sn = parseInt(v['product_number'].substr(0, 6))
      pn = parseInt(v['product_number'].substr(6, 6))
    }
    this.search(sn, pn, false)
  }

  private searchByProductNumber(productNumber: string, autoselect: boolean): void {
    const sn = parseInt(productNumber.substr(0, 6))
    const pn = parseInt(productNumber.substr(productNumber.length == 13 ? 7 : 6, 6))
    this.search(sn, pn, autoselect)
  }

  private productIsAlreadySelected(sn: number, pn: number): boolean {
    let found = this.selectedProducts.find(p => { return p.sales_period.seller_id == sn && p.number == pn })
    if (found) {
      this.disableSearchElements()
      this.productAlreadySelected = true
      return true
    }
    return false
  }

  private search(sn: number, pn: number, autoselect: boolean): void {
    if (this.productIsAlreadySelected(sn, pn)){
      return
    }
    this.clearProductNotice()
    this.productSearchForm = this.fb.group({
      seller_number: sn,
      product_number: pn
    })
    this.productsService.searchPos(this.store, this.productSearchForm.value)
      .subscribe(
        products => {
          let taggedProducts = products.filter(p => { return p.status === 'tagged' })
          if (taggedProducts.length === 0 && products.length === 1) {
            // no tagged (hinnoiteltu) products found, but one found in other status
            this.setProductFoundInUnsellableStatus(products[0].status)
          } else {
            if (taggedProducts.length > 0 && autoselect) {
              this.select(taggedProducts[0])
            } else {
              this.foundProducts = taggedProducts
            }
            if (taggedProducts.length == 0) {
              this.setProductNotFound()
            }
          }
          this.clearForms()

        },
        error => { console.log(error) }
      )
  }

  unfind(product: Product): void {
    this.foundProducts.splice(this.foundProducts.indexOf(product), 1)
  }

  unfindAll(): void {
    this.foundProducts = []
  }

  cancelEikuAddOrRemove(product: Product) {
    if (this.isEiku) {
      if (product.to_be_added) {
        product.to_be_added = false
        this.doRemove(product)
      }
      if (product.to_be_removed) {
        product.to_be_removed = false
      }
    }
  }

  remove(product: Product) {
    if (this.isEiku) {
      product.to_be_removed = true
    } else {
      this.doRemove(product)
    }
  }

  private doRemove(product: Product) {
    product.deleting = true
    setTimeout(() => {
      product.deleting = false
      this.selectedProducts.splice(this.selectedProducts.indexOf(product), 1)
    }, 150)
  }

  pn(): string {
    return this.zeroPad6(this._productBeingPriced.sales_period.seller_id) + '-' + this.zeroPad6(this._productBeingPriced.number)
  }

  zeroPad6(number) {
    return number ? (""+number).padStart(6, '0') : ''
  }

  clearProductNotice(): void {
    this.productAlreadySelected = false
    this.productNotFound = false
    this.unfoundBarcode = null
    this.unsellableProductFound = false
    this.unsellableStatus = null

    this.enableSearchElements()
    this.barcodeInput.nativeElement.focus()
  }

  priceDisplay(product: Product): string {
    if (product.sales_period && product.sales_period.discount && product.sales_period.discount > 0) {
      const price = product.is_manual ? product.pos_set_price : product.price
      const discount_percent = product.sales_period.discount
      const discounted_price = this._applyDiscount(price, discount_percent)
      return currencyFiEur(discounted_price) + ' (' + currencyFiEur(price) + ' -' + discount_percent + '%)'
    }
    return currencyFiEur(product.is_manual ? product.pos_set_price : product.price)
  }

  private _priceConsideringDiscount(product): number {
    const price = product.is_manual ? product.pos_set_price : product.price
    if (!product.sales_period || !product.sales_period.discount || product.sales_period.discount == 0) {
      return price
    }
    return this._applyDiscount(price, product.sales_period.discount)
  }

  private _applyDiscount(price: number, discount_percent: number): number {
    const price_cents = price * 100
    const discount_cents = (discount_percent / 100.0) * price_cents
    const discounted_price_cents = price_cents - discount_cents
    return Math.round(discounted_price_cents) / 100.0
  }

  saleRowPriceDisplay(row: SaleRow): string {
    var display = currencyFiEur(row.total)
    if (row.discount_percent > 0) {
      display += ` (${currencyFiEur(row.product_price)} -${row.discount_percent}%)`
    }
    return display
  }

  private refreshLatest(): void {
    if (!this.store) {
      this.clearLatest()
    } else {
      this.salesService.getLatest(this.store).subscribe(
        latest => { this.setLatest(latest) },
        error => { this.clearLatest() }
      )
    }
  }

  private setLatest(sale: Sale): void {
    this.latestSale = sale
  }

  private clearLatest(): void {
    this.latestSale = null
  }

  private setProductFoundInUnsellableStatus(status: string): void {
    this.unsellableProductFound = true
    this.unsellableStatus = status
    this.disableSearchElements()
  }

  private setProductNotFound(barcode: string = null): void {
    this.productNotFound = true
    this.unfoundBarcode = barcode
    this.disableSearchElements()
  }

  private disableSearchElements(): void {
    this.barcodeScanForm.get('scanned_number').disable()
    this.productSearchForm.get('seller_number').disable()
    this.productSearchForm.get('product_number').disable()
  }

  private enableSearchElements(): void {
    this.barcodeScanForm.get('scanned_number').enable()
    this.productSearchForm.get('seller_number').enable()
    this.productSearchForm.get('product_number').enable()
  }

  private clearForms() {
    this.productSearchForm = this.fb.group({
      seller_number: [''],
      product_number: ['']
    })
    this.barcodeScanForm = this.fb.group({
      scanned_number: ['']
    })
  }
}
