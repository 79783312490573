import { PlainDate } from '@/util/date-utils'

import { Merchant } from '@/domain/merchant'
import { Rack } from '@/domain/rack'
import { SalesPeriod } from '@/domain/sales-period'
import { Store } from '@/domain/store'

import { RackTimeline } from '@/model/rack-timeline'

export class CalendarViewModel {

  private storesById: Map<number, Store> = null
  private racksById: Map<number, Rack> = null
  private rackIdsWithSalesPeriods: number[] = []
  private timelinesByRackId: Map<number, RackTimeline> = new Map()

  constructor(
    public salesPeriods: SalesPeriod[],
    public dayRange: PlainDate[] = [],
    public merchant: Merchant,
    public racks: Rack[] = []
  ) {
    // setup data
    const ids = this.salesPeriods.map(p => { return p.rack_time_slot.reck.id })
    this.rackIdsWithSalesPeriods = [...new Set(ids)]
    this.storesById = new Map<number, Store>()
    this.merchant.stores.forEach(store => { this.storesById.set(store.id, store) })
    this.racksById = new Map<number, Rack>()
    this.racks.forEach(r => { this.racksById.set(r.id, r) })

    // generate rack timelines
    this.rackIdsWithSalesPeriods.forEach(rackId => {
      let rackPeriods = this._periodsForRack(rackId)
      const rack = this.racksById.get(rackId)
      let rackTimeline = new RackTimeline(rack, rackPeriods, this.dayRange, this.merchant)//, this.racksById)
      this.timelinesByRackId.set(rackId, rackTimeline)
    })
  }

  private _periodsForRack(rackId: number): SalesPeriod[] {
    return this.salesPeriods.filter(period => { return period.rack_time_slot.reck.id === rackId })
  }

  public getTimelineFor(rackId: number): RackTimeline {
    let timeline = this.timelinesByRackId.get(rackId)
    if (!timeline) {
      // Rack was unknown coz no sales period, so generate empty timeline now
      timeline = this.createEmptyTimelineFor(rackId)
    }
    return timeline
  }

  private createEmptyTimelineFor(rackId: number): RackTimeline {
    let timeline = new RackTimeline(this.racksById.get(rackId), [ ], this.dayRange, this.merchant) // , this.racksById)
    this.timelinesByRackId.set(rackId, timeline)
    return timeline
  }
}

