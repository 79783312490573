<nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
  <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="/">
    {{merchantName}}
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">

    <ul class="navbar-nav" *ngIf="auth.isSeller">
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/reservation" *ngIf="auth.isSellerReservationEnabled">{{'nav.booking'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/periods">{{'nav.salesperiods'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/settings">{{'nav.settings'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" href="{{'url.instructions'|translate}}" target="_blank">{{'nav.instructions'|translate}}</a></li>
    </ul>

    <ul class="navbar-nav" *ngIf="auth.isMerchant">
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/dashboard">{{'nav.dashboard'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/merchant/pos">{{'nav.pos'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/calendar">{{'nav.calendar'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/periods">{{'nav.salesperiods'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/settlements">{{'nav.settlements'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/sepa-xml">{{'nav.sepa_xml'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/merchant/sellers/all">{{'nav.sellers'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/merchant/service">{{'nav.service_products'|translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/settings">{{'nav.settings'|translate}}</a></li>
    </ul>

    <ul class="navbar-nav" *ngIf="auth.isAdmin">
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/admin/dashboard">Dashboard</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/admin/merchants">Merchants</a></li>
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/admin/reports">Reports</a></li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <application-update-alert></application-update-alert>

      <li class="nav-item" *ngIf="!user"><a class="nav-link" routerLink="/login">{{'account.login'|translate}}</a></li>
      <li *ngIf="!user && sellerSignupEnabled" class="nav-item"><a class="nav-link" routerLink="/signup">{{'account.signup'|translate}}</a></li>

      <li class="nav-item dropdown pr-4" *ngIf="user">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{user.username}}
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <h6 class="dropdown-header">{{'role.' + user.role | lowercase | translate | uppercase}}</h6>
          <a class="dropdown-item" routerLink="/profile" *ngIf="auth.isSeller">{{'nav.profile'|translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()"><i-bs class="mr-2" name="boxArrowRight"></i-bs> {{'account.logout'|translate}}</a>
        </div>
      </li>

      <li *ngIf="env !== 'prod'" class="navbar-text">
        <span class="badge" style="background-color: red; color: white; font-weight: bold;">
          {{env|uppercase}}
        </span>
      </li>

    </ul>
  </div>
</nav>