<div class="sales-period-calendar vh-100 d-flex flex-column">
  <!-- dodge the nav bar (actual height 42px) -->
  <div class="flex-column">
    <div style="height:60px">HEADER</div>
  </div>
  <div class="flex-column flex-grow-1 overflow-auto">
    <sales-period-seller-reservation (selectedForCreate)="selected($event)">
    </sales-period-seller-reservation>
  </div>
</div>

<ng-template #confirmSelectionModal let-modal>
  <div class="modal-header">
    <div class="container px-0">
      <div class="row" *ngIf="!reservedSalesPeriod">
        <div class="col">
          <div class="modal-title mb-2" id="modal-basic-title">
            <h1>{{'seller.booking.reservation_msg'|translate}}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h2>
            <sales-period-booking-header [readmore]="false" [product]="selection.product" [variation]="selection.variation"></sales-period-booking-header>
          </h2>
          <h2 class="mt-2">
            {{'common.from'|translate|titlecase}} {{selection.from.asDate() | date: 'd.M.'}}
          </h2>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <!-- <p><span [innerHTML]="selection.product.description_formatted"></span></p> -->
          <p><span [innerHTML]="selection.variation.description_formatted"></span></p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <alert></alert>
    <div *ngIf="!reservedSalesPeriod">
      <label for="sellerNoteTextArea">{{'seller.booking.note_to_merchant'|translate}}</label>
      <textarea #sellerNoteTextArea type="text" rows="3" class="form-control p-1" [(ngModel)]="sellerNote"></textarea>
    </div>
  </div>
  <div class="modal-footer"><!-- d-flex -->

    <div class="container px-0">

    <div class="row">
      <div class="col">

        <div class="text-right"><!-- mt-2 -->
          <input #tcCheckBox type="checkbox" [(ngModel)]="tcAccepted"/>
          <label for="tcCheckBox">
            <span class="ml-2" [innerHTML]="'seller.booking.accept_tc'|translate:{url:merchant.terms_and_conditions_url}"></span>
          </label>
        </div>


      </div>
    </div>

    <div class="row">
      <div class="col d-flex">
        <button *ngIf="!reservedSalesPeriod" class="btn btn-secondary" (click)="modal.dismiss('cancel')">{{'common.cancel'|translate|uppercase}}</button>
        <button *ngIf="reservedSalesPeriod" class="btn btn-secondary" (click)="modal.dismiss('close')">{{'common.close'|translate|uppercase}}</button>
        <div *ngIf="!reservedSalesPeriod" class="ml-auto">
          <div *ngIf="bothPaymentOptionsAvailable; else justConfirm" style="text-align: right;">
            <button class="btn btn-primary mb-3" (click)="confirmReservationPayInStore()" [disabled]="!tcAccepted">{{'common.confirm_reservation_pay_in_store'|translate:{price:priceInStore}|uppercase}}</button>
            <br/>
            <button class="btn btn-primary" (click)="confirmReservationPayOnline()" [disabled]="!tcAccepted">{{'common.confirm_reservation_pay_online'|translate:{price:price}|uppercase}}</button>
          </div>
          <ng-template #justConfirm>
            <button class="btn btn-primary" (click)="confirmReservation()" [disabled]="!tcAccepted">{{'common.confirm_reservation'|translate|uppercase}}</button>
          </ng-template>
        </div>
        <button *ngIf="reservedSalesPeriod" class="btn btn-success ml-auto" (click)="modal.close('done_after_reserved')">{{'seller.booking.open_new_period'|translate|uppercase}}</button>
      </div>
    </div>

    </div>

  </div>
</ng-template>

<ng-template #proceedToPaymentModal let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">{{'reservation.goto_payment_method_title'|translate}}</h1>
  </div>
  <div class="modal-body">
    <div *ngIf="paymentStatus === 'fail'">
      <ngb-alert [type]="'warning'" [dismissible]="false">
        <span [innerHTML]="'reservation.payment_failed_try_again' | translate"></span>
      </ngb-alert>
    </div>
    <p>{{'reservation.goto_payment_method_msg'|translate}}</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="modal.dismiss('cancel')">{{'reservation.cancel_reservation'|translate|uppercase}}</button>
    <button class="btn btn-success ml-auto" (click)="modal.close('goto_payment')">{{'reservation.goto_payment_button'|translate|uppercase}}</button>
  </div>
</ng-template>

<ng-template #retryPaymentModal let-modal>
  <div class="modal-header" style="display: initial">
    <div *ngIf="!(paymentStatus && paymentStatus != 'success')">
      <h1 class="modal-title" id="modal-basic-title">{{'reservation.retry_or_cancel_title'|translate}}</h1>
      <br/>
    </div>
    <h1 class="modal-title" id="modal-basic-title">
      <span class="badge badge-light p-2">{{selection.product.code}}</span>
      <span class="badge badge-light p-2 ml-2">{{selection.variation.code}}</span>
      &bull; {{'common.duration'|translate|titlecase}} {{selection.variation.duration}}{{'common.days_abbrev'|translate}}
      &bull; <span *ngIf="selection.from; else noFromDate">{{'common.from'|translate|titlecase}} {{selection.from.asDate() | date: 'd.M.'}}</span>–<span *ngIf="selection.to; else noToDate">{{'common.from'|translate|titlecase}} {{selection.to.asDate() | date: 'd.M.'}}</span>
        <ng-template #noFromDate>?1</ng-template>
        <ng-template #noToDate>?2</ng-template>
      <br/>
      {{'common.max_items'|translate}} <span class="badge badge-light p-2 mr-2">{{capacity}}</span>
      &bull; {{'common.commission'|translate|titlecase}} <span class="badge badge-light p-2 mr-2">{{commission}}</span>
      &bull; {{'common.price'|translate|titlecase}} <span class="badge badge-light p-2 mr-2">{{price}}</span>
    </h1>
  </div>
  <div class="modal-body">
    <ngb-alert *ngIf="paymentStatus && paymentStatus != 'success'" [type]="'warning'" [dismissible]="false">
      <span [innerHTML]="'reservation.status_msg_'+paymentStatus | translate"></span>
    </ngb-alert>
    <p>{{'reservation.retry_or_cancel_msg'|translate}}</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="modal.dismiss('cancel')">{{'reservation.cancel_reservation'|translate|uppercase}}</button>
    <button class="btn btn-primary ml-auto" (click)="modal.close('goto_reconfirm')">{{'reservation.goto_reconfirm_button'|translate|uppercase}}</button>
  </div>
</ng-template>

<ng-template #doneModal let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">{{'reservation.success_title'|translate}}</h1>
  </div>
  <div class="modal-body">
    <alert></alert>
    <p>{{'reservation.success_msg'|translate}}</p>
  </div>
  <div class="modal-footer d-flex">
    <button class="btn btn-success ml-auto" (click)="modal.close('ok')">{{'reservation.success_button'|translate|uppercase}}</button>
  </div>
</ng-template>