<div class="container-fluid" style="margin-top: 60px;">

  <div class="row">
    <div class="col">

      <div class="card gutter-b">
        <div class="card-header">
          <div class="d-flex">
            <h1 class="mb-5">{{'merchant.settlements.title_sepaxml'|translate}}</h1>
            <div class="ml-auto">
              <button class="btn btn-primary" (click)="create()">
                {{'common.create_new'|translate|uppercase}}
                <span *ngIf="loading" class="spinner-border spinner-border-sm ml-1"></span>
              </button>
            </div>
          </div>
          <alert></alert>
        </div>
        <div class="card-body">

          <div *ngIf="messages">

            <div *ngIf="messages.length == 0">
              {{'merchant.settlements.no_xml'|translate}}
            </div>

            <table *ngIf="messages.length > 0" class="table table-sm table-hover">
              <thead>
                <tr>
                  <th scope="col">{{'common.number'|translate|titlecase}}</th>
                  <th scope="col">{{'common.time'|translate|titlecase}}</th>
                  <th scope="col">{{'common.settlements'|translate|titlecase}}</th>
                  <th scope="col">XML</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let message of messages">
                  <td scope="row"><span class="badge badge-light p-1 m-1">{{message.id}}</span></td>
                  <td scope="row" style="white-space: nowrap;">{{message.created_at | date: 'yyyy-MM-dd HH:mm'}}</td>
                  <td scope="row">
                    <div *ngIf="message.settlements; else noSettlementsInMessage">
                      <span *ngFor="let settlement of message.settlements | ysort">
                        <span class="badge badge-light p-1 mr-1">{{settlement.sales_period_id}}/{{settlement.number}}</span>
                      </span>
                    </div>
                    <ng-template #noSettlementsInMessage>
                      &ndash;
                    </ng-template>
                  </td>
                  <td scope="row">
                    <button class="btn btn-primary p-1 m-0" style="cursor: pointer;">
                      <i-bs name="save" (click)="downloadXml(message)"></i-bs>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div><!-- card body -->
      </div><!-- card -->

    </div><!-- col -->
  </div><!-- row -->

</div><!-- container-fluid -->
