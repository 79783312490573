import { duration, remainingDuration, areEqual, isOnOrAfter, isOnOrBefore, PlainDate } from '@/util/date-utils'

import { Merchant } from '@/domain/merchant'
import { Rack } from '@/domain/rack'
import { SalesPeriod } from '@/domain/sales-period'
import { Store } from '@/domain/store'

import { FREE_DAY, BUSY_DAY, CLOSED_DAY } from '@/model/calendar'
import { RackDay } from '@/model/rack-day'

export class RackTimeline {

  public rackDays: RackDay[] = []
  public firstAvailableDay: RackDay = null

  public rackId: number

  private _store: Store

  constructor(
    public rack: Rack,
    public periods: SalesPeriod[],
    public dayRange: PlainDate[],
    public merchant: Merchant
  ) {
    this.rackId = rack.id
    this._store = merchant.storeById(rack.store_id)
    this.init()
  }

  private init(): void {
    for (let i = 0; i < this.dayRange.length; i++) {
      let plainDate = this.dayRange[i]
      let rackDay = new RackDay(this)
      rackDay.date = plainDate
      rackDay.status = this.findStatusFor(plainDate)
      if (!this.firstAvailableDay && rackDay.status == FREE_DAY) {
        rackDay.isFirstAvailableDate = true
        this.firstAvailableDay = rackDay
      }
      if (i > 0) {
        rackDay.period = this.findPeriodStartingOn(plainDate)
        rackDay.duration = rackDay.period ? duration(rackDay.period.rack_time_slot) : 1
        rackDay.periodStatus = rackDay.period && rackDay.period.status === 'status_preliminary' ? 'status_preliminary' : 'future'
      } else {
        rackDay.period = this.findPeriodOngoingOn(plainDate)
        rackDay.duration = rackDay.period ? remainingDuration(rackDay.period.rack_time_slot) : 1
        rackDay.periodStatus = rackDay.period && rackDay.period.status === 'status_preliminary' ? 'status_preliminary' : 'current'
      }
      this.rackDays.push(rackDay)
      i += rackDay.duration - 1
    }
  }

  private findStatusFor(plainDate: PlainDate): string {
    // prioritize 'busy' status over 'closed', so check for it first
    let period = this.findPeriodOngoingOn(plainDate)
    if (period) {
      return BUSY_DAY
    }
    if (this._store.isClosedOn(plainDate)) {
      return CLOSED_DAY
    }
    const rack = this.rack // this.racksById.get(this.rackId)
    if (rack && rack.isClosedOn(plainDate)) {
      return CLOSED_DAY
    }
    return FREE_DAY
  }

  private isDateInSalesPeriod(date: PlainDate, period: SalesPeriod): boolean {
    let from = PlainDate.ofDate(new Date(period.rack_time_slot.from))
    let to = PlainDate.ofDate(new Date(period.rack_time_slot.to))
    return isOnOrAfter(date.asDate(), from.asDate()) && isOnOrBefore(date.asDate(), to.asDate())
  }

  private findPeriodStartingOn(date: PlainDate): SalesPeriod | null {
    return this.periods.find(period => {
      return areEqual(PlainDate.ofDate(new Date(period.rack_time_slot.from)), date)
    })
  }

  private findPeriodOngoingOn(date: PlainDate): SalesPeriod | null {
    return this.periods.find(period => {
      return this.isDateInSalesPeriod(date, period)
    })
  }

  /** Unhover all days in this RackTimeline */
  public unhover(): void {
    this.rackDays.forEach(r => { r.unhover() })
  }

  /** Hover the duration's days if they are available */
  public hoverIfAvailable(fromRackDay: RackDay, duration: number): void {
    let index = this.rackDays.findIndex(r => r === fromRackDay)
    if (index > (this.rackDays.length - duration)) {
      return
    }
    for (let i = 0; i < duration; i++) {
      const day = this.rackDays[index + i]
      if (day.busy) {
        return
      }
    }
    let span = duration
    for (let i = 0; i < span; i++) {
      const day = this.rackDays[index + i]
      if (day.closed) {
        span += 1 // skip a closed day
      } else {
        day.hover() // hover on an open day
      }
    }
    fromRackDay.hoverDuration(span)
  }
}
