import { Component, OnInit } from '@angular/core';

import { ServiceProductsService } from '@/services/service-products.service';

import { ServiceProduct } from '@/domain/service-product';

@Component({
  selector: 'app-service-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  products: ServiceProduct[]

  constructor(private service: ServiceProductsService) { }

  ngOnInit(): void {
    this.getAllProducts();
  }

  getAllProducts(): void {
    // console.log('loading service products')
    this.service.serviceProducts.subscribe(p => this.products = p)
  }
}
