import { LocalDate } from '@js-joda/core'
import * as equal from 'fast-deep-equal'
import { Store } from './store'
import { CustomPeriodStatus } from './custom-period-status'

export class Merchant {
  id: number

  name: string
  official_name: string
  address: string
  business_id: string
  email: string
  phone: string
  contact: string
  iban: number
  bic: number
  checkout_merchant_id: number

  stores: Store[]

  homepage: string
  hostname: string
  static_logo_asset: string
  maybe_create_product_features: boolean
  seller_registration_enabled: boolean
  seller_reservations_enabled: boolean
  seller_reservation_checkout_enabled: boolean
  seller_reservation_checkout_required: boolean
  privacy_policy_url: string
  terms_and_conditions_url: string
  earliest_settlement_date: LocalDate
  last_calendar_date_rolling: number
  custom_period_statuses?: CustomPeriodStatus[]

  public reservationCalendarLastDate(): LocalDate {
    var plus = this.last_calendar_date_rolling > 0 ? this.last_calendar_date_rolling : 90;
    return LocalDate.now().plusDays(plus);
  }

  public static from(json: any): Merchant {
    const merchant = Object.assign(new Merchant(), json)
    if (json.stores) {
      merchant.stores = json.stores.map((s: any) => { return Store.from(s) })
    }
    if (json.earliest_settlement_date) {
      merchant.earliest_settlement_date = LocalDate.parse(json.earliest_settlement_date)
    }
    if (merchant.hasCustomPeriodStatuses()) {
      merchant.custom_period_statuses = merchant.custom_period_statuses.sort((a,b) => { return b.order - a.order })
    }
    return merchant
  }

  public storeById(storeId: number): Store {
    return this.stores.find(s => { return s.id == storeId })
  }

  public hasCustomPeriodStatuses(): boolean {
    return this.custom_period_statuses && this.custom_period_statuses.length > 0
  }

  public hasSettlements(): boolean {
    return this.earliest_settlement_date != null
  }

  public static equal(merchant1: Merchant, merchant2: Merchant): boolean {
    return equal(merchant1, merchant2)
  }
}