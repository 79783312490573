import { Injectable } from '@angular/core'

import { Rack } from '@/domain/rack'
import { ServiceProduct } from '@/domain/service-product'
import { Store } from '@/domain/store'

@Injectable({ providedIn: 'root' })
export class StoresService {

  findStoresFromRacks(products: ServiceProduct[]): Store[] {
    let storeById = {}
    let stores: Store[] = []
    products.forEach((p: ServiceProduct) => {
      p.recks.forEach((r: Rack) => {
        if (!storeById[r.store_id]) {
          let store = new Store(r.store_id, r.store_name)
          storeById[r.store_id] = store
          stores.push(store)
        }
      })
    })
    return stores
  }

  filterRacksForStore(serviceProducts: ServiceProduct[], storeId: number): Rack[] {
    if (serviceProducts == null) {
      return []
    }
    let racks = serviceProducts.flatMap(p => { return p.recks })
    let x = racks.filter(r => { return r.store_id == storeId })
    // console.log('racks for store ' + storeId + ' ' + x[0].store_name, x)
    return x
  }

  filterProductsForStore(serviceProducts: ServiceProduct[], storeId: number): ServiceProduct[] {
    if (serviceProducts == null) {
      return []
    }
    return serviceProducts.filter(p => { return this.hasRackForStore(p, storeId) })
  }

  private hasRackForStore(product: ServiceProduct, storeId: number): boolean {
    let y = product.recks.filter(rack => { return rack.store_id == storeId })
    let x = y.length > 0
    return x
  }
}
