import { SalesPeriod } from '@/domain/sales-period'

export function domainMerge(arr1: any[], arr2: any[]): any[] {
  const merged = [
    ...[...arr1, ...arr2]
      .reduce(
        (acc, curr) => acc.set(curr.id, { ...acc.get(curr.id), ...curr }),
        new Map()
      )
      .values(),
  ]
  return merged
}

export function containsById(array, item): boolean {
  return array.find(a => a.id == item.id)
}

// Compare by products
export function periodsDiffer(period1: SalesPeriod, period2: SalesPeriod): boolean {
  if (!shallowEqual(period1.product_counts, period2.product_counts)) {
    return true
  }
  let differs = false
  period1.products.forEach(product => {
    const otherProduct = period2.products.find(p => p.id == product.id)
    if (!shallowEqual(product, otherProduct)) {
      differs = true
    }
  })
  return differs
}

function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }
  return true
}
