import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { first } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateService } from '@ngx-translate/core'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'

@UntilDestroy()
@Component({ templateUrl: 'reset-password.component.html' })
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup
  loading = false
  submitted = false
  returnUrl: string

  didResetOk = false

  merchantLogoUrl = null
  private _token: string

  constructor(
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _translate: TranslateService,
    private _auth: AuthenticationService,
    private _alertService: AlertService
  ) {
    this._auth.preloadedMerchant.pipe(untilDestroyed(this)).subscribe(merchant => {
      this._setMerchantLogo(merchant ? merchant.static_logo_asset : null)
    })

    let params = this._route.snapshot.queryParams
    if (!params['token']) {
      this._router.navigate(['/'])
    }

    this._token = params['token']
  }

  ngOnInit() {
    this.resetForm = this._formBuilder.group({
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required]
    })
  }

  private _setMerchantLogo(asset: string): void {
    this.merchantLogoUrl = asset ? '/' + asset : null
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls }

  onSubmit() {
    this.submitted = true

    // reset alerts on submit
    this._alertService.clear()

    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return
    }

    this.loading = true;
    this._auth.resetPassword(this._token, this.f.password.value, this.f.password_confirmation.value)
      .pipe(first())
      .subscribe(
        response => {
          this.loading = false
          if (response['password_updated']) {
            this.didResetOk = true
            const msg = this._translate.instant('account.password_reset_ok_msg')
            this._alertService.successNoTimeout(msg)
          } else {
            const msg = this._translate.instant('account.password_not_updated')
            this._alertService.error(msg)
          }
        },
        error => {
          this.loading = false
          const msg = this._translate.instant('account.invalid_password_token')
          this._alertService.errorNoTimeout(msg)
        })
  }
}