<div class="col-md-4 offset-md-4 mt-5" style="margin-top: 60px;">

  <div class="card" style="margin-top: 100px;">
    <div *ngIf="merchantLogoUrl" class="merchant-logo-wrapper p-4">
      <img class="merchant-logo-img" src="{{merchantLogoUrl}}" alt="logo"/>
    </div>
    <h4 class="card-header">{{'account.login'|translate}}</h4>
    <div class="card-body">

      <alert></alert>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="username">{{'account.username'|translate}}</label>
          <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">{{'account.username_required'|translate}}</div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">{{'account.password'|translate}}</label>
          <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">{{'account.password_required'|translate}}</div>
          </div>
        </div>
        <div class="d-flex">
          <div>
            <a routerLink="/request_password">{{'account.request_password'|translate}}</a>
          </div>
          <div class="ml-auto t-right">
            <button [disabled]="loading" class="btn btn-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{'account.login'|translate|uppercase}}
            </button>
          </div>
        </div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </form>
    </div>
  </div>
</div>
