<div class="d-flex" id="wrapper" style="margin-top: 60px;">

  <product-features-sidebar></product-features-sidebar>

  <div id="page-content-wrapper">
    <div class="container-fluid">

      <h1 class="mt-4">Product Conditions</h1>

      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let condition of conditions">
            <th scope="row"><span class="badge">{{condition.id}}</span></th>
            <td>{{condition.name}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>

</div>
