import { Merchant } from './merchant'
import { SalesPeriod } from '@/domain/sales-period'
import { User } from '@/domain/user'

export class Seller {

  public id: number
  public created_at?: string
  public updated_at?: string
  public accept_marketing: boolean
  public language: string
  public seller_reservations_enabled: boolean

  public email: string
  public name: string
  public phone: string
  public iban: string

  public user_id: number
  public user?: User

  public merchant_id: number
  public merchant?: Merchant

  public sales_periods?: SalesPeriod[]
  public sales_periods_count?: number

  // transient when merchant adds seller/user
  public user_email?: string
  public user_password?: string

  public recentlyModified?: boolean = false
  public user_already_existed: boolean

  public idString(): string {
    return this.id === null ? "null" : this.id.toString()
  }

  constructor() { }

  public static from(json: any): Seller {
    return Object.assign(new Seller(), json)
  }

  public static fromMany(many: any[]): Seller[] {
    return many.map(a => Seller.from(a))
  }
}
