import { Document } from '@/domain/document'
import { SettlementProduct } from '@/domain/settlement-product'
import { SettlementServiceCharge } from '@/domain/settlement-service-charge'

export class Settlement {
  public id: number
  public created_at: string
  public updated_at: string

  public number: number

  public merchant_id: number
  public merchant_name: string
  public merchant_address: string
  public merchant_contact: string
  public merchant_biz_id: string

  public seller_id: number
  public seller_name: string
  public seller_email: string

  public sales_period_id: number
  public service_product_name: string
  public service_product_variation_name: string
  public from: string
  public to: string

  public sales_total: number
  public commission_total: number
  public commission_tax_total: number
  public service_charges_total: number
  public service_charges_tax_total: number
  public settlement_total: number
  public overage: number

  public settled_for: string
  public settlement_method: string
  public settlement_iban: string
  public payment_status: string
  public payment_time?: Date

  public pdf_document_id: string
  public pdf_document: Document

  // public settlement_products: SettlementProduct[]
  public settlement_service_charges: SettlementServiceCharge[]
  public payment_initiation_message_id?: number

  public static from(json: any): Settlement {
    return Object.assign(new Settlement(), json)
  }

  public static fromMany(many: any[]): Settlement[] {
    return many.map(a => Settlement.from(a))
  }
}
