import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'product-features-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class ProductFeatureSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
