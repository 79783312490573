import { Component, OnInit } from '@angular/core';

import { ServiceProductsStorage } from '@/storage/service-products.storage'

@Component({
  selector: 'service-types',
  templateUrl: './service-types.component.html'
})
export class ServiceTypesComponent implements OnInit {

  constructor(
    public service: ServiceProductsStorage
  ) { }

  ngOnInit(): void {
  }

}
