import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import * as api from '@/config/api-config.json'

import { ProductSold } from '@/domain/product-sold'
import { Sale } from '@/domain/sale'
import { Store } from '@/domain/store'

@Injectable({ providedIn: 'root' })
export class SalesService {

  constructor(private _http: HttpClient) { }

  getLatest(store: Store): Observable<Sale> {
    return this._http.get<Sale>(`${api.sales.getLast}?store_id=${store.id}`)
  }

  createOrUpdateSale(store: Store, products_sold: ProductSold[], saleIdToUpdate: number = null, nonce: string = null): Observable<Sale> {
    let requestBody = {
      "sale": {
        "products_sold": products_sold
      }
    }
    if (saleIdToUpdate) {
      return this._http
        .patch<Sale>(`${api.sales.update}/${saleIdToUpdate}`, requestBody)
        .pipe<Sale>(catchError(this._handleError))
    } else {
      requestBody['sale']['nonce'] = nonce
      requestBody['sale']['store_id'] = store.id
      return this._http
        .post<Sale>(api.sales.create, requestBody)
        .pipe<Sale>(catchError(this._handleError))
    }
  }

  private _handleError(error: HttpErrorResponse) {
    return throwError(error)
  }
}
