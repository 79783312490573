import { LocalDate } from '@js-joda/core'

export class RackAvailability {

  public available: LocalDate[]

  public static from(json: any): RackAvailability {
    const ra = new RackAvailability()
    ra.available = json.available
      ? json.available.map(date => LocalDate.parse(date))
      : []
    return ra
  }
}
