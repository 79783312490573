<div class="col-md-4 offset-md-4 mt-5" style="margin-top: 60px;">

  <div class="card" style="margin-top: 100px;">
    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <div *ngIf="merchantLogoUrl" class="merchant-logo-wrapper p-4">
        <img class="merchant-logo-img" src="{{merchantLogoUrl}}" alt="logo"/>
      </div>
      <h4 class="card-header">{{'account.reset_password_title'|translate}}</h4>
      <div class="card-body">
        <alert></alert>
        <!-- <div *ngIf="didResetOk"><p>{{'account.password_reset_ok_msg'|translate}}</p></div> -->
        <div *ngIf="!didResetOk">
          <div class="form-group">
            <label for="password">{{'account.new_password'|translate}}</label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">{{'account.new_password_required'|translate}}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="password_confirmation">{{'account.new_password_confirmation'|translate}}</label>
            <input type="password" formControlName="password_confirmation" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" />
            <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
              <div *ngIf="f.password_confirmation.errors.required">{{'account.new_password_confirmation_required'|translate}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex">
        <div class="ml-auto t-right">
          <button *ngIf="!didResetOk" [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{'common.save'|translate|uppercase}}
          </button>
          <a *ngIf="didResetOk" role="button" class="btn btn-primary" routerLink="/login">{{'account.login'|translate|uppercase}}</a>
        </div>
      </div>
    </form>
  </div>

</div>
