import { ProductBrand } from '@/domain/product-brand'
import { ProductCategory } from '@/domain/product-category'
import { ProductColor } from '@/domain/product-color'
import { ProductCondition } from '@/domain/product-condition'
import { ProductSize } from '@/domain/product-size'
import { ProductType } from '@/domain/product-type'

import { SaleRow } from '@/domain/sale-row'
import { SalesPeriod } from '@/domain/sales-period'
import { Seller } from '@/domain/seller'
import { Store } from '@/domain/store'

export class Product {

  public id: number
  public note: string
  public price: number
  public status: string
  public settled?: boolean
  public sale_row?: SaleRow
  public deletable: boolean
  public editable: boolean
  public is_manual: boolean

  public sales_period_id: number
  public sales_period: SalesPeriod
  public store: Store

  public number: number
  public product_number?: string

  public product_status_id: number
  public product_category_id: number
  public product_type_id: number
  public product_brand_id: number
  public product_size_id: number
  public product_color_id: number
  public product_condition_id: number

  isNew?: boolean = true
  saving?: boolean = false
  deleting?: boolean = false
  recentlyUpdated?: boolean = false
  recentlyCreated?: boolean = false

  public pos_set_price: number // price entered at pos, to set in sale_row

  static create(): Product {
    let p = new Product()
    p.isNew = true
    return p
  }

  constructor() { }

  // obsolete
  public brand: ProductBrand
  public category: ProductCategory
  public color: ProductColor
  public condition: ProductCondition
  public size: ProductSize
  public type: ProductType
  public seller: Seller

  // returned if features were created
  public product_brand?: ProductBrand
  public product_type?: ProductType
  public product_size?: ProductSize

  // used in POS
  public to_be_added?: boolean
  public to_be_removed?: boolean

  public static from(source: any): Product {
    return Object.assign(new Product(), source)
  }
}
