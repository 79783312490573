import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { UntilDestroy } from '@ngneat/until-destroy'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'
import { PaymentCheckoutService } from '@/services/payment-checkout.service'
import { SalesPeriodsService } from '@/services/sales-periods.service'

import { Merchant } from '@/domain/merchant'
import { SalesPeriod } from '@/domain/sales-period'
import { PlainDate } from '@/util/date-utils'
import { currencyFiEur } from '@/util/number-utils'

import { SelectedVariationAndDate } from '@/model/calendar'

@UntilDestroy()
@Component({
  selector: 'seller-booking-view',
  templateUrl: 'seller-booking-view.component.html'
})
export class SellerBookingViewComponent implements OnInit {

  @ViewChild('confirmSelectionModal')
  confirmSelectionModal: ElementRef

  @ViewChild('proceedToPaymentModal')
  proceedToPaymentModal: ElementRef

  @ViewChild('retryPaymentModal')
  retryPaymentModal: ElementRef

  @ViewChild('doneModal')
  doneModal: ElementRef

  selection: SelectedVariationAndDate

  /** Sales period that was directly reserved without payment */
  reservedSalesPeriod: SalesPeriod = null

  /** Sales period that is pending payment for max. e.g. 30 min [merchant configurable] */
  pendingSalesPeriod: SalesPeriod = null

  /** Checkout response status, null|invalid|success|failure|expired_success|expired_failure or error */
  paymentStatus: string = null

  private _confirmSelectionModalRef: NgbModalRef
  private _proceedToPaymentModalRef: NgbModalRef
  private _retryPaymentModalRef: NgbModalRef
  private _doneModalRef: NgbModalRef

  private _initialized: boolean = false

  merchant: Merchant
  tcAccepted: boolean = false
  sellerNote: string = null

  constructor(
    public auth: AuthenticationService,
    private _salesPeriodsService: SalesPeriodsService,
    private _checkoutService: PaymentCheckoutService,
    private _alertService: AlertService,
    private _modalService: NgbModal,
    private _route: ActivatedRoute,
    private _router: Router,
    private _translate: TranslateService
  ) { }

  // e.g. https://bluto.varpu.local/reservation/payment_cancelled?checkout-account=695861&checkout-algorithm=sha512&checkout-amount=3900&checkout-stamp=9b14c6c6-c1b0-4fac-883d-829890b0fed4&checkout-reference=1151&checkout-transaction-id=5957b1da-a535-11eb-85ed-63f038a0cd03&checkout-status=fail&checkout-provider=checkout&signature=8c2e95edc2911aeeb72527eda26a1dea5ca7429e25ed29426313da778219999fe6dd17aac274f41ad159d03b820d084ddf08ce52eaa9a90a3438bfb955c38b24&language=FI

  ngOnInit() {
    // this._findAction()
    this._init()
  }

  private _reset(): void {
    this._initialized = true
    this.tcAccepted = false
    this.sellerNote = null
    this.pendingSalesPeriod = null
    this.reservedSalesPeriod = null
  }

  private _init(): void {
    this.merchant = this.auth.currentUserValue.merchant
    this._salesPeriodsService.preliminary$
      .subscribe(
        periods => {
          if (periods.length == 0) {
            return
          }
          const sellerId = this.auth.sellerId
          this.pendingSalesPeriod = periods.find(p => { return p.seller_id === sellerId && p.status === 'status_preliminary' })
          if (!this._initialized) {
            this._initialized = true
            this.pendingSalesPeriod ? this._initWithPendings() : this._initWithoutPendings()
          }
        }
      )
  }

  private _initWithPendings(): void {
    if (!this._hasCheckoutParams() || this._paymentError()) {
      this._displayRetryPaymentModal()
    } else {
      const queryParams = this._getCheckoutParams()
      this._checkoutService.validate(queryParams).subscribe(
        validation => {
          if (validation.status == 'success') {
            this._salesPeriodsService.getForSellerBooking(this.pendingSalesPeriod.id).subscribe(period => {
              this.pendingSalesPeriod = null
              this.reservedSalesPeriod = period
              this._displayDoneModal()
            })
          } else {
            this.paymentStatus = validation.status
            this._displayRetryPaymentModal()
          }
        }
      )
    }
  }

  private _paymentError(): boolean {
    return this.pendingSalesPeriod && this.pendingSalesPeriod.checkout_payment.status == 'error';
  }

  private _initWithoutPendings(): void {
    if (this._hasCheckoutParams()) {
      // TODO report ignored payment params to user
    }
    // this._clearQueryParams()
  }

  private _hasCheckoutParams(): boolean {
    const queryParams = this._getCheckoutParams()
    return queryParams['checkout-status'] != undefined
  }

  private _getCheckoutParams(): Params {
    return this._route.snapshot.queryParams
  }

  get capacity(): string {
    return (this.selection.variation.capacity && this.selection.variation.capacity > 0)
      ? ''+this.selection.variation.capacity
      : '–'
  }

  get priceInStore(): string|null {
    if (this.merchant.seller_reservation_checkout_required) {
      return null
    }
    return (this.selection.variation.price_in_store && this.selection.variation.price_in_store >= 0)
      ? this.currency(this.selection.variation.price_in_store)
      : null
  }

  get price(): string|null {
    if (!this.merchant.seller_reservation_checkout_enabled && !this.merchant.seller_reservation_checkout_required) {
      return null
    }
    return (this.selection.variation.price && this.selection.variation.price >= 0)
      ? this.currency(this.selection.variation.price)
      : '–'
  }

  get commission(): string {
    return (this.selection.variation.commission && this.selection.variation.commission >= 0)
      ? this.percent(this.selection.variation.commission)
      : '–'
  }

  get bothPaymentOptionsAvailable(): boolean {
    const merchant = this.auth.currentUserValue.merchant
    const variation = this.selection.variation
    return merchant.seller_reservation_checkout_enabled && !merchant.seller_reservation_checkout_required &&
      variation.price > 0 && variation.price_in_store > 0
  }

  confirmReservationPayInStore(): void {
    const payNow = false
    this._confirm(payNow)
  }

  confirmReservationPayOnline(): void {
    const payNow = true
    this._confirm(payNow)
  }

  confirmReservation(): void {
    const whatever = null // go with default on server
    this._confirm(whatever)
  }

  private _confirm(payNow: boolean|null = null): void {
    const variationId = this.selection.variation.id
    const rackId = this.selection.rack ? this.selection.rack.id : this.selection.rackId
    const from = this.selection.from

    this._salesPeriodsService.createBySeller(payNow, variationId, rackId, from, this.sellerNote).subscribe(
      period => {
        if (period.checkout_payment && period.checkout_payment.status == 'error') {
          this.paymentStatus = 'error'
          this._confirmSelectionModalRef.dismiss()
          this._displayRetryPaymentModal()
        } else if (period.status == 'status_preliminary') {
          this.pendingSalesPeriod = period
          this._confirmSelectionModalRef.dismiss()
          this._displayProceedToPaymentModal()
        } else {
          this.reservedSalesPeriod = period
          const msg = this._translate.instant('seller.booking.successful_msg')
          this._alertService.success(msg)
          this.tcAccepted = false // in case of next reservation
          this.sellerNote = null
        }
      },
      error => {
        this.reservedSalesPeriod = null
        this._alertService.error(error.message)
      }
    )
  }

  private _initiateNewPaymentForPendingPeriod(): void {
    this.paymentStatus = null
    this._salesPeriodsService.startNewPayment(this.pendingSalesPeriod.id).subscribe(
      period => {
        this.pendingSalesPeriod = period
        if (period.checkout_payment && period.checkout_payment.status == 'error') {
          this.paymentStatus = 'error'
          this._displayRetryPaymentModal()
        } else {
          this._displayProceedToPaymentModal()
        }
      }
    )
  }

  selected(selection: SelectedVariationAndDate): void {
    this.reservedSalesPeriod = null
    this.selection = selection
    this._displayConfirmSelectionModal()
  }

  currency(value: any): string {
    return currencyFiEur(value)
  }

  percent(value: number): string {
    return value ? value + "%" : ''
  }

  private _cancelReservation(): void {
    this._salesPeriodsService.cancelReservation(this.pendingSalesPeriod.id).subscribe(
      response => {
        this._reset()
      }
    )
  }

  private _setSelectionFromPendingPeriod(): void {
    if (!this.selection) {
      this.selection = new SelectedVariationAndDate(
        this.pendingSalesPeriod.service_product,
        this.pendingSalesPeriod.service_product_variation,
        null, // TODO FIXME // this.pendingSalesPeriod.rack_time_slot.reck_id,
        PlainDate.ofDate(new Date(this.pendingSalesPeriod.rack_time_slot.from)),
        PlainDate.ofDate(new Date(this.pendingSalesPeriod.rack_time_slot.to))
      )
      console.log('Initializing selection from pending period', this.pendingSalesPeriod)
      console.log('Initialized selection as', this.selection)
    }
  }

  private _displayDoneModal(): void {
    this._doneModalRef = this._modalService.open(this.doneModal, { centered: true, ariaLabelledBy: 'modal-basic-title' })
    const msg = this._translate.instant('reservation.success_alert')
    this._alertService.success(msg)
    this._doneModalRef.result.then(
      closedReason => {
        this._router.navigate([ '/period', this.reservedSalesPeriod.id ])
        this.reservedSalesPeriod = null
      },
      dismissalReason => { }
    )
  }

  private _displayRetryPaymentModal(): void {
    if (this._paymentError()) {
      this.paymentStatus = 'error'
    }
    this._setSelectionFromPendingPeriod()
    this._retryPaymentModalRef = this._modalService.open(this.retryPaymentModal, { centered: true, ariaLabelledBy: 'modal-basic-title' })
    this._retryPaymentModalRef.result.then(
      closedReason => {
        this._initiateNewPaymentForPendingPeriod()
      },
      dismissalReason => {
        this._cancelReservation()
      }
    )
  }

  private _displayProceedToPaymentModal(): void {
    this._proceedToPaymentModalRef = this._modalService.open(this.proceedToPaymentModal, { centered: true, ariaLabelledBy: 'modal-basic-title' })
    this._proceedToPaymentModalRef.result.then(
      closedReason => {
        const url = this.pendingSalesPeriod.checkout_payment.href
        window.location.replace(url)
      },
      dismissalReason => {
        this._cancelReservation()
      }
    )
  }

  private _displayConfirmSelectionModal(): void {
    this.tcAccepted = false // always reset to false when opening confirmation dialog
    // NB do not reset sellerNote, allow user to recycle it; reset upon reservation
    this._confirmSelectionModalRef = this._modalService.open(this.confirmSelectionModal, { centered: true, ariaLabelledBy: 'modal-basic-title' })
    this._confirmSelectionModalRef.result.then(
      confirmedReason => {
        if (confirmedReason == 'done_after_reserved') {
          this._router.navigate([ '/period', this.reservedSalesPeriod.id ])
          this.reservedSalesPeriod = null
        }
      },
      dismissalReason => { }
    )
  }
}