import { Component, OnInit, Input, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ibanValidator } from 'ngx-iban'
import { TranslateService } from '@ngx-translate/core'

import { AlertService } from '@/services/alert.service'
import { SellersService } from '@/services/sellers.service'

import { Seller } from '@/domain/seller'

@Component({
  selector: 'seller-profile',
  templateUrl: 'seller-profile.component.html'
})
export class SellerProfileComponent implements OnInit, OnChanges {

  @Input()
  seller: Seller = null

  @ViewChild('contactName') contactName: ElementRef
  @ViewChild('paymentIban') paymentIban: ElementRef

  contactInfoForm = this._form.group({
    email: ['', Validators.required],
    name: ['', Validators.required],
    phone: [''],
    accept_marketing: [false],
  })

  paymentInfoForm = this._form.group({
    iban: ['', ibanValidator('FI')]
  })

  editingContactInfo: boolean = false
  editingPaymentInfo: boolean = false

  constructor(
    private _sellersService: SellersService,
    private _alertService: AlertService,
    private _translate: TranslateService,
    private _form: FormBuilder
  ) { }

  ngOnInit(): void {
    this.reset()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForms()
  }

  editContact(): void {
    this.enableContactForm()
    this.editingContactInfo = true
    this.contactName.nativeElement.focus()
  }

  cancelContactEdit(): void {
    this.editingContactInfo = false
    this.reset()
  }

  editPayment(): void {
    this.enablePaymentForm()
    this.editingPaymentInfo = true
    this.paymentIban.nativeElement.focus()
  }

  cancelPaymentEdit(): void {
    this.editingPaymentInfo = false
    this.reset()
  }

  onSubmitContactForm() {
    this._sellersService.saveContactInfo(this.seller.id, this.contactInfoForm.value).subscribe(
      json => {
        let seller = Seller.from(json)
        this.updateFrom(seller)
        this.reset()
      },
      error => {
        let msg = error.message + (error.errors ? (': ' + error.errors.join('; ')) : '')
        this._alertService.error(this._translate.instant(msg))
      }
    )
  }

  onSubmitPaymentForm() {
    this._sellersService.saveIban(this.seller.id, this.paymentInfoForm.value).subscribe(
      json => {
        let seller = Seller.from(json)
        this.updateFrom(seller)
        this.reset()
      },
      error => {
        let msg = error.message + (error.errors ? (': ' + error.errors.join('; ')) : '')
        this._alertService.error(this._translate.instant(msg))
      }
    )
  }

  // private

  private reset(): void {
    this.editingContactInfo = false
    this.editingPaymentInfo = false
    this.initForms()
    this.disableForms()
  }

  private updateFrom(seller: Seller): void {
    this.seller.name = seller.name
    this.seller.phone = seller.phone
    this.seller.iban = seller.iban
    this.seller.accept_marketing = seller.accept_marketing
  }

  private enableContactForm(): void {
    this.contactInfoForm.enable()
    this.contactInfoForm.get('email').disable()
  }

  private enablePaymentForm(): void {
    this.paymentInfoForm.enable()
  }

  private disableForms(): void {
    this.contactInfoForm.disable()
    this.paymentInfoForm.disable()
  }

  private initForms(): void {
    this.initContactForm()
    this.initPaymentForm()
  }

  private initContactForm(): void {
    this.contactInfoForm.setValue({
      email: this.seller.user.email,
      name: this.seller.name,
      phone: this.seller.phone,
      accept_marketing: this.seller.accept_marketing,
    })
  }

  private initPaymentForm(): void {
    this.paymentInfoForm.setValue({
      iban: this.seller.iban
    })
  }
}