import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { first } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateService } from '@ngx-translate/core'

import { AlertService } from '@/services/alert.service'
import { AuthenticationService } from '@/services/authentication.service'

@UntilDestroy()
@Component({ templateUrl: 'request-password.component.html' })
export class RequestPasswordComponent implements OnInit {

  requestForm: FormGroup
  loading = false
  submitted = false
  returnUrl: string

  merchantLogoUrl = null
  didRequest = false

  constructor(
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _translate: TranslateService,
    private _auth: AuthenticationService,
    private _alertService: AlertService
  ) {
    this._auth.preloadedMerchant.pipe(untilDestroyed(this)).subscribe(merchant => {
      this._setMerchantLogo(merchant ? merchant.static_logo_asset : null)
    })
  }

  ngOnInit() {
    this.requestForm = this._formBuilder.group({
      email: ['', Validators.required]
    })
  }

  private _setMerchantLogo(asset: string): void {
    this.merchantLogoUrl = asset ? '/' + asset : null
  }

  // convenience getter for easy access to form fields
  get f() { return this.requestForm.controls }

  onSubmit() {
    this.submitted = true

    // reset alerts on submit
    this._alertService.clear()

    // stop here if form is invalid
    if (this.requestForm.invalid) {
      return
    }

    this.loading = true;
    this._auth.requestPassword(this.f.email.value)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false
          this.didRequest = true
          const msg = this._translate.instant('account.request_password_post_msg')
          this._alertService.successNoTimeout(msg)
        },
        error => {
          this.loading = false
          console.error(error)
          const msg = this._translate.instant('common.error')
          this._alertService.error(msg)
        })
  }
}