import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FormControl } from '@angular/forms'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

import { MerchantsService } from '@/services/merchants.service'
import { ServiceProductsService } from '@/services/service-products.service'

import { Rack } from '@/domain/rack'
import { SalesPeriod } from '@/domain/sales-period'
import { ServiceProduct } from '@/domain/service-product'
import { ServiceProductVariation } from '@/domain/service-product-variation'
import { Store } from '@/domain/store'

@UntilDestroy()
@Component({
  selector: 'service-product-selector',
  templateUrl: 'service-product-selector.component.html',
  styleUrls: [ 'service-product-selector.component.scss' ]
})
export class ServiceProductSelectorComponent implements OnInit, OnChanges {

  @Input()
  filterSalesPeriods: SalesPeriod[] = null
  @Output()
  filterSalesPeriodsChange = new EventEmitter<SalesPeriod[]>()

  @Input()
  preselectStoreId: number = null
  @Input()
  store: Store = null
  @Output()
  storeChange = new EventEmitter<Store>()

  @Input()
  preselectProductId: number = null
  @Input()
  product: ServiceProduct = null
  @Output()
  productChange = new EventEmitter<ServiceProduct>()

  @Input()
  preselectVariationId: number = null
  @Input()
  variation: ServiceProductVariation = null
  @Output()
  variationChange = new EventEmitter<ServiceProductVariation>()

  @Input()
  rack: Rack = null
  @Output()
  rackChange = new EventEmitter<Rack>()

  @Input()
  enableSearch: boolean = true

  @Input()
  search: string = ''
  @Output()
  searchChange = new EventEmitter<string>()

  @Input()
  showRacks: boolean = true

  @Input()
  showVariations: boolean = true

  @Output()
  selectProduct = new EventEmitter<ServiceProduct>()
  @Output()
  unselectProduct = new EventEmitter<ServiceProduct>()

  // @Output()
  // selectStore = new EventEmitter<Store>()
  @Output()
  unselectStore = new EventEmitter<Store>()

  @Output()
  onChange = new EventEmitter<boolean>()

  @Input()
  compact: boolean = false

  /** Whether to automatically preselect the first ServiceProduct */
  @Input()
  autoselectProduct: boolean = false

  /** Whether to automatically preselect the first Variation when a ServiceProduct is selected */
  @Input()
  autoselectVariation: boolean = false

  /** Whether product selection is always required; if true, sets autoselectProduct=true */
  @Input()
  requireProductSelect: boolean = false

  /** Whether variation selection is always required; if true, sets autoselectVariation=true */
  @Input()
  requireVariationSelect: boolean = false

  // available data to filter upon
  serviceProducts: ServiceProduct[] = null
  availableStoreIds: number[] = []
  storeNameById: {}

  // filtered data
  filteredProducts: ServiceProduct[] = null
  filteredRacks: Rack[] = null

  // current filter selections
  storeFilter = {}
  productFilter = {}
  variationFilter = {}
  rackFilter = {}

  filter = new FormControl('')
  storesAvailable: boolean = null

  private _salesPeriods: SalesPeriod[] = null
  private _stores: Store[] = []

  constructor(
    public serviceProductService: ServiceProductsService,
    private _merchants: MerchantsService,
  ) { }

  ngOnInit(): void {
    this._validateOptions()
    this._salesPeriods = this.filterSalesPeriods
    this.filter.valueChanges.pipe(untilDestroyed(this)).subscribe(t => {
      this.searchChange.emit(t)
    })
    this._load()
  }

  private _load(): void {
    combineLatest([ this.serviceProductService.serviceProducts, this._merchants.stores$ ])
    .pipe(map(([ serviceProducts, stores ]) => ({ serviceProducts, stores })))
    .subscribe(({ serviceProducts, stores }) => {
      // 1. merchants
      this._stores = stores

      // 2. service products
      if (serviceProducts.length == 0) {
        return
      }
      this.availableStoreIds = [...new Set(
        serviceProducts.flatMap((p: ServiceProduct) => {
          return p.recks.map(r => r.store_id)
        })
      )]
      this.storesAvailable = this.availableStoreIds.length > 0
      this.storeNameById = {}
      serviceProducts.forEach((p: ServiceProduct) => {
        p.recks.forEach((r: Rack) => {
          if (!this.storeNameById[r.store_id]) {
            this.storeNameById[r.store_id] = r.store_name
          }
        })
      })
      this.serviceProducts = serviceProducts
      this.filteredProducts = serviceProducts
      this.sortRacks()

      // store preselection
      let initStoreId = this.preselectStoreId && this.availableStoreIds.includes(this.preselectStoreId)
        ? this.preselectStoreId
        : this.availableStoreIds[0]
      this._storeFilterChange(initStoreId)

      // product preselection
      if (this.preselectProductId) {
        this._productFilterChange(this.preselectProductId)
      }

      // variation preselection
      if (this.product && this.preselectVariationId) {
        this._variationFilterChange(this.preselectVariationId)
      }
    })
  }

  ngOnChanges(): void {
    this._emitFilterChange()
  }

  private _validateOptions() {
    if (this.requireProductSelect) {
      this.autoselectProduct = true
    }
    if (this.requireVariationSelect) {
      this.autoselectVariation = true
    }
  }

  clearSearchFilterContent(): void {
    this.filter.setValue('')
    this._emitSearchChange()
  }

  storeFilterChanged($event) {
    this._updateSelectedStore(+$event.target.id) // NB '+'
  }

  private _storeFilterChange(id: number) {
    this.storeFilter[id] = true
    this._updateSelectedStore(id)
  }

  private _updateSelectedStore(id: number) {
    this.availableStoreIds.forEach(i => { if (i != id) { this.storeFilter[i] = false } })
    if (this.storeFilter[id]) {
      this._filterStore(id)
    } else {
      this._resetFilters()
    }
  }

  productFilterChanged($event) {
    this._updateSelectedProduct($event.target.id)
  }

  private _productFilterChange(id: number) {
    this.productFilter[id] = true
    this._updateSelectedProduct(id)
  }

  private _updateSelectedProduct(id: number) {
    this.serviceProducts.forEach(p => {
      if (p.id != id) {
        this.productFilter[p.id] = false
      }
    })
    if (this.autoselectProduct) {
      this.productFilter[id] = true
    }
    if (this.productFilter[id]) {
      this.filterProduct(id)
    } else {
      this._resetFilters()
    }
  }

  private _maybeAutoselectProduct(): boolean {
    if (this.autoselectProduct && this.filteredProducts.length > 0) {
      const productId = this.filteredProducts[0].id
      this._productFilterChange(productId)
      return true
    }
    return false
  }

  variationFilterChanged($event) {
    this._updateSelectedVariation($event.target.id)
  }

  private _variationFilterChange(id: number) {
    this.variationFilter[id] = true
    this._updateSelectedVariation(id)
  }

  private _updateSelectedVariation(id: number) {
    this.product.service_product_variations.forEach(v => {
      if (v.id != id) {
        this.variationFilter[v.id] = false
      }
    })
    if (this.requireVariationSelect) {
      this.variationFilter[id] = true
    }
    if (this.variationFilter[id]) {
      this.filterVariation(id)
    } else {
      this._resetVariationFilter()
    }
  }

  rackFilterChanged($event) {
    let selectedRackId = $event.target.id
    this.product.recks.forEach(r => {
      if (r.id != selectedRackId) {
        this.rackFilter[r.id] = false
      }
    })
    if (this.rackFilter[selectedRackId]) {
      this.filterRack(selectedRackId)
    } else {
      this._resetRackFilter()
    }
  }

  // private

  private _emitFilterChange(): void {
    if (this._salesPeriods) {
      this.filterSalesPeriods = this._salesPeriods.filter(period => {
        return (!this.product || period.service_product.id === this.product.id)
          && (!this.variation || period.service_product_variation.id === this.variation.id)
          && (!this.rack || period.rack_time_slot.reck.id === this.rack.id)
      })
      this.filterSalesPeriodsChange.emit(this.filterSalesPeriods)
    }
    this.storeChange.emit(this.store)
    this.productChange.emit(this.product)
    this.variationChange.emit(this.variation)
    // console.log('emitted selected variation', this.variation)
    this.rackChange.emit(this.rack)
    this.onChange.emit(true)
  }

  private _emitSearchChange(): void {
    this.searchChange.emit(this.filter.value)
    this.onChange.emit(true)
  }

  private _resetFilters(): void{
    this.store = null
    this.storeFilter = {}
    this.unselectStore.emit(this.store)

    this._resetProductFilter(false)
    this.unselectProduct.emit(this.product)

    this._resetVariationFilter(false)
    this._resetRackFilter(false)

    this._emitFilterChange()
  }

  private _resetProductFilter(sp: boolean = true) {
    this.product = null
    this.productFilter = {}
    if (sp) {
      this._emitFilterChange()
    }
  }

  private _resetVariationFilter(sp: boolean = true) {
    this.variation = null
    this.variationFilter = {}
    if (sp) {
      this._emitFilterChange()
    }
  }

  private _resetRackFilter(sp: boolean = true) {
    this.rack = null
    this.rackFilter = {}
    if (sp) {
      this._emitFilterChange()
    }
  }

  private _filterStore(id: number): void {
    this.storeFilter[id] = true

    // NB product filter handled below
    this._resetVariationFilter()
    this._resetRackFilter()

    this.store = this._stores.find(s => s.id == id)
    this.storeChange.emit(this.store)

    // find racks/products that are available in the selected store

    // let allRacks = this.serviceProducts.flatMap(p => p.recks)
    let storeRacks = this.serviceProducts.flatMap(p => p.recks).filter(r => r.store_id == id)
    let rackIds = storeRacks.map(rack => rack.id)

    // find products available in current store
    this.filteredProducts = this.serviceProducts.filter(p => {
      let productRackIds = p.recks.map(rack => rack.id)
      return this._intersection(productRackIds, rackIds).length > 0
    })

    if (this.product) {
      if (!this.filteredProducts.find(p => p.id === this.product.id)) {
        this._resetProductFilter()
      }
    }

    if (!this.preselectProductId) {
      let didAutoselect = this._maybeAutoselectProduct()
      if (!didAutoselect) {
        this._resetProductFilter()
      }
    }
  }

  private _intersection(a: any[], b: any[]) {
    return a.filter(Set.prototype.has, new Set(b));
  }

  private filterProduct(id: number): void {
    this._resetVariationFilter()
    this._resetRackFilter()
    this.product = this.serviceProducts.find(p => { return p.id == id })
    if (!this.product) {
      this._resetProductFilter()
      return
    }
    this.product.service_product_variations.forEach(sv => { this.variationFilter[sv.id] = false })
    this.selectProduct.emit(this.product)
    if (this.autoselectVariation && this.product.service_product_variations.length > 0) {
      const variationId = this.product.service_product_variations[0].id
      this._variationFilterChange(variationId)
    } else {
      this._emitFilterChange()
    }
  }

  private filterVariation(id: number): void {
    this.variation =
      this.product.service_product_variations
        .find(v => { return v.id == id })
    this._emitFilterChange()
  }

  private filterRack(id: number): void {
    this.rack =
      this.product.recks
        .find(r => { return r.id == id })
    this._emitFilterChange()
  }

  private sortRacks(): void {
    this.serviceProducts.forEach(product => {
      product.recks = product.recks.sort((a, b) => {
        let an = parseInt(a.name)
        let bn = parseInt(b.name)
        if (!isNaN(an) && !isNaN(bn)) {
          return an - bn
        }
        return a.name.localeCompare(b.name)
      })
    })
  }
}