import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { catchError, map, shareReplay, tap } from 'rxjs/operators'
import { PlainDate, isTodayOnOrBetween, isAfterToday } from '@/util/date-utils'
import { domainMerge } from '@/util/domain-utils'
import { formatISO } from 'date-fns'

import * as api from '@/config/api-config.json'

import { CableService } from '@/services/cable.service'

import { SalesPeriod } from '@/domain/sales-period'
import { Seller } from '@/domain/seller'

export enum SalesPeriodStatus {
  CURRENT,     // status_reserved and active today
  FUTURE,      // status_reserved and active tomorrow or later
  UNSETTLED,   // not status_cancelled and period has ended and unsettled_products_count > 0
  PAST,        // status_reserved and period has ended and unsettled_products_count == 0
  CANCELLED,   // status_cancelled
  PRELIMINARY, // pending seller payment

  RESERVATION_EXPIRED,   // seller payment timed out
  RESERVATION_CANCELLED, // seller cancelled

  PAYMENT_PENDING,       // payment in progress
  // PAYMENT_TIMEOUT,       // payment timed out
  // PAYMENT_CANCELLED,     // payment cancelled by user

  PENDING_APPROVAL,      // pending merchant approval
  REJECTED               // merchant rejected
}

const PENDING = new SalesPeriod()
PENDING.id = -999

@Injectable({ providedIn: 'root' })
export class SalesPeriodsService {

  private _source: SalesPeriod[] = []
  private _source$ = new BehaviorSubject<SalesPeriod[]>([])
  private _observables: Map<SalesPeriodStatus, Observable<SalesPeriod[]>> = new Map()

  private _watchedPeriods: Map<number, SalesPeriod> = new Map()
  private _watchedSubjects$: Map<number, BehaviorSubject<SalesPeriod>> = new Map()

  constructor(
    private _cable: CableService,
    private _http: HttpClient
  ) {
    this._cable.salesPeriods.subscribe(period => { this._handleReceivedPeriod(period) })
  }

  get current$(): Observable<SalesPeriod[]> { return this._observableFor(SalesPeriodStatus.CURRENT) }
  get future$(): Observable<SalesPeriod[]> { return this._observableFor(SalesPeriodStatus.FUTURE) }
  get unsettled$(): Observable<SalesPeriod[]> { return this._observableFor(SalesPeriodStatus.UNSETTLED) }
  get past$(): Observable<SalesPeriod[]> { return this._observableFor(SalesPeriodStatus.PAST) }
  get cancelled$(): Observable<SalesPeriod[]> { return this._observableFor(SalesPeriodStatus.CANCELLED) }
  get preliminary$(): Observable<SalesPeriod[]> { return this._observableFor(SalesPeriodStatus.PRELIMINARY) }
  get pending$(): Observable<SalesPeriod[]> { return this._observableFor(SalesPeriodStatus.PENDING_APPROVAL) }
  get rejected$(): Observable<SalesPeriod[]> { return this._observableFor(SalesPeriodStatus.REJECTED) }

  private _observableFor(status: SalesPeriodStatus): Observable<SalesPeriod[]> {
    this._initLoadSalesPeriods(status)
    if (!this._observables.get(status)) {
      const observable = this._source$.pipe(map((periods) => this._filter(periods, status)), shareReplay(1))
      this._observables.set(status, observable)
    }
    return this._observables.get(status)
  }

  private _filter(periods: SalesPeriod[], status: SalesPeriodStatus): SalesPeriod[] {
    const today = this.today
    let result = periods
    switch (status) {
      case SalesPeriodStatus.FUTURE: {
        result = periods.filter(p => {
          return p.status === 'status_reserved' && isAfterToday(new Date(p.rack_time_slot.from))
        })
        break
      }
      // case SalesPeriodStatus.PENDING_PAYMENT: {
      //   result = periods.filter(p => {
      //     return p.payment_status === 'payment_status_pending'
      //   })
      //   break
      // }
      case SalesPeriodStatus.PENDING_APPROVAL: {
        result = periods.filter(p => {
          return p.status === 'status_pending_approval'
        })
        break
      }
      case SalesPeriodStatus.CURRENT: {
        result = periods.filter(p => {
          return p.status === 'status_reserved' && isTodayOnOrBetween(new Date(p.rack_time_slot.from), new Date(p.rack_time_slot.to))
        })
        break
      }
      case SalesPeriodStatus.UNSETTLED: {
        result = periods.filter(p => {
          return p.unsettled_products_count > 0 && p.status != 'status_cancelled' && new Date(p.rack_time_slot.to) < today
        })
        break
      }
      case SalesPeriodStatus.PAST: {
        result = periods.filter(p => {
          return p.status === 'status_reserved' && new Date(p.rack_time_slot.to) < today
        })
        break
      }
      case SalesPeriodStatus.CANCELLED: {
        result = periods.filter(p => {
          return p.status === 'status_cancelled'
        })
        break
      }
      case SalesPeriodStatus.REJECTED: {
        result = periods.filter(p => {
          return p.status === 'status_rejected'
        })
        break
      }
      case SalesPeriodStatus.PRELIMINARY: {
        result = periods.filter(p => {
          return p.status === 'status_preliminary'
        })
        break
      }
      default: {
        return []
        break
      }
    }
    return result
  }

  private _initLoaded = {}
  private _initLoadSalesPeriods(status: SalesPeriodStatus): void {
    if (this._initLoaded[status]) {
      return
    }
    this._initLoaded[status] = true
    const params = 'include_products=false&status=' + SalesPeriodStatus[status].toLowerCase()
    this._get(params).subscribe(
      periods => {
        this._source = domainMerge(this._source, periods)
        this._source$.next(this._source)
      }
    )
  }

  private _handleReceivedPeriod(period: SalesPeriod): void {
    this._updateOne(period)
  }

  private _updateOne(period: SalesPeriod): void {
    this._updateMany([ period ])
  }

  private _updateMany(periods: SalesPeriod[]): void {
    this._source = domainMerge(this._source, periods)
    this._source$.next(this._source)
    periods.forEach(period => this._updateIfWatched(period))
  }

  private _updateIfWatched(period: SalesPeriod): void {
    const id = +period.id
    var watched = this._watchedPeriods.get(id)
    if (watched) {
      this._watchedPeriods.set(id, period)
      var subject = this._watchedSubjects$.get(id)
      if (subject) {
        subject.next(period)
      }
    }
  }

  watch(periodId: number): Observable<SalesPeriod> {
    const id = +periodId
    var period = this._watchedPeriods.get(id)
    if (!period) {
      period = this._source.find(p => p.id == id) || PENDING
      this._watchedPeriods.set(id, period)
    }
    var subject = this._watchedSubjects$.get(id)
    if (!subject) {
      subject = new BehaviorSubject<SalesPeriod>(period === PENDING ? null : period)
      this._watchedSubjects$.set(id, subject)
    }
    if (!period || !period.products || period.product_counts.total > period.products.length) {
      this._http
        .get<SalesPeriod>(`${api.salesPeriods.get}/${id}?include_products=true`)
        .subscribe(period => { this._updateOne(period) })
    }
    return subject.asObservable().pipe(shareReplay(1))
  }

  sellerPeriods$(seller: Seller): Observable<SalesPeriod[]> {
    this._initLoadForSeller(seller.id)
    return this._source$.pipe(
      map(periods => periods.filter(p => p.seller_id == seller.id)),
      shareReplay(1)
    )
  }

  private _initLoadForSeller(sellerId: number): void {
    this._get(`seller_id=${sellerId}&include_products=false`)
      .subscribe(periods => { this._updateMany(periods) })
  }

  getForSellerBooking(id: number): Observable<SalesPeriod> {
    return this._http.get<SalesPeriod>(`${api.salesPeriods.get}/${id}`)
      .pipe(tap((period: SalesPeriod) => { this._updateOne(period) }))
  }

  /** status = status_reserved, status_preliminary, status_cancelled // TODO create enum */
  setStatus(salesPeriodId: number, status: string): Observable<SalesPeriod> {
    let requestBody = {
      "sales_period": {
        "status": status
      }
    }
    const url = `${api.salesPeriods.edit}/${salesPeriodId}`
    return this._http.patch<SalesPeriod>(url, requestBody)
      .pipe(
        tap((period: SalesPeriod) => { this._updateOne(period) }),
        catchError(this._handleError)
      )
  }

  getPriceTags(period: SalesPeriod, status: string): Observable<Blob> {
    const endpoint = `${api.priceTags.getForSalesPeriod}/${period.id}/${status}`
    return this._http.get(endpoint, { responseType: "blob", headers: { 'Accept': 'application/pdf' }})
  }

  getStartingOnDate(date: Date): Observable<SalesPeriod[]> {
    const yyyy_mm_dd = formatISO(date, { representation: 'date' })
    return this._get(`include_products=false&starting_date=${yyyy_mm_dd}`)
  }

  getEndingOnDate(date: Date): Observable<SalesPeriod[]> {
    const yyyy_mm_dd = formatISO(date, { representation: 'date' })
    return this._get(`include_products=false&ending_date=${yyyy_mm_dd}`)
  }

  private _get(queryParams: string): Observable<SalesPeriod[]> {
    return this._http.get<SalesPeriod[]>(`${api.salesPeriods.all}?${queryParams}`)
  }

  // delete(id: number): Observable<SalesPeriod> {
  //   return this._http.delete<SalesPeriod>(`${api.salesPeriods.delete}/${id}`)
  // }

  /**
   * Create a sales period
   *
   * @param payNow: null = go with default, true = want to pay online, false = want to pay in store
   * @param variationId
   * @param rackId
   * @param from
   * @param sellerNote
   */
  createBySeller(payNow: boolean|null = null, variationId: number, rackId: number, from: PlainDate, sellerNote: string|null): Observable<SalesPeriod> {
    const requestBody = {
      "sales_period": {
        "service_product_variation_id": variationId,
        "rack_id": rackId,
        "from_date": formatISO(from.asDate())
      }
    }
    if (sellerNote) {
      requestBody['sales_period']['seller_note'] = sellerNote
    }
    if (payNow) {
      requestBody['sales_period']['pay_now'] = true
    }
    return this._http.post<SalesPeriod>(api.salesPeriods.create, requestBody)
      .pipe(
        tap((period: SalesPeriod) => { this._updateOne(period) }),
        catchError(this._handleError)
      )
  }

  cancelReservation(salesPeriodId: number) {
    return this._http
    .post<SalesPeriod>(`${api.salesPeriods.edit}/${salesPeriodId}/${api.salesPeriods.reservations.cancel}`, { })
    .pipe(
      map((period: SalesPeriod) => { return period }),
      catchError(this._handleError)
    )
  }

  approveReservation(salesPeriodId: number) {
    return this._http
    .post<SalesPeriod>(`${api.salesPeriods.edit}/${salesPeriodId}/${api.salesPeriods.reservations.approve}`, { })
    .pipe(
      map((period: SalesPeriod) => { return period }),
      catchError(this._handleError)
    )
  }

  rejectReservation(salesPeriodId: number) {
    return this._http
    .post<SalesPeriod>(`${api.salesPeriods.edit}/${salesPeriodId}/${api.salesPeriods.reservations.reject}`, { })
    .pipe(
      map((period: SalesPeriod) => { return period }),
      catchError(this._handleError)
    )
  }

  startNewPayment(salesPeriodId: number): Observable<SalesPeriod> {
    return this._http
      .post<SalesPeriod>(`${api.salesPeriods.edit}/${salesPeriodId}/${api.salesPeriods.payments.create}`, { })
      .pipe(
        map((period: SalesPeriod) => { return period }),
        catchError(this._handleError)
      )
  }

  reload(salesPeriodId: number): void {
    this._http
      .get<SalesPeriod>(`${api.salesPeriods.get}/${salesPeriodId}`)
      .subscribe(period => {
        this._updateOne(period)
      })
  }

  saveOrCreate(values, salesPeriodId: number) {
    const requestBody = {
      "sales_period": {
        "seller_id": values.seller_id,
        "service_product_variation_id": values.service_product_variation_id,
        "rack_id": values.rack_id,
        "from_date": values.from,
        "to_date": values.to,
        "seller_note": values.seller_note,
        "internal_note": values.internal_note,
        "price": values.price,
        "commission": values.commission,
        "capacity": values.max_items,
        "discount": values.discount,
        "is_donate": false,
        "payment_status": values.payment_status,
        "custom_period_status_id": values.custom_period_status_id
      }
    }
    if (values.rack_time_slot_id) {
      requestBody['sales_period']['rack_time_slot_id'] = values.rack_time_slot_id
    }

    if (salesPeriodId) {
      return this._http
        .patch<SalesPeriod>(`${api.salesPeriods.edit}/${salesPeriodId}`, requestBody)
        .pipe(
          tap((period: SalesPeriod) => { this._updateOne(period) }),
          catchError(this._handleError))
    } else {
      return this._http
        .post<SalesPeriod>(api.salesPeriods.create, requestBody)
        .pipe(
          tap((period: SalesPeriod) => { this._updateOne(period) }),
          catchError(this._handleError))
    }
  }

  getDailySalesReport(when: string): Observable<Blob> {
    let endpoint = `${api.reports.dailySales}/${when}`
    return this._http.get(endpoint, { responseType: "blob", headers: { 'Accept': '*/*' }})
  }

  private _handleError(error: HttpErrorResponse) {
    return throwError(error)
  }

  get today(): Date {
    const today = new Date
    today.setHours(0,0,0,0)
    return today
  }
}
