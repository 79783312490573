import { Component } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

import { SalesPeriodsService } from '@/services/sales-periods.service'

import { TranslateService } from '@ngx-translate/core'

@UntilDestroy()
@Component({
  selector: 'rejected-sales-periods',
  template: `
    <div class="mt-4">
      <sales-period-list [salesPeriods]="periods.rejected$|async" [title]="title"></sales-period-list>
    </div>
  `
})
export class RejectedSalesPeriodsViewComponent  {

  constructor(
    public periods: SalesPeriodsService,
    private _translate: TranslateService,
  ) { }

  get title(): string {
    return this._translate.instant('pricing.title.rejected')
  }
}
