import { Product } from "@/domain/product"
import { Store } from "@/domain/store"

export class SaleRow {

  public id: number
  public created_at?: string
  public updated_at?: string

  public product_id: number
  public product_number?: string
  public description: string
  public product_price: number
  public quantity: number
  public discount_percent: number
  public discount_amount: number
  public commission: number
  public tax_name: string
  public tax_percent: number

  public total: number
  public total_excl_commission: number
  public total_commission: number

  public product?: Product
  public product_is_manual: boolean

  public store_id: number
  public store_name: string
  public product_store: Store

  constructor() { }

  public static from(json: any): SaleRow {
    return Object.assign(new SaleRow(), json)
  }
}
