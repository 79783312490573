import { Injectable } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Observable, Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class AlertService {
  private subject = new Subject<any>()
  private keepAfterRouteChange = false

  private timeout = 10*1000

  constructor(
    private router: Router,
    private _translate: TranslateService
  ) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          // console.log('only keep for a single route change')
          this.keepAfterRouteChange = false
        } else {
          // clear alert message
          // console.log('clear alert message')
          this.clear()
        }
      }
    })
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable()
  }

  success(message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange
    this.subject.next({ type: 'success', text: message })
    setTimeout(() => { this.clear() }, this.timeout)
  }

  successNoTimeout(message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange
    this.subject.next({ type: 'success', text: message })
  }

  warning(message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange
    this.subject.next({ type: 'warning', text: message })
    setTimeout(() => { this.clear() }, this.timeout)
  }

  errors(errors: any[]) {
    errors.forEach(error => {
      // TODO if (error.code.charAt(0) === 'E')
      if (error.code.charAt(0) === 'W') {
        var message = this._translate.instant('warning.' + error.code)
        switch (error.code) {
          case 'W01011':
            message = message.replace('##', error.data.sales_period_ids.sort.join(', '))
            break
        }
        this.warning(message)
      }
    })
  }

  error(message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange
    this.subject.next({ type: 'error', text: message })
    setTimeout(() => { this.clear() }, this.timeout)
  }

  errorNoTimeout(message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange
    this.subject.next({ type: 'error', text: message })
  }

  clear() {
    this.subject.next()
  }
}