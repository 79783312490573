// Modified with thanks to
// https://medium.com/@schneider.julien/angular-8-automatic-application-refresh-on-users-side-6aee1adc3d5
// and https://blog.nodeswat.com/automagic-reload-for-clients-after-deploy-with-angular-4-8440c9fdd96c

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { interval, Observable, Subject } from 'rxjs'

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000
const APP_UPDATE_CHECK_INTERVAL_MS = FIVE_MINUTES_IN_MS
const APP_URL = ''

const MAINTENANCE_BREAK_CHECK_MIN = 5

@Injectable({ providedIn: 'root' })
export class ApplicationUpdateService {

  private subject = new Subject<boolean>()


  // this is be replaced by actual timestamp post-build.js
  private currentTimestamp = '{{POST_BUILD_ENTERS_TIMESTAMP_HERE}}'
  private appJsonFile = 'app.json'

  constructor(private http: HttpClient) {
    // console.debug('Timestamp at init', this.currentTimestamp)
  }

  public init() {
    let frequency = APP_UPDATE_CHECK_INTERVAL_MS
    let frequencyNumber = Number(frequency)

    if (isNaN(frequencyNumber) || 0 >= frequencyNumber) {
        // console.warn("SoftwareUpdateService.init() no frequency : runApplicationUpdating is skipped")
    } else {
      // on application init, force reload if necessary; but not in local env
      let localEnv = window.location.href.indexOf('bluto.varpu.local') > 0
      let forceReloadIfNeeded = true && !localEnv
      this.runApplicationCheck(forceReloadIfNeeded)

      interval(frequencyNumber).subscribe(val => this.runApplicationCheck(false))
    }

    interval(MAINTENANCE_BREAK_CHECK_MIN * 60 * 1000).subscribe(val => this.runStatusCheck())
  }

  /**
   * Update the app by forcing a reload
   */
  public update() {
    window.location.replace(window.location.href)
  }

  public getNotification(): Observable<boolean> {
    return this.subject.asObservable()
  }

  private runStatusCheck(): void {
    this.http.get(APP_URL + '/status').subscribe(
      (response: any) => { },
      (err) => { }
    )
  }

  /**
  * Will get new application timestamp and launch application update if needed
  * @param forceReload
  */
  private runApplicationCheck(forceReload: boolean = false): void {
    let url = APP_URL + '/' + this.appJsonFile
    this.http.get(url + '?t=' + (new Date().getTime()) ).subscribe(
      (response: any) => {
        const newTimestamp = response.t
        // console.debug('Server timestamp is', newTimestamp)
        const updateNeeded = this.isUpdated(newTimestamp)
        if (updateNeeded) {
          this.updateApplication(forceReload)
        }
      },
      (err) => {
        // console.error(err, `SoftwareUpdateService.runApplicationCheck() : could not get ${this.appJsonFile}`)
      }
    )
  }

  /**
   * Reload application, user may be asked for validation
   * if forceReload is false then user will be asked for application refresh
   * if foceTeload is true then application will be refreshed
   * @param forceReload
   */
  private updateApplication(forceReload: boolean): void {
    if (forceReload) {
      this.update()
    } else {
      this.subject.next(true)
    }
  }

  /**
  * Checks if application timestamp has changed
  * @param newTimestamp
  * @returns {boolean}
  */
  private isUpdated(newTimestamp: string): boolean {
    if (!this.currentTimestamp || !newTimestamp) {
      // console.debug('App update status not known')
      return false
    }
    let result = this.currentTimestamp !== newTimestamp
    if (result) {
      // console.debug('App is updated', this.currentTimestamp, newTimestamp)
    } else {
      // console.debug('App is up to date')
    }
    return result
  }
}