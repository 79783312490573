<div class="row mt-4">
  <div class="col-sm">
    <div class="card gutter-b">
      <div class="card-header">

        <div class="d-flex justify-content-start mb-4">
          <h1>
            <span *ngIf="title">{{title}}</span>
            <span *ngIf="multistore && auth.isMerchant" class="ml-2">&bull;</span>
            <!-- <span *ngIf="multistore && auth.isMerchant && filterStore" class="badge badge-light p-2 ml-2">{{filterStore.name}}</span> -->
            <span *ngIf="maybeStoreName" class="badge badge-light p-2 ml-2">{{maybeStoreName}}</span>
            <span *ngIf="auth.isMerchant && !filterStore" class="badge badge-light p-2 ml-2">{{'common.all_stores'|translate}}</span>
            <span *ngIf="filterProduct" class="ml-2">&bull;</span>
            <span *ngIf="filterProduct" class="badge badge-light p-2 ml-2">{{filterProduct.code}}</span>
            <span class="badge badge-light p-2 ml-2" *ngIf="filterVariation">{{filterVariation.name}}</span>
            <span class="badge badge-light p-2 ml-2" *ngIf="filterRack">{{filterRack.name}}</span>
            <span *ngIf="filteredPeriods" class="ml-2">&bull;</span>
            <span *ngIf="filteredPeriods" class="ml-2">{{filteredPeriods.length}}</span>
          </h1>
          <button id="createButton" *ngIf="create && !auth.isSeller" class="btn btn-primary ml-auto" (click)="openSalePeriodModal()">{{'merchant.salesperiod.create_new'|translate|uppercase}}</button>
        </div>

        <div *ngIf="auth.isMerchant && showCurrentFilter">
          <service-product-selector
            (onChange)="filterChanged($event)"
            [(store)]="filterStore"
            [(product)]="filterProduct"
            [(variation)]="filterVariation"
            [(rack)]="filterRack"
            [(search)]="searchField"
            [showRacks]="true"
            [showVariations]="true"
            [compact]="false"
            >
          </service-product-selector>
        </div>
      </div>
      <div class="card-body">
        <sales-period-table
          [salesPeriods]="filteredPeriods"
          [store]="filterStore"
          [search]="searchField"
          [linkToPeriod]="true"
          [hide]="hide"
          [approvalActions]="approvalActions">
        </sales-period-table>
      </div>
    </div>
  </div>
</div>

<sales-period-edit-modal
  [open]="openSalesPeriodModal"
  [selection]="selection"
  (closed)="salesPeriodModalClosed($event)"
  (created)="salesPeriodCreated($event)"
  [link]="true"
  >
</sales-period-edit-modal>
