import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import * as api from '@/config/api-config.json'
import { PaymentProvider } from '@/domain/payment/checkout/payment-provider'

@Injectable({ providedIn: 'root' })
export class PaymentCheckoutService {

  private _error: boolean = false
  private _paymentProviders: PaymentProvider[]

  private _observer: Observable<PaymentProvider[]>

  constructor(private _http: HttpClient) {
    this._observer = new Observable(observer => {
      if (this._paymentProviders) {
        observer.next(this._paymentProviders)
        return observer.complete()
      }

      if (this._error) {
        return observer.error()
      }

      this._http
        .get<PaymentProvider[]>(api.payment.checkout.providers)
        .subscribe(providers => {
          this._paymentProviders = providers
          observer.next(this._paymentProviders)
          observer.complete()
          return this._paymentProviders
        }, error => {
          console.error('Error loading payment providers', error)
          this._error = true
        })
    })
  }

  get paymentProviders(): Observable<PaymentProvider[]> {
    return this._observer
  }

  public validate(params: any): Observable<any> {
    return this._http.post(api.payment.checkout.validate, { 'checkout_callback_params': params })
  }
}
