export class ProductBrand {

  id: number
  name: string

  static create(name: string): ProductBrand {
    let brand = new ProductBrand()
    brand.name = name
    return brand
  }

  constructor() { }

  public static from(obj: any): ProductBrand {
    return Object.assign(new ProductBrand(), obj)
  }
}
