import { Settlement } from "./settlement"

export class PaymentInitiationMessage {
  public id: number
  public created_at: string
  public updated_at: string

  public settlement_ids: number[]
  public settlements: Settlement[] // only partial fields

  public xml: string

  public static from(source: any): PaymentInitiationMessage {
    return Object.assign(new PaymentInitiationMessage(), source)
  }
}
